import { useMemo } from "react";
import { useSelector } from "react-redux";

import { EvaChart } from "constants/charts.enums";
import {
  selectChartAnnotationFeature,
  selectChartAverageFeature,
  selectChartBounds,
  selectChartCutoffFeature,
  selectChartDataTableFeature,
  selectChartDebugFeature,
  selectChartFeatures,
  selectChartFocusFeature,
  selectChartForecastFeature,
  selectChartFullScreenFeature,
  selectChartIsProducingRateTypeFeature,
  selectChartLassoBrushEnd,
  selectChartLassoBrushPosition,
  selectChartLassoFeature,
  selectChartLassoList,
  selectChartLegendFeature,
  selectChartLockUnitsFeature,
  selectChartLocks,
  selectChartMovingAverageDaysFeature,
  selectChartNormalizationFeature,
  selectChartOptions,
  selectChartOverflowIndex,
  selectChartP10Feature,
  selectChartP50Feature,
  selectChartP90Feature,
  selectChartPointOpacityFeature,
  selectChartPointSizeFeature,
  selectChartProduct,
  selectChartRequest,
  selectChartResponse,
  selectChartScreenshotFeature,
  selectChartShutInMonthsFeature,
  selectChartSourceFeature,
  selectChartSpaghettiFeature,
  selectChartSurvivorBiasFeature,
  selectChartTimeStepFeature,
  selectChartType,
  selectChartTypeParamFeature,
  selectChartTypeWellsFeature,
  selectChartUseWeightedRatioAverageFeature,
  selectPressureChartSettingsFeature
} from "store/features";
import { RootState } from "store/rootReducer";

export const useChartEntities = (id: string) => {
  const chartType =
    useSelector((state: RootState) => selectChartType(state, id)) ?? EvaChart.None;
  const product = useSelector((state: RootState) => selectChartProduct(state, id));
  const sum = useSelector((state: RootState) => selectChartSpaghettiFeature(state, id));
  const forecast = useSelector((state: RootState) =>
    selectChartForecastFeature(state, id)
  );
  const source = useSelector((state: RootState) => selectChartSourceFeature(state, id));
  const timeStep = useSelector((state: RootState) =>
    selectChartTimeStepFeature(state, id)
  );
  const lasso = useSelector((state: RootState) => selectChartLassoFeature(state, id));
  const isProducingRateType = useSelector((state: RootState) =>
    selectChartIsProducingRateTypeFeature(state, id)
  );
  const cutoff = useSelector((state: RootState) => selectChartCutoffFeature(state, id))
    ?.properties?.value;
  const shutInMonths = useSelector((state: RootState) =>
    selectChartShutInMonthsFeature(state, id)
  )?.properties?.value;
  const movingAverageDays = useSelector((state: RootState) =>
    selectChartMovingAverageDaysFeature(state, id)
  )?.properties?.value;
  const survivorBias = useSelector((state: RootState) =>
    selectChartSurvivorBiasFeature(state, id)
  )?.active;
  const useWeightedRatioAverage = useSelector((state: RootState) =>
    selectChartUseWeightedRatioAverageFeature(state, id)
  )?.active;
  const lockUnits = useSelector((state: RootState) =>
    selectChartLockUnitsFeature(state, id)
  )?.active;
  const debugState = useSelector((state: RootState) =>
    selectChartDebugFeature(state, id)
  );
  const typewells = useSelector((state: RootState) =>
    selectChartTypeWellsFeature(state, id)
  );

  const fullScreen = useSelector((state: RootState) =>
    selectChartFullScreenFeature(state, id)
  );
  const screenshot = useSelector((state: RootState) =>
    selectChartScreenshotFeature(state, id)
  );

  const average = useSelector((state: RootState) => selectChartAverageFeature(state, id));
  const p10 = useSelector((state: RootState) => selectChartP10Feature(state, id));
  const p50 = useSelector((state: RootState) => selectChartP50Feature(state, id));
  const p90 = useSelector((state: RootState) => selectChartP90Feature(state, id));

  const normalizeBy = useSelector((state: RootState) =>
    selectChartNormalizationFeature(state, id)
  );

  const chartFocus = useSelector((state: RootState) =>
    selectChartFocusFeature(state, id)
  );

  const chartLegend = useSelector((state: RootState) =>
    selectChartLegendFeature(state, id)
  );

  const bounds = useSelector((state: RootState) => selectChartBounds(state, id));

  const dataTable = useSelector((state: RootState) =>
    selectChartDataTableFeature(state, id)
  );
  const overflowIndex = useSelector((state: RootState) =>
    selectChartOverflowIndex(state, id)
  );
  const pointOpacity = useSelector((state: RootState) =>
    selectChartPointOpacityFeature(state, id)
  );
  const pointSize = useSelector((state: RootState) =>
    selectChartPointSizeFeature(state, id)
  );
  const chartTypeParam = useSelector((state: RootState) =>
    selectChartTypeParamFeature(state, id)
  );

  const annotations = useSelector((state: RootState) =>
    selectChartAnnotationFeature(state, id)
  );

  const apiResponse = useSelector((state: RootState) => selectChartResponse(state, id));

  const request = useSelector((state: RootState) => selectChartRequest(state, id));

  const options = useSelector((state: RootState) => selectChartOptions(state, id));
  const locks = useSelector((state: RootState) => selectChartLocks(state, id));
  const lassoBrushEnd = useSelector((state: RootState) =>
    selectChartLassoBrushEnd(state, id)
  );
  const lassoBrushPosition = useSelector((state: RootState) =>
    selectChartLassoBrushPosition(state, id)
  );
  const lassoBrushList = useSelector((state: RootState) =>
    selectChartLassoList(state, id)
  );
  const features = useSelector((state: RootState) => selectChartFeatures(state, id));
  const pressureChartSettings = useSelector((state: RootState) =>
    selectPressureChartSettingsFeature(state, id)
  );

  return useMemo(
    () => ({
      chartType,
      product,
      sum,
      forecast,
      source,
      lasso,
      timeStep,
      isProducingRateType,
      cutoff,
      shutInMonths,
      movingAverageDays,
      survivorBias,
      useWeightedRatioAverage,
      lockUnits,
      debugState,
      typewells,
      average,
      p10,
      p50,
      p90,
      normalizeBy,
      chartFocus,
      chartLegend,
      fullScreen,
      screenshot,
      overflowIndex,
      annotations,
      bounds,
      dataTable,
      pointOpacity,
      pointSize,
      chartTypeParam,
      apiResponse,
      request,
      options,
      locks,
      lassoBrushEnd,
      lassoBrushPosition,
      lassoBrushList,
      features,
      pressureChartSettings
    }),
    [
      chartType,
      product,
      sum,
      forecast,
      source,
      lasso,
      timeStep,
      isProducingRateType,
      cutoff,
      shutInMonths,
      movingAverageDays,
      survivorBias,
      useWeightedRatioAverage,
      lockUnits,
      debugState,
      typewells,
      average,
      p10,
      p50,
      p90,
      normalizeBy,
      chartFocus,
      chartLegend,
      fullScreen,
      screenshot,
      overflowIndex,
      annotations,
      bounds,
      dataTable,
      overflowIndex,
      pointSize,
      pointOpacity,
      chartTypeParam,
      apiResponse,
      request,
      options,
      locks,
      lassoBrushEnd,
      lassoBrushPosition,
      lassoBrushList,
      features,
      pressureChartSettings
    ]
  );
};

export type ChartEntities = ReturnType<typeof useChartEntities>;

export const DEFAULT_CHART_ENTITIES: ChartEntities = {
  chartType: EvaChart.None,
  sum: undefined,
  forecast: false,
  source: undefined,
  lasso: undefined,
  timeStep: undefined,
  isProducingRateType: undefined,
  cutoff: undefined,
  shutInMonths: undefined,
  movingAverageDays: undefined,
  survivorBias: false,
  useWeightedRatioAverage: false,
  lockUnits: false,
  debugState: undefined,
  typewells: undefined,
  average: undefined,
  p10: undefined,
  p50: undefined,
  p90: undefined,
  normalizeBy: undefined,
  chartFocus: undefined,
  chartLegend: undefined,
  fullScreen: undefined,
  screenshot: undefined,
  overflowIndex: undefined,
  annotations: undefined,
  product: undefined,
  bounds: undefined,
  dataTable: undefined,
  pointOpacity: undefined,
  pointSize: undefined,
  chartTypeParam: undefined,
  apiResponse: undefined,
  request: undefined,
  options: undefined,
  locks: undefined,
  lassoBrushEnd: undefined,
  lassoBrushPosition: undefined,
  lassoBrushList: undefined,
  features: undefined,
  pressureChartSettings: undefined
};
