import { memo, useState } from "react";

import { Popover } from "antd";
import { useChartsContext } from "contexts/ChartContext";

import { useChartEntities } from "hooks/charts";

import StatsIcon from "components/icons/Stats";

import ToolbarButton from "../../../base/ToolbarButton";
import StatisticsOptions from "./StatisticsOptions";

const StatisticsToggle = () => {
  const [isVisible, setIsVisible] = useState(false);

  const { id } = useChartsContext();
  const {
    average: averageState,
    p10: p10State,
    p50: p50State,
    p90: p90State
  } = useChartEntities(id);

  const isActive =
    averageState?.active || p10State?.active || p50State?.active || p90State?.active;
  const isDisabled = !(
    averageState?.enabled ||
    p10State?.enabled ||
    p50State?.enabled ||
    p90State?.enabled
  );

  return (
    <Popover
      trigger="click"
      placement="bottomRight"
      open={isVisible}
      onOpenChange={(v) => setIsVisible(v)}
      content={<StatisticsOptions isVisible={isVisible} />}>
      <ToolbarButton
        data-testid={isActive ? "statisticsToggleActive" : "statisticsToggleInactive"}
        active={isActive}
        icon={<StatsIcon />}
        isMenuButton={true}
        overflowLabel={"Statistics"}
        tooltipText={"Show Statistics"}
        disabled={isDisabled}
      />
    </Popover>
  );
};

export default memo(StatisticsToggle);
