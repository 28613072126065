import { useEffect, useState } from "react";

import { AXIS_TYPE } from "../../../constants";
import { EvaChart } from "../../../constants/charts.enums";
import { ChartAxisType } from "../../../models/chart";

/**
 * A custom hook that determines the allowed data types for chart axes group by based on chart type.
 * This is used to filter out specific data types in the group by that are not applicable for
 * the selected chart type.
 *
 * @param {ChartAxisType} axisType - The axis type, either "x" or "y".
 * @param {EvaChart} chartType - The type of chart being rendered.
 * @returns {string[]} An array of allowed data types for the specified axis.
 */
const useAxisDataTypeFilter = (axisType: ChartAxisType, chartType: EvaChart) => {
  const [x, setX] = useState([]);
  const [y, setY] = useState([]);

  const isProbit = chartType === EvaChart.Probit;
  const isBoxPlot = chartType === EvaChart.BoxPlot;
  const isCrossPlot = chartType === EvaChart.CrossPlot;
  const isStackedBar = chartType === EvaChart.StackedBar;
  const isDateTrend = chartType === EvaChart.TrendDate;

  useEffect(() => {
    axisType === "x"
      ? setX([
          ...(isCrossPlot ? ["Number", "Integer"] : []),
          ...(isProbit ? ["Number", "Integer"] : []),
          ...(isDateTrend ? ["Date", "Integer"] : [])
        ])
      : setY([
          ...(isCrossPlot ? ["Number", "Integer"] : []),
          ...(isBoxPlot || isStackedBar || isDateTrend ? ["Number", "Integer"] : [])
        ]);
  }, [axisType, chartType, isBoxPlot, isCrossPlot, isStackedBar, isProbit, isDateTrend]);

  return axisType === AXIS_TYPE.x ? x : y;
};

export default useAxisDataTypeFilter;
