import { memo, useState } from "react";

import { Popover } from "antd";
import { useChartsContext } from "contexts/ChartContext";

import { useChartEntities } from "hooks/charts";

import CollapseVerticalIcon from "components/icons/CollapseVertical";

import ToolbarButton from "../../../base/ToolbarButton";
import NormalizeBySettings from "./NormalizeBySettings";

const NormalizeByToggle = () => {
  const [visible, setVisible] = useState(false);
  const { id } = useChartsContext();
  const { normalizeBy } = useChartEntities(id);
  const showButton = normalizeBy?.show;
  const isNormalizeActive = normalizeBy?.active;

  if (!showButton) {
    return null;
  }

  return (
    <Popover
      arrowPointAtCenter
      content={<NormalizeBySettings />}
      onOpenChange={(v) => setVisible(v)}
      open={visible}
      overlayClassName="normalize-popover"
      placement="bottomRight"
      trigger="click">
      <ToolbarButton
        active={isNormalizeActive}
        data-testid={
          isNormalizeActive ? "normalizationToggleActive" : "normalizationToggleInactive"
        }
        icon={<CollapseVerticalIcon />}
        isGlobalSetting={true}
        isMenuButton={true}
        overflowLabel="Normalize"
        tooltipText="Normalize"
      />
    </Popover>
  );
};

export default memo(NormalizeByToggle);
