// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { memo } from "react";
import { useDispatch } from "react-redux";

import { mdiInformationOutline } from "@mdi/js";
import { IS_INTERNAL_ENV } from "constants/app.constants";
import { useChartsContext } from "contexts";
import { updateChartByPath } from "store/features";

import { useChartEntities } from "hooks/charts";

import ToolbarButton from "../../base/ToolbarButton";

const AnnotationToggle = () => {
  const dispatch = useDispatch();
  const { id } = useChartsContext();
  const { annotations } = useChartEntities(id);
  const isActive = annotations?.active;
  const showButton = annotations?.show;

  const toggle = (active: boolean) => {
    dispatch(
      updateChartByPath({
        id,
        path: `features.annotations.active`,
        value: active
      })
    );
  };

  if (!IS_INTERNAL_ENV || !showButton) {
    return null;
  }

  return (
    <ToolbarButton
      icon={<Icon path={mdiInformationOutline} size={1.8} />}
      active={isActive}
      overflowLabel="Producing"
      tooltipText="Toggle Annotations"
      onToggle={toggle}
    />
  );
};

export default memo(AnnotationToggle);
