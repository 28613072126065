import { EvaChart } from "constants/charts.enums";
import { EvaChartStates } from "types/factory";

import { cagrLocks } from "./cagr";
import { crossPlotLocks } from "./crossPlot";
import { rateCumLocks } from "./rateCum";

export const LOCKS_MAP = {
  [EvaChart.RateCum]: rateCumLocks,
  [EvaChart.CrossPlot]: crossPlotLocks,
  [EvaChart.CAGR]: cagrLocks
};

export function createChartLocks({
  type,
  states
}: {
  type: EvaChart;
  states: Partial<EvaChartStates>;
}): { lockedXMin: number; lockedXMax: number; lockedYMin: number; lockedYMax: number } {
  const locks = LOCKS_MAP[type];
  if (!locks) {
    throw new Error(`Unknown chart type: ${type} to create locks`);
  }
  return locks(states);
}
