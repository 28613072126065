import { DEFAULT_AXIS_LABEL_FONT_SIZE } from "constants/chart.constants";
import { YAXisOption } from "echarts/types/dist/shared";
import { createStateWithDefaults } from "entities/utils";

const DEFAULT_Y_AXIS_POINTER_LABEL_MARGIN = 3;

export const DEFAULT_Y_AXIS_OPTIONS: Partial<YAXisOption> = {
  id: "yAxis",
  triggerEvent: true,
  min: undefined,
  max: undefined,
  type: "value",
  name: "",
  nameGap: 50,
  nameLocation: "middle",
  nameTextStyle: {
    // The bottom padding will shift the name
    // so that it does not overlap with the axis-labels (top-left-bottom-right).
    padding: [0, 0, 5, 0]
  },
  nameTruncate: {
    maxWidth: 0,
    ellipsis: "..."
  },
  axisLine: {
    show: true,
    onZero: false,
    lineStyle: { color: "rgb(155,155,155)", width: 1 }
  },
  splitLine: {
    lineStyle: {
      color: "#D9D9D9"
    }
  },
  axisTick: {
    show: true,
    lineStyle: {
      width: 1
    }
  },
  axisPointer: {
    label: {
      margin: DEFAULT_Y_AXIS_POINTER_LABEL_MARGIN
    }
  },
  axisLabel: {
    fontWeight: 500,
    color: "#5B6770",
    fontSize: DEFAULT_AXIS_LABEL_FONT_SIZE,
    margin: 8
  }
};

export const generateYAxisOptions = createStateWithDefaults(DEFAULT_Y_AXIS_OPTIONS);
