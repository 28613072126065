/* eslint-disable @typescript-eslint/no-explicit-any */
import { TimeStep } from "constants/charts.enums";

import { ISeriesStyle } from "components/multiphase-chart/models/shared.models";

import { Annotation, ChartLayout } from "./chart";

export class Point2F {
  X: number | undefined;
  Y: number | undefined;
}

export interface IZoneVisOptions {
  zoneDepthFontSize: number;
  lsdHeaderFontSize: number;
  valueFontSize: number;
  legendFontSize: number;
  legendHeaderFontSize: number;
  showValue: boolean;
  showWellLabel: boolean;
  showXDAOutline: boolean;
  fillXDA: boolean;
  fillColor: string;
  showPadOutline: boolean;
  padOutlineColor: string;
  padOutlineWidth: number;
  padOutlineDashed: boolean;
}

export interface ScreenshotPresetModel {
  id?: string;
  title: string;
  width: number;
  height: number;
  titleFontSize: number;
  axisFontSize: number;
  axisLabelFontSize: number;
  topsLabelsFontSize: number;
  dataLabelsFontSize: number;
  gridLabelsFontSize: number;
  legendFontSize: number;
}

export interface ScreenshotPresetEditModel {
  id?: string;
  title: string;
  width: string;
  height: string;
  titleFontSize: string;
  axisFontSize: string;
  axisLabelFontSize: string;
  topsLabelsFontSize: string;
  dataLabelsFontSize: string;
  gridLabelsFontSize: string;
  legendFontSize: string;
}

export class IpdbLsdValue {
  uwi: string | undefined;
  val: number | undefined;
  cat: string | undefined;
  color: string | undefined;
}

export class StationSurvey {
  Location: Point2F | undefined;
  DepthSS: number | undefined;
  MeasuredDepth: number | undefined;
}

export class WellSurvey {
  Uwi: string | undefined;
  Surveys: StationSurvey[] | undefined;
}

export class Point {
  lon: number;
  lat: number;
}

export interface IGeoObj {
  geometry: any;
  properties: any;
}

export interface IGeoJSON {
  features: IGeoObj[];
}

export interface LegFeature {
  UWI: string;
  Survey: Point2F[];
}

export interface IUWIFeat {
  UWI: string;
  Color: string;
  Value: string;
  ValueN: string;
  ValueE: string;
  ValueW: string;
  IsSelected: boolean;
  Operator: string;
  Vintage: string;
  UniqueId: string;
  Thickness: number;
  FormattedUWI: string;
  Midpoint: number[];
  Surface: number[];
  BottomHole: number[];
  Survey: Point2F[];
  RenderSurfaceToBottomHoleLine: boolean;
  WellIds: string[];
  Legs: LegFeature[];
}

export class XYZRecord {
  X: number | undefined;
  Y: number | undefined;
  Z: number | undefined;
}

export class UWIList {
  UWIFeatures: IUWIFeat[] | undefined;
  Distance: number | undefined;
}

export class Perf {
  MeasuredDepth: number | undefined;
  StageNumber: number | undefined;
  Id: number | undefined;
}

export class WellVisData {
  feat: IUWIFeat | undefined;
  well: WellSurvey | undefined;
  lsds: LsdInfo[] | undefined;
  completions: Perf[] | undefined;
}

export interface IZoneVisWellData {
  AverageToeLsdDepth: number;
  Color: string;
  DepthOffset: number;
  CrossSectionPointWithOffset: Point2F;
  Shape: Point2F[];
}

export class WellWkt {
  wkt: string | undefined;
  name: string | undefined;
  entity: string | undefined;
  isParent: boolean | undefined;
}

export class KrigeSettings {
  method = "ordinary";
  variogram = "spherical";
  row = 100;
  col = 100;
  weight = false;
}

export class ClientCustomField {
  ObjectId = "";
  Name = "";
  DefType = 0;
}

export class McDArea {
  id?: number;
  layerId = 0;
  label = "";
  geom = "";
  fields: Record<string, unknown> | string = {};
  createdBy = "";
  updatedBy = "";
  parentId: number | undefined;
}

export interface ILockStatus {
  lockedBy: string;
  isLocked: boolean;
}

export interface ILayerResult {
  node: IMcDLayerNode;
  childAreas: McDArea[];
  siblings: McDArea[];
  errorMessage: string;
}

export interface IMcDLayerNode {
  name: string;
  id: string;
  layerId: number;
  isOpen: boolean;
  expanded: boolean;
  isChecked: boolean;
  headingLayerId: number;
  isSelected: boolean;
  isHeading: boolean;
  nodeId: number;
  layerOrder: number;
  children: IMcDLayerNode[];
  value: McDArea;
  parentId: string;
  lockStatus: ILockStatus;
}

export class SaveMapSettings {
  layerName = "";
  zone = "";
  gridX = 0;
  gridY = 0;
  id = "";
}

export class WellInfo {
  Uwi: string | undefined;
  AverageToeLsdSSDepth?: number;
  CrossSectionPointWithOffset?: Point2F;
  Location?: Point2F;
  Survey?: string;
  Color?: string;
  Shape?: Point2F[];
}

export enum Direction {
  X,
  Y
}

export class ZoneInfo {
  Name?: string;
  Pay?: number;
  DepthSS?: number;
}

export class Spacing {
  Lateral = 0.0;
  Vertical = 0.0;
}

export class LegendItem {
  Title = "";
  Color = "";
}

export class LegendInfo {
  GroupByTitle = "";
  LegendItems: LegendItem[] = [];
}

export class LsdInfo {
  Survey = "";
  Formatted?: string;
  Zones?: ZoneInfo[];
  NLat = 0;
  WLon = 0;
  SLat = 0;
  ELon = 0;

  ZoneOffset?: number;

  getWidth(direction: Direction) {
    if (direction === Direction.X) {
      const width = Math.abs(this.WLon - this.ELon);

      return width;
    }
    return this.NLat - this.SLat;
  }
}

export interface ISavedFilter {
  name: string;
  filterJSON: string;
}

export enum ResultMessageType {
  Ok = "ok",
  MissingNormalizeData = "missingNormalizeData",
  GeneralError = "generalError",
  NoData = "noData",
  SeriesCountExceeded = "seriesCountExceeded",
  MissingForecastData = "missingForecastData",
  WellCountExceeded = "wellCountExceeded"
}

export interface LineStyle {
  thickness: number;
  color: number;
  dashed: boolean;
  itemColors: string[];
  hexColor: string;
}

export interface ChartSeries {
  productionSource: string;
  forecastSource: string;
  x: any[];
  y: any[];
  uwiIndices: number[];
  label: string;
  style: LineStyle;
  isSecondaryYAxis: boolean;
  isForecast: boolean;
  groupTitle: string;
  xAxisIndex: number;
  yAxisIndex: number;
  type?: string;
  isComparisonForecast?: Nullable<boolean>;
  mode?: Primitive;
  timeStep?: TimeStep;
  xAxisTitle?: string;
  yAxisTitle?: string;
  numOfZeroRateWells?: Nullable<number>;
  annotations?: Annotation[];
}

export enum AxisType {
  Linear = 0,
  Log = 1,
  Category = 2,
  Date = 3,
  Year = 4
}

export interface TickLabels {
  label: string;
  value: number;
}

export interface ChartAxis {
  title: string;
  defaultAxisType: AxisType;
  labels: TickLabels[];
  min: number;
  max: number;
  interval: number;
  data: any[];
  logMin: number | null;
  yScaleToX: number;
}

export interface ChartMessage {
  message: string;
  messageType: string;
  entities: string[];
}

export interface WellSources {
  forecastSources: string[];
  productionDataSources: string[];
}

export interface SyncState {
  isForecastSynced: boolean;
  isProductionSynced: boolean;
  syncedSchema: string;
  warningMessage?: string;
}

export interface IChartResult {
  wellSources?: WellSources;
  errorMessage: string;
  errorEntities: string[];
  layout: ChartLayout;
  messages: ChartMessage[];
  messageType: ResultMessageType;
  requestId: string;
  title: string;
  series: ChartSeries[];
  uwis?: string[];
  syncStatus: SyncState;
  // eslint-disable-next-line @typescript-eslint/ban-types
  uwiCoordinates?: object;
  dotSize?: number;
}

export interface AddChartPresetModel {
  id: string;
  name: string;
  products: ProductPreset[];
  chartType: string;
  rateType: string;
  mBblPosition: string;
  mMcfPosition: string;
  combineSameUnitAxis?: boolean;
  roleType: string;
  style: ISeriesStyle[];
  ownerId: string;
}

export interface UpdateChartPresetModel {
  id: string;
  name: string;
  products: ProductPreset[];
  chartType: string;
  rateType: string;
  mBblPosition: string;
  mMcfPosition: string;
  combineSameUnitAxis?: boolean;
  roleType: string;
  style: ISeriesStyle[];
  ownerId: string;
}

export interface ProductPreset {
  name: string;
  axis: string;
}

export interface Geometry {
  type: string;
  coordinates: number[][][];
}

export interface GeomT {
  ShapefileID: string;
  shapefileGeomId: string;
  ProjectShapefileID: string;
  Color?: any;
  Opacity?: any;
  Thickness: number;
  StrokeColor?: any;
  Geometry: Geometry;
  Properties: { [name: string]: any };
  CreatedDate: Date;
  checked: boolean;
}

export interface RasterAttributes {
  min: number;
  max: number;
}

export interface ShapefileT {
  shapefileId: string;
  shapefileNodeId: string;
  projectId: string;
  owner: string;
  createdDate: Date;
  modifiedDate?: any;
  name: string;
  geoms: GeomT[];
  key: string;
  title: string;
  type: string;
  color: string;
  opacity?: any;
  strokeColor: string;
  order: number;
  thickness: number;
  properties: string[];
}
