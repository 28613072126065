import { LineSeriesOption } from "echarts";
import { createStateWithDefaults } from "entities/utils";
import { SeriesType } from "types/echarts";

export const DEFAULT_LINE_CHART_OPTIONS: Partial<LineSeriesOption> = {
  name: "",
  type: SeriesType.Line,
  showSymbol: false,
  animation: false,
  blendMode: "source-over",
  lineStyle: { width: 2, type: "solid", opacity: 1 },
  z: 2,
  blur: {}
};

export const generateLineSeriesOptions = createStateWithDefaults(
  DEFAULT_LINE_CHART_OPTIONS
);
