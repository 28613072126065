import { PieSeriesOption } from "echarts";
import { createStateWithDefaults } from "entities/utils";
import { SeriesType } from "types/echarts";

export const DEFAULT_PIE_CHART_OPTIONS: Partial<PieSeriesOption> = {
  name: "",
  type: SeriesType.Pie,
  animation: false,
  blendMode: "source-over",
  z: 2, // TODO Chart: check if we can use z-index for highlighting target data point
  emphasis: {
    focus: "series",
    itemStyle: {
      borderWidth: 3,
      borderRadius: 10,
      // borderColor: "rgba(0,0,0,0.5)",
      opacity: 1,
      decal: {
        backgroundColor: "white"
      }
    }
  },
  blur: {}
};

export const generatePieSeriesOptions = createStateWithDefaults(
  DEFAULT_PIE_CHART_OPTIONS
);
