import { useState } from "react";
import { useDispatch } from "react-redux";

import { Button, Popover } from "antd";
import { ALL_PRODUCT_TYPES } from "constants/chart.constants";
import styled from "styled-components";
import { getProductIcon } from "utils/chart/getProductIcon";

import { BaseButton, BaseTooltip } from "components/base";
import ProductHeader from "components/chart/ProductHeader";

import { useChartsContext } from "../../../contexts";
import { useChartEntities } from "../../../hooks/charts";
import { IProductType } from "../../../models/chart";
import { updateChartByPath } from "../../../store/features";

const Products = [
  {
    name: "Product",
    items: [
      ALL_PRODUCT_TYPES.Oil,
      ALL_PRODUCT_TYPES.Gas,
      ALL_PRODUCT_TYPES.Water,
      ALL_PRODUCT_TYPES.CGR,
      ALL_PRODUCT_TYPES.GOR
    ]
  }
];

// Note: In order to be able to select the pressure charts in the app you have to set your
// data source to "Private" and then set the timestep to daily, this will cause the pressure
// charts to be available for selection.
export default function PressureChartOptions() {
  const { id } = useChartsContext();
  const { pressureChartSettings } = useChartEntities(id);
  const dispatch = useDispatch();

  const selectedItem = ALL_PRODUCT_TYPES[pressureChartSettings.properties.product];

  const [popoverVisible, setPopoverVisible] = useState(false);

  const handleProductSelect = (product: IProductType) => {
    dispatch(
      updateChartByPath({
        id,
        path: "features.pressureChartSettings.properties.product",
        value: product.key
      })
    );
    setPopoverVisible(false);
  };

  const content = (
    <ProductContainer>
      {Products.map((header) => (
        <ProductHeader key={header.name} header={header.name}>
          {header.items.map((product) => (
            <ProductButton
              data-testId={`pressureProductButton${product.key}`}
              key={product}
              ellipses={false}
              onClick={() => {
                handleProductSelect(product);
              }}
              appearance=""
              className={`${product.key === selectedItem.key ? "isSelected" : null}`}>
              {getProductIcon(product.label)}
              <span> {product.label}</span>
            </ProductButton>
          ))}
        </ProductHeader>
      ))}
    </ProductContainer>
  );

  return (
    <Wrapper>
      <BaseTooltip text={selectedItem.label}>
        <Popover
          content={content}
          placement="top"
          trigger="click"
          visible={popoverVisible}
          onVisibleChange={(visible) => setPopoverVisible(visible)}
          overlayClassName="group-by-popover">
          <StyledButton
            data-testId="pressureProductSelector"
            onClick={() => setPopoverVisible(!popoverVisible)}>
            {selectedItem.label}
          </StyledButton>
        </Popover>
      </BaseTooltip>
    </Wrapper>
  );
}

const ProductButton = styled(BaseButton)`
  min-height: 60px;
  height: auto;
  border-radius: 4px;
  color: #041c2c;
  background-color: unset;
  font-weight: var(--fontWeightMedium);
  font-size: 12px;

  &.isSelected {
    background: rgba(var(--color-shadow-rgb), 0.3);
    color: var(--color-primary);

    svg {
      color: var(--color-primary);
    }
  }

  span {
    margin-top: 0;
  }

  .label {
    line-height: 18px;
    margin: 0 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    background: rgba(var(--color-shadow-rgb), 0.3);

    svg {
      color: var(--color-primary);
    }
  }

  &.isSelected:hover {
    background: rgba(var(--color-shadow-rgb), 0.9);

    svg {
      color: var(--color-primary);
    }
  }

  svg {
    min-height: 40px;
    color: #a2aaad;
    transition: color var(--duration-short);
  }
`;

const ProductContainer = styled.div`
  width: 375px;
  padding-bottom: 25px;
  max-height: 90vh;
  overflow-y: auto; /* Enable vertical scrolling */

  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 1px solid #d9e1e2;
  }

  .ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs-nav-list {
    display: flex;
    justify-content: center;
    padding: 0 25px;
  }

  .ant-tabs-tab .ant-tabs-tab-btn {
    font-weight: bold;
    font-size: 1.4rem;
    padding: 0 0px;
    margin: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  max-width: 180px;
  width: 180px;

  & > span {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
