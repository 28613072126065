import {
  ALL_PRODUCT_TYPES,
  BLACK_HEX,
  DEFAULT_DOT_OPACITY,
  DEFAULT_LINE_THICKNESS,
  DEFAULT_NORMALIZE_BY_DISPLAY_FIELD,
  DEFAULT_NORMALIZE_BY_FIELD,
  DEFAULT_NORMALIZE_BY_HIGHER_SCALER,
  DEFAULT_NORMALIZE_BY_LOWER_SCALER,
  DEFAULT_NORMALIZE_BY_PER,
  DEFAULT_NORMALIZE_BY_THRESHOLD,
  DEFAULT_NORMALIZE_BY_UNIT,
  DEFAULT_POINT_SIZE,
  ORIGIN
} from "constants/chart.constants";
import { EvaSource, TimeStep } from "constants/charts.enums";
import { createStateWithDefaults } from "entities/utils";
import {
  ChartBaseFeature,
  ChartFocusFeature,
  ChartTypeParamFeature,
  CutOffFeature,
  DataTableFeature,
  LassoFeature,
  MovingAverageDaysFeature,
  NormalizationFeature,
  PointOpacityFeature,
  PointSizeFeature,
  PressureChartSettingsFeature,
  ScreenshotFeature,
  ShutInMonthsFeature,
  SourceFeature,
  StatisticsFeature,
  TimeStepFeature
} from "types";

import { DEFAULT_COLOR_PALETTE_NAME } from "../../../../components/user-settings/models";
import { EntityKind } from "../../../../models/entityKind";
import { PdenSourceEnum } from "../../../../models/pdenDataSourceSetting";

export const DEFAULT_FEATURE_ENTITY: Partial<ChartBaseFeature> = {
  properties: {},
  show: false,
  active: false,
  enabled: false
};

export const generateChartsFeatureEntity =
  createStateWithDefaults(DEFAULT_FEATURE_ENTITY);

export const CHART_DATA_TABLE_LABEL = "Show Raw Data";
export const DEFAULT_DATA_TABLE_FEATURE_ENTITY: Partial<DataTableFeature> = {
  ...DEFAULT_FEATURE_ENTITY,
  properties: {
    tooltipText: CHART_DATA_TABLE_LABEL,
    overflowLabel: CHART_DATA_TABLE_LABEL
  }
};

export const generateDataTableFeatureEntity = createStateWithDefaults(
  DEFAULT_DATA_TABLE_FEATURE_ENTITY
);

export const DEFAULT_SOURCE_FEATURE_ENTITY: Partial<SourceFeature> = {
  ...DEFAULT_FEATURE_ENTITY,
  properties: {
    value: EvaSource.Public
  }
};

export const generateSourceFeatureEntity = createStateWithDefaults(
  DEFAULT_SOURCE_FEATURE_ENTITY
);

export const DEFAULT_TIME_STEP_FEATURE_ENTITY: Partial<TimeStepFeature> = {
  ...DEFAULT_FEATURE_ENTITY,
  properties: {
    value: TimeStep.Month
  }
};

export const generateTimeStepFeatureEntity = createStateWithDefaults(
  DEFAULT_TIME_STEP_FEATURE_ENTITY
);

export const DEFAULT_CUT_OFF_FEATURE_ENTITY: Partial<CutOffFeature> = {
  ...DEFAULT_FEATURE_ENTITY,
  properties: {
    value: 50
  }
};

export const generateCutOffFeatureEntity = createStateWithDefaults(
  DEFAULT_CUT_OFF_FEATURE_ENTITY
);

export const DEFAULT_SHUT_IN_MONTHS_FEATURE_ENTITY: Partial<ShutInMonthsFeature> = {
  ...DEFAULT_FEATURE_ENTITY,
  properties: {
    value: 3
  }
};

export const generateShutInMonthsFeatureEntity = createStateWithDefaults(
  DEFAULT_SHUT_IN_MONTHS_FEATURE_ENTITY
);
export const DEFAULT_MOVING_AVERAGE_DAYS_FEATURE_ENTITY: Partial<MovingAverageDaysFeature> =
  {
    ...DEFAULT_FEATURE_ENTITY,
    properties: {
      value: 0
    }
  };

export const generateMovingAverageDaysFeatureEntity = createStateWithDefaults(
  DEFAULT_MOVING_AVERAGE_DAYS_FEATURE_ENTITY
);

export const DEFAULT_POINT_OPACITY_FEATURE_ENTITY: Partial<PointOpacityFeature> = {
  ...DEFAULT_FEATURE_ENTITY,
  properties: {
    value: DEFAULT_DOT_OPACITY
  }
};

export const generatePointOpacityFeatureEntity = createStateWithDefaults(
  DEFAULT_POINT_OPACITY_FEATURE_ENTITY
);

export const DEFAULT_POINT_SIZE_FEATURE_ENTITY: Partial<PointSizeFeature> = {
  ...DEFAULT_FEATURE_ENTITY,
  properties: {
    value: DEFAULT_POINT_SIZE
  }
};

export const generatePointSizeFeatureEntity = createStateWithDefaults(
  DEFAULT_POINT_SIZE_FEATURE_ENTITY
);

export const DEFAULT_LASSO_FEATURE_ENTITY: Partial<LassoFeature> = {
  ...DEFAULT_FEATURE_ENTITY,
  properties: {
    list: [],
    brushEnd: false,
    position: ORIGIN
  }
};

export const generateLassoFeatureEntity = createStateWithDefaults(
  DEFAULT_LASSO_FEATURE_ENTITY
);
export const DEFAULT_P10_FEATURE_ENTITY: Partial<StatisticsFeature> = {
  ...DEFAULT_FEATURE_ENTITY,
  properties: {
    color: BLACK_HEX,
    thickness: DEFAULT_LINE_THICKNESS
  }
};

export const generateP10FeatureEntity = createStateWithDefaults(
  DEFAULT_P10_FEATURE_ENTITY
);

export const DEFAULT_P50_FEATURE_ENTITY: Partial<StatisticsFeature> = {
  ...DEFAULT_FEATURE_ENTITY,
  properties: {
    color: BLACK_HEX,
    thickness: DEFAULT_LINE_THICKNESS
  }
};

export const generateP50FeatureEntity = createStateWithDefaults(
  DEFAULT_P50_FEATURE_ENTITY
);

export const DEFAULT_P90_FEATURE_ENTITY: Partial<StatisticsFeature> = {
  ...DEFAULT_FEATURE_ENTITY,
  properties: {
    color: BLACK_HEX,
    thickness: DEFAULT_LINE_THICKNESS
  }
};

export const generateP90FeatureEntity = createStateWithDefaults(
  DEFAULT_P90_FEATURE_ENTITY
);

export const DEFAULT_AVERAGE_FEATURE_ENTITY: Partial<StatisticsFeature> = {
  ...DEFAULT_FEATURE_ENTITY,
  properties: {
    color: BLACK_HEX,
    thickness: DEFAULT_LINE_THICKNESS
  }
};

export const generateAverageFeatureEntity = createStateWithDefaults(
  DEFAULT_AVERAGE_FEATURE_ENTITY
);

export const DEFAULT_SCREENSHOT_FEATURE_ENTITY: Partial<ScreenshotFeature> = {
  ...DEFAULT_FEATURE_ENTITY,
  properties: {
    preset: undefined
  }
};

export const generateScreenshotFeatureEntity = createStateWithDefaults(
  DEFAULT_SCREENSHOT_FEATURE_ENTITY
);

export const DEFAULT_NORMALIZATION_ENTITY: Partial<NormalizationFeature> = {
  ...DEFAULT_FEATURE_ENTITY,
  properties: {
    displayName: DEFAULT_NORMALIZE_BY_DISPLAY_FIELD,
    field: DEFAULT_NORMALIZE_BY_FIELD,
    higherScalar: DEFAULT_NORMALIZE_BY_HIGHER_SCALER,
    lowerScalar: DEFAULT_NORMALIZE_BY_LOWER_SCALER,
    per: DEFAULT_NORMALIZE_BY_PER,
    threshold: DEFAULT_NORMALIZE_BY_THRESHOLD,
    unit: DEFAULT_NORMALIZE_BY_UNIT,
    useMultilinearNormalization: false,
    useNormalizeBy: false
  }
};

export const generateNormalizationFeatureEntity = createStateWithDefaults(
  DEFAULT_NORMALIZATION_ENTITY
);

export const DEFAULT_CHART_FOCUS_ENTITY: Partial<ChartFocusFeature> = {
  ...DEFAULT_FEATURE_ENTITY,
  properties: {
    colorPalette: {
      id: "-1",
      name: DEFAULT_COLOR_PALETTE_NAME,
      colorScale: [],
      reverse: false,
      shared: false,
      paletteType: 0,
      thickness: [],
      preferredColors: [],
      colors: []
    },
    groupBy: {
      title: "Resource Play",
      property: "Header.ResourcePlay",
      groupByField: "Header.ResourcePlay",
      pdenSource: EvaSource.Public,
      canBin: false,
      dataType: "Text",
      categoryId: 1,
      tooltip: "",
      display: "",
      entityKind: EntityKind.Well,
      bin: {
        BinSize: "",
        MinSize: "",
        MaxBins: "",
        MaxBinsSortOrder: "WellCount",
        GreaterThan: "",
        BinType: "BinSize",
        LessThan: "",
        UseDynamicBins: false
      }
    }
  }
};

export const generateChartFocusEntity = createStateWithDefaults(
  DEFAULT_CHART_FOCUS_ENTITY
);

export const DEFAULT_CHART_FOCUS_MIDSTREAM_ENTITY: Partial<ChartFocusFeature> = {
  ...DEFAULT_FEATURE_ENTITY,
  properties: {
    colorPalette: {
      id: "-1",
      name: DEFAULT_COLOR_PALETTE_NAME,
      colorScale: [],
      reverse: false,
      shared: false,
      paletteType: 0,
      thickness: [],
      preferredColors: [],
      colors: []
    },
    groupBy: {
      title: "Facility Operator",
      property: "Header.OperatorName",
      groupByField: "Header.OperatorName",
      pdenSource: EvaSource.Public,
      canBin: false,
      dataType: "Text",
      categoryId: 1,
      tooltip: "",
      display: "",
      entityKind: EntityKind.Facility,
      bin: {
        BinSize: "",
        MinSize: "",
        MaxBins: "",
        GreaterThan: "",
        BinType: "BinSize",
        LessThan: "",
        UseDynamicBins: false
      }
    }
  }
};

export const generateMidstreamChartFocusEntity = createStateWithDefaults(
  DEFAULT_CHART_FOCUS_MIDSTREAM_ENTITY
);

export const DEFAULT_CHART_TYPE_PARAM_FEATURE: Partial<ChartTypeParamFeature> = {
  ...DEFAULT_FEATURE_ENTITY,
  properties: {
    x: {
      group: "",
      subgroup: "",
      product: "",
      property: "",
      title: "",
      defaultUnit: "",
      dataType: "Text",
      canBin: false,
      pdenSource: PdenSourceEnum.Public.toString()
    },
    y: {
      group: "",
      subgroup: "",
      product: "",
      property: "",
      title: "",
      defaultUnit: "",
      dataType: "Text",
      canBin: false,
      pdenSource: PdenSourceEnum.Public.toString()
    }
  }
};

export const generateChartTypeParamFeature = createStateWithDefaults(
  DEFAULT_CHART_TYPE_PARAM_FEATURE
);

export const DEFAULT_PRESSURE_CHART_SETTINGS_ENTITY: Partial<PressureChartSettingsFeature> =
  {
    ...DEFAULT_FEATURE_ENTITY,
    properties: {
      product: ALL_PRODUCT_TYPES.Oil.key
    }
  };

export const generatePressureChartSettingsFeature = createStateWithDefaults(
  DEFAULT_PRESSURE_CHART_SETTINGS_ENTITY
);
