import { memo, useState } from "react";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Popover } from "antd";
import { EvaChart } from "constants/charts.enums";
import { useChartsContext } from "contexts";
import styled from "styled-components/macro";

import { useChartEntities } from "hooks/charts";
import useBetaFeatures from "hooks/useBetaFeatures";

import ToolbarButton from "../../base/ToolbarButton";
import { components } from "../components/ChartToolbar";

const OverflowToggle = () => {
  const { id } = useChartsContext();
  const { chartType, overflowIndex } = useChartEntities(id);
  const [overflowVisible, setOverflowVisible] = useState(false);

  const { hasFeature } = useBetaFeatures();
  // const { overflowIndex, chartCapabilities, settings } = useChartState();
  // if only one item is in the overflow menu, don't overflow
  const isOverflowing = overflowIndex < components.length - 1;
  const overflowComponents = components
    // .filter((c) => {
    //   // if the component is not in the availableChartCapabilities, it should always be shown
    //   if (!availableChartCapabilities.includes(c.key as string)) {
    //     return true;
    //   }
    //   return chartCapabilities.findIndex((capability) => capability.key === c.key) >= 0;
    // })
    .slice(overflowIndex)
    .filter(
      (c) =>
        c.key !== "globalSettingsDivider" &&
        !(c.key === "referenceLines" && !hasFeature("Chart Reference Lines")) &&
        !(c.key === "slopeLines" && chartType !== EvaChart.MaterialBalanceTime)
    );

  if (!isOverflowing) return null;

  return (
    <Popover
      arrowPointAtCenter
      content={<OverflowWrapper>{overflowComponents}</OverflowWrapper>}
      placement="bottomRight"
      trigger="click"
      open={overflowVisible}
      onOpenChange={(v) => setOverflowVisible(v)}>
      <ToolbarButton
        active={false}
        data-testid="chart-options-overflow-button"
        icon={<MoreVertIcon fontSize="large" />}
        overflowLabel=""
        tooltipText="More"
      />
    </Popover>
  );
};

export default memo(OverflowToggle);

const OverflowWrapper = styled.div`
  display: grid;
  gap: 4px;
  max-height: 300px;
  border-radius: var(--border-radius);
  padding: 8px 12px;
  margin: -12px -16px;
  overflow: hidden scroll;

  & > button {
    width: auto !important;
  }
`;
