import { EvaChart } from "constants/charts.enums";
import { EvaChartFeatures } from "types";

import { cagrFeatures } from "./cagr";
import { crossPlotFeatures } from "./crossPlot";
import { crossPlotMidstreamFeatures } from "./crossPlotMidstream";
import { cumTimeFeatures } from "./cumTime";
import { declineRateFeatures } from "./declineRate";
import { mosaicFeatures } from "./mosaic";
import { pieFeatures } from "./pie";
import { rateCumFeatures } from "./rateCum";
import { rateDateFeatures } from "./rateDate";
import { rateDateMidstreamFeatures } from "./rateDateMidstream";
import { rateTimeFeatures } from "./rateTime";
import { totalRateCumFeatures } from "./totalRateCum";

export const FEATURE_MAP = {
  [EvaChart.RateCum]: rateCumFeatures,
  [EvaChart.CrossPlot]: crossPlotFeatures,
  [EvaChart.CumTime]: cumTimeFeatures,
  [EvaChart.RateTime]: rateTimeFeatures,
  [EvaChart.Pie]: pieFeatures,
  [EvaChart.RateDate]: rateDateFeatures,
  [EvaChart.TotalRateCum]: totalRateCumFeatures,
  [EvaChart.DeclineRate]: declineRateFeatures,
  [EvaChart.CAGR]: cagrFeatures,
  [EvaChart.Mosaic]: mosaicFeatures,

  // midstream charts
  [EvaChart.RateDateMidstream]: rateDateMidstreamFeatures,
  [EvaChart.CrossPlotMidstream]: crossPlotMidstreamFeatures
};

export function createFeatureEntities(
  type: EvaChart,
  overrides: Partial<EvaChartFeatures> = {}
): EvaChartFeatures {
  const features = FEATURE_MAP[type];
  if (!features) {
    throw new Error(`Unknown chart type: ${type} to create features`);
  }
  return features(overrides);
}
