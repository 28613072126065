import { EvaChart } from "constants/charts.enums";
import { LegendStates, MouseSelectionStates, SelectedParams } from "types";

import { ChartDependencies } from "hooks/charts";

import { cagrHoverLegends, cagrSelectionParams } from "./cagr";
import { crossPlotHoverLegends, crossPlotSelectionParams } from "./crossPlot";
import { cumTimeHoverLegends } from "./cumTime";
import { declineRateHoverLegends, declineRateSelectionParams } from "./declineRate";
import { mosaicHoverLegends, mosaicSelectionParams } from "./mosaic";
import { pieHoverLegends, pieSelectionParams } from "./pie";
import { rateCumHoverLegends, rateCumSelectionParams } from "./rateCum";
import { rateDateHoverLegends, rateDateSelectionParams } from "./rateDate";
import {
  rateDateMidstreamHoverLegends,
  rateDateMidstreamSelectionParams
} from "./rateDateMidstream";
import { rateTimeHoverLegends, rateTimeSelectionParams } from "./rateTime";
import { totalRateCumHoverLegends, totalRateCumSelectionParams } from "./totalRateCum";

const PARAM_TRANSLATOR_MAP = {
  [EvaChart.RateCum]: rateCumSelectionParams,
  [EvaChart.CrossPlot]: crossPlotSelectionParams,
  [EvaChart.CumTime]: rateCumSelectionParams,
  [EvaChart.RateTime]: rateTimeSelectionParams,
  [EvaChart.Pie]: pieSelectionParams,
  [EvaChart.RateDate]: rateDateSelectionParams,
  [EvaChart.TotalRateCum]: totalRateCumSelectionParams,
  [EvaChart.DeclineRate]: declineRateSelectionParams,
  [EvaChart.CAGR]: cagrSelectionParams,
  [EvaChart.Mosaic]: mosaicSelectionParams,

  // midstream charts
  [EvaChart.RateDateMidstream]: rateDateMidstreamSelectionParams,
  [EvaChart.CrossPlotMidstream]: crossPlotSelectionParams
};

export function translateLegendsToParams({
  type,
  states,
  series,
  forecast
}: LegendStates): SelectedParams {
  const translator = PARAM_TRANSLATOR_MAP[type];
  if (!translator) {
    throw new Error(`Unknown chart type: ${type} to translate legends to options`);
  }
  return translator({ states, series, forecast });
}

const LEGEND_TRANSLATOR_MAP = {
  [EvaChart.RateCum]: rateCumHoverLegends,
  [EvaChart.CrossPlot]: crossPlotHoverLegends,
  [EvaChart.CumTime]: cumTimeHoverLegends,
  [EvaChart.RateTime]: rateTimeHoverLegends,
  [EvaChart.Pie]: pieHoverLegends,
  [EvaChart.RateDate]: rateDateHoverLegends,
  [EvaChart.TotalRateCum]: totalRateCumHoverLegends,
  [EvaChart.DeclineRate]: declineRateHoverLegends,
  [EvaChart.CAGR]: cagrHoverLegends,
  [EvaChart.Mosaic]: mosaicHoverLegends,

  // midstream charts
  [EvaChart.RateDateMidstream]: rateDateMidstreamHoverLegends,
  [EvaChart.CrossPlotMidstream]: crossPlotHoverLegends
};

export function translateSelectionToLegends({
  type,
  params,
  series,
  response,
  forecast
}: MouseSelectionStates): Partial<ChartDependencies> {
  const translator = LEGEND_TRANSLATOR_MAP[type];
  if (!translator) {
    throw new Error(`Unknown chart type: ${type} to translate selection to legends`);
  }
  return translator({ params, series, response, forecast });
}
