import { DEFAULT_AXIS_LABEL_FONT_SIZE } from "constants/chart.constants";
import { XAXisOption } from "echarts/types/dist/shared";
import { createStateWithDefaults } from "entities/utils";

export const DEFAULT_X_AXIS_OPTIONS: Partial<XAXisOption> = {
  type: "value",
  triggerEvent: true,
  name: "",
  id: "xAxis",
  nameLocation: "middle",
  min: undefined,
  max: undefined,
  nameGap: 30,
  axisLine: {
    show: true,
    onZero: false,
    lineStyle: { color: "rgb(155,155,155)", width: 1 }
  },
  splitLine: {
    lineStyle: {
      color: "#D9D9D9"
    }
  },
  axisTick: {
    show: true,
    lineStyle: {
      width: 1
    }
  },
  axisLabel: {
    fontWeight: 500,
    color: "#5B6770",
    fontSize: DEFAULT_AXIS_LABEL_FONT_SIZE,
    padding: [5, 0, 0, 2]
  }
};

export const generateXAxisOptions = createStateWithDefaults(DEFAULT_X_AXIS_OPTIONS);
