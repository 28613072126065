import { TooltipOption } from "echarts/types/dist/shared";
import { EvaChartStates } from "types/factory";
import { removeCount } from "utils";

import { getPrecision, getSeriesTooltipDot, getUnit } from "components/chart/utils";

export function translateMosaicResponseTooltipOptions(
  states: EvaChartStates
): TooltipOption {
  const { layout } = states;

  const yAxisTitle = layout?.yAxis?.title || "";
  return {
    trigger: "item",
    confine: true,
    enterable: true,
    extraCssText: "border-style:none;",
    className: "chart-tooltip-container",
    showDelay: 500,
    hideDelay: 0,
    show: true,
    axisPointer: {
      label: {
        precision: 2,
        show: true
      },
      type: "cross"
    },

    position: function (point, _, dom) {
      const xPos = point[0];
      const yPos =
        point[1] - (dom as HTMLDivElement).offsetHeight > 48
          ? point[1] - (dom as HTMLDivElement).offsetHeight
          : 48;
      return [xPos, yPos]; //position top right of mouse cursor
    },
    formatter: (items) => {
      const series = items;
      if (series) {
        const value = Array.isArray(series.value) ? series.value[1] : series.value;
        if (series.value.length > 4) {
          const percentage = series.value[4] - series.value[3];
          const wellCount = series.value[0].match(`(?<=\\()\\d+?(?=\\))`);
          return `<p style="text-align:right;">${getSeriesTooltipDot(
            series.color
          )} ${removeCount(
            series.value[0]
          )}:&emsp;<span style="font-weight:700;color:#666;font-family: var(--fontMono);">${wellCount} (${percentage.toFixed(
            2
          )}%)</span></p>`;
        }
        const formattedValue =
          value !== "" && yAxisTitle
            ? value.toFixed(getPrecision(getUnit(yAxisTitle)))
            : value;

        const seriesName = series.seriesName;
        return `<p style="text-align:right;">${getSeriesTooltipDot(
          series.color
        )} ${seriesName}:&emsp;<span style="font-weight:700;color:#666;font-family: var(--fontMono);">${formattedValue}</span></p>`;
      }
      return "";
    }
  };
}
