import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { ORIGIN } from "constants/chart.constants";
import { useChartsContext } from "contexts/ChartContext";
import { updateChartByPath } from "store/features";
import { ZRenderActionType } from "types/echarts";

import { useChartEntities } from "../useChartEntities";

export function useChartZRender(chartInstanceRef: React.RefObject<echarts.ECharts>) {
  const dispatch = useDispatch();
  const { id } = useChartsContext();
  const { lasso } = useChartEntities(id);
  const active = lasso.active;

  useEffect(() => {
    if (!chartInstanceRef.current) return;
    const chartInstance = chartInstanceRef.current;
    const zr = chartInstance.getZr();

    const mouseupHandler = (e) => {
      if (active) {
        const { event } = e;
        const { offsetX, offsetY } = event;
        const position = { x: offsetX, y: offsetY };

        dispatch(
          updateChartByPath({
            id,
            path: `features.lasso.properties.position`,
            value: position
          })
        );
      }
    };

    const mousedownHandler = () => {
      dispatch(
        updateChartByPath({
          id,
          path: `features.lasso.properties.position`,
          value: ORIGIN
        })
      );
    };
    zr.on(ZRenderActionType.MouseUp, mouseupHandler);
    zr.on(ZRenderActionType.MouseDown, mousedownHandler);

    return () => {
      if (!chartInstanceRef.current) return;

      const zr = chartInstanceRef.current?.getZr();
      if (!zr) return;

      zr.off(ZRenderActionType.MouseUp, mouseupHandler);
      zr.off(ZRenderActionType.MouseDown, mousedownHandler);
    };
  }, [chartInstanceRef.current, active, dispatch, id]);
}
