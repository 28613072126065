import { useEffect, useRef, useState } from "react";

import { useChartsContext } from "contexts";

import { useChartDependencies, useChartEntities } from "hooks/charts";

import { LegendItemModel } from "models";

import { createLegendItem } from "components/chart/utils";
import useTypeWellSeries from "components/charts/hooks/useTypeWellSeries";

const useTypeWellLegendItems = (): LegendItemModel[] => {
  // context
  const { id: chartId, entityKind } = useChartsContext();
  const { normalizeBy, typewells } = useChartEntities(chartId);
  const typeWellsActive = typewells?.active;

  // state
  const { checkedGlobalTypeWells, globalNormalizeBy, normalizeTypeWell, useNormalizeBy } =
    useChartDependencies(entityKind);
  const typeWellSeries = useTypeWellSeries(
    checkedGlobalTypeWells,
    normalizeBy?.active && normalizeBy?.properties
      ? normalizeBy.properties
      : globalNormalizeBy,
    normalizeBy?.active ? true : useNormalizeBy,
    normalizeTypeWell,
    typeWellsActive
  );

  const [items, setItems] = useState<LegendItemModel[]>([]);

  // derived state
  const isTypeWellsEnabled = typewells?.active;

  const prevTypeWellSeriesRef = useRef(null);

  // effects
  useEffect(() => {
    // useTypeWellSeries will continously return an empty array if typewells is not enabled
    // need a ref to not setItems continously which causes an infinite loop
    if (
      JSON.stringify(prevTypeWellSeriesRef.current) === JSON.stringify(typeWellSeries)
    ) {
      return;
    }
    prevTypeWellSeriesRef.current = typeWellSeries;

    if (!isTypeWellsEnabled || !checkedGlobalTypeWells?.length) {
      setItems([]);
      return;
    }
    const nextList = checkedGlobalTypeWells
      .filter((item) => {
        // only include type wells in legend that are shown on the chart
        return (
          typeWellSeries.some((tw) => tw.name === item.title) || item.type === "folder"
        );
      })
      .map((item) => {
        return item.type === "folder"
          ? item.children
              .filter((item) => {
                return typeWellSeries.some((tw) => tw.name === item.title);
              })
              .map((child) =>
                createLegendItem(child.title, true, child.color, "#555555", child.id)
              )
          : createLegendItem(item.title, true, item.color, "#555555", item.id);
      })
      .flatMap((x) => x);

    setItems(nextList);
  }, [isTypeWellsEnabled, checkedGlobalTypeWells, typeWellSeries]);

  return items;
};

export default useTypeWellLegendItems;
