import { useEffect, useState } from "react";

import { BLACK_HEX } from "constants/chart.constants";
import { EvaChart, EvaStatisticsKind } from "constants/charts.enums";
import { useChartsContext } from "contexts";

import { useChartEntities } from "hooks/charts";

import { LegendItemModel } from "models";

import { createLegendItem, verifyChartTypeCanUseStats } from "components/chart/utils";

const useStatisticsLegendItem = (
  chartEntityKey: EvaStatisticsKind
): LegendItemModel[] => {
  const { id } = useChartsContext();
  const { chartType, [chartEntityKey]: chartEntity } = useChartEntities(id);
  const [items, setItems] = useState<LegendItemModel[]>([]);

  const isLegendEnabled = Boolean(chartEntity?.active);
  const canChartShowStats =
    verifyChartTypeCanUseStats(chartType) &&
    (chartEntityKey === EvaStatisticsKind.Average ||
      chartType !== EvaChart.MaterialBalanceTime); // Example: Material Balance Time does not support P10.

  useEffect(() => {
    if (!isLegendEnabled || !canChartShowStats) {
      setItems([]);
      return;
    }

    const color = chartEntity?.properties?.color || BLACK_HEX;
    const capitalizedKey =
      chartEntityKey && chartEntityKey.length > 0
        ? chartEntityKey.charAt(0).toUpperCase() + chartEntityKey.slice(1)
        : "";
    const legendItem = createLegendItem(capitalizedKey, true, color as string);

    setItems([legendItem]);
  }, [isLegendEnabled, canChartShowStats, chartEntity?.properties]);

  return items;
};

export default useStatisticsLegendItem;
