import { memo } from "react";
import { useDispatch } from "react-redux";

import { useChartsContext } from "contexts/ChartContext";
import { updateChartByPath } from "store/features";

import { useChartEntities } from "hooks/charts";

import { UserArps } from "components/icons";

import ToolbarButton from "../../base/ToolbarButton";

const TypewellsToggle = () => {
  const dispatch = useDispatch();
  const { id } = useChartsContext();
  const { typewells } = useChartEntities(id);
  const isEnabled = typewells.enabled;
  const isActive = typewells.active;
  const showButton = typewells.show;

  const toggle = (active: boolean) => {
    dispatch(
      updateChartByPath({
        id,
        path: `features.typewells.active`,
        value: active
      })
    );
  };

  if (!showButton) {
    return null;
  }

  return (
    <ToolbarButton
      active={isActive}
      icon={<UserArps />}
      overflowLabel="Typewells"
      tooltipText="Type Wells"
      onToggle={toggle}
      disabled={!isEnabled}
    />
  );
};

export default memo(TypewellsToggle);
