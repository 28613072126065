import classnames from "classnames";

import { XMaxLocker, XMinLocker } from "../AxisLocker";
import { Wrapper } from "./AxisContainer.styles";

export function XAxisContainer() {
  const wrapperClassnames = classnames({
    xAxis: true
  });

  return (
    <Wrapper className={wrapperClassnames}>
      <XMaxLocker />
      <XMinLocker />
    </Wrapper>
  );
}
