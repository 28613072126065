import { useSelector } from "react-redux";

import { ChartStatus, useChartsContext } from "contexts";
import { RootState } from "store/rootReducer";

import { useChartEntities } from "hooks/charts";

import { XAxisContainer } from "./XAxisContainer";
import { YAxisContainer } from "./YAxisContainer";

export function AxisContainer() {
  const widgetHoverMap = useSelector((state: RootState) => state.app.widgetHoverMap);
  const { id, status } = useChartsContext();
  const isHover = widgetHoverMap?.[id] ?? false;
  const { screenshot, dataTable } = useChartEntities(id);

  const showAxis =
    !screenshot?.active && !dataTable?.active && isHover && status === ChartStatus.Idle;

  return (
    showAxis && (
      <>
        <XAxisContainer />
        <YAxisContainer />
      </>
    )
  );
}
