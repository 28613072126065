import { Switch } from "antd";
import {
  ProductKind,
  ProductTypeT,
  SalesProduct,
  ThroughputProduct,
  getProductLabel
} from "constants/chart.constants";
import styled from "styled-components";
import { getProductTooltip } from "utils";
import { getProductIcon } from "utils/chart/getProductIcon";
import { getShortenedProductName } from "utils/chart/productNameHelpers";

import useBetaFeatures from "hooks/useBetaFeatures";

import { Tooltip } from "components/base";

import ProductHeader from "../../ProductHeader";
import { ProductButton } from "../ProductSelector.styles";

export const LiquidYieldsProductHeader = ({
  selectedProductGroup,
  yieldsType,
  rawLabel,
  salesLabel,
  onChangeType,
  setProduct,
  product,
  inletRateLabel,
  outletRateLabel,
  entityKind
}) => {
  const { hasFeature } = useBetaFeatures();

  if (
    selectedProductGroup !== ProductKind.Sales &&
    selectedProductGroup !== ProductKind.Throughput
  ) {
    return null;
  }
  const index = yieldsType === rawLabel ? 2 : 3;
  const header: ProductTypeT =
    selectedProductGroup === ProductKind["Throughput"]
      ? ThroughputProduct[index]
      : SalesProduct[index];

  function removeCO2Strings(items) {
    return items.filter((item) => !item.includes("CO₂"));
  }
  if (hasFeature("Facility Volumes")) {
    header.items = removeCO2Strings(header.items);
  }
  return (
    <>
      <ButtonContainer>
        <Switch
          unCheckedChildren={rawLabel}
          checkedChildren={salesLabel}
          checked={yieldsType === salesLabel}
          onChange={onChangeType}
          size="small"></Switch>
      </ButtonContainer>

      <ProductHeader key={header.name} header={header.name}>
        {header.items.map((prod) => (
          <Tooltip key={prod} title={getProductTooltip(prod, selectedProductGroup)}>
            <ProductButton
              isDisabled={
                // chartType === ALL_CHART_TYPES.MaterialBalanceTime.label &&
                // !MaterialBalanceTimeChartProducts.includes(prod)
                false
              }
              key={prod}
              ellipses={false}
              onClick={() => {
                // TODO chart: reset axisMinMax and reference lines
                setProduct(prod);
              }}
              appearance=""
              className={`${prod === product ? "isSelected" : null}`}>
              {getProductIcon(prod)}
              <span>
                {getShortenedProductName(
                  selectedProductGroup,
                  getProductLabel(prod, entityKind),
                  rawLabel,
                  salesLabel,
                  inletRateLabel,
                  outletRateLabel
                )}
              </span>
            </ProductButton>
          </Tooltip>
        ))}
      </ProductHeader>
    </>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  transform: translate(-10px, 30px);
  margin-top: -16px;
  position: relative;
  z-index: 1;

  .ant-switch {
    background-color: var(--color-primary);
  }
`;
