import { TooltipOption } from "echarts/types/dist/shared";
import { EvaChartStates } from "types/factory";

import { formatMultiSeriesTooltip, formatSingleSeriesTooltip } from "./utils";

export function translateTotalRateCumResponseTooltipOptions(
  states: EvaChartStates
): TooltipOption {
  const { layout, hoverLegendItem } = states;

  const yAxisTitle = layout?.yAxis?.title || "";

  return {
    trigger: "axis",
    confine: true,
    enterable: true,
    extraCssText: "border-style:none;",
    className: "chart-tooltip-container",
    showDelay: 500,
    hideDelay: 0,
    show: true,
    axisPointer: {
      label: {
        precision: 2,
        show: true
      },
      type: "cross"
    },

    position: function (point, _, dom) {
      const xPos = point[0];
      const yPos =
        point[1] - (dom as HTMLDivElement).offsetHeight > 48
          ? point[1] - (dom as HTMLDivElement).offsetHeight
          : 48;
      return [xPos, yPos]; //position top right of mouse cursor
    },
    formatter: (items) => {
      if (
        (hoverLegendItem && items) ||
        (items && Array.isArray(items) && items.length === 1)
      ) {
        const series = Array.isArray(items)
          ? items.filter((item) => item.seriesName.startsWith(hoverLegendItem))[0]
          : items;

        if (series) {
          return formatSingleSeriesTooltip(series, yAxisTitle);
        }
      }

      if (items && items.length > 0) {
        return formatMultiSeriesTooltip(items, yAxisTitle);
      }

      return "";
    }
  };
}
