import { useCallback } from "react";
import { useSelector } from "react-redux";

import { EvaChart, EvaStatisticsKind } from "constants/charts.enums";
import { useChartsContext } from "contexts";
import { RootState } from "store/rootReducer";
import { removeCount } from "utils/removeCountFromTitle";

import { useChartDependencies, useChartEntities } from "hooks/charts";

import { LegendItemModel } from "models";
import { ChartSeries } from "models/model";

import { createLegendItem } from "components/chart/utils";
import { useScreenshotContext } from "components/screenshot/hooks";
import { applyAbbreviations } from "components/screenshot/utils";
import { useUserSettings } from "components/user/hooks";

import useStatisticsLegendItem from "./useStatisticsLegendItem";
import useTypeWellLegendItems from "./useTypeWellLegendItems";

const getFilteredItems = (series: ChartSeries[]): ChartSeries[] => {
  return series.filter(
    (series) =>
      !["Average", "P10", "P50", "P90"].includes(series.groupTitle) &&
      !series.isForecast &&
      !series.isSecondaryYAxis &&
      !series.label?.includes(" Fit - ")
  );
};

/**
 * A simple hook to get the legend items for the chart
 *
 * @returns list of legend items
 */
const useChartLegendItems = (): LegendItemModel[] => {
  // custom hooks
  const avgLegendItem = useStatisticsLegendItem(EvaStatisticsKind.Average);
  const p90LegendItem = useStatisticsLegendItem(EvaStatisticsKind.P90);
  const p50LegendItem = useStatisticsLegendItem(EvaStatisticsKind.P50);
  const p10LegendItem = useStatisticsLegendItem(EvaStatisticsKind.P10);
  // TODO chart
  // const slopeLegendItem = useSlopeLegendItem();

  const typeWellLegendItems = useTypeWellLegendItems();
  const { userAbbreviations } = useUserSettings();
  const screenshotState = useScreenshotContext();

  // state from store
  const placeholderBinsEnabled = useSelector(
    (state: RootState) => state.groupBy.placeholderBinsEnabled
  );

  const { id: chartId, entityKind } = useChartsContext();
  const { chartFocus, chartType, screenshot, apiResponse } = useChartEntities(chartId);
  const { showGroupsNotInFilter, txnId } = useChartDependencies(entityKind);

  const abbreviations = userAbbreviations?.abbreviations ?? [];

  // derived state
  const { id, legend } =
    entityKind === "Well"
      ? { id: txnId.id, legend: txnId.legend }
      : {
          id: txnId.facilityId,
          legend: txnId.facilityLegend
        };
  const { legendItems } = legend;
  const hasLegendItems = Boolean(id) && Boolean(legendItems.length);

  const createAndFormatLegendItem = useCallback(
    (title: string, inFilter: boolean, color: string): LegendItemModel => {
      const formattedTitle =
        screenshot && screenshotState?.settings?.applyAbbreviations
          ? applyAbbreviations(title, abbreviations)
          : title;
      return createLegendItem(formattedTitle, inFilter, color);
    },
    [screenshot, screenshotState?.settings?.applyAbbreviations, abbreviations]
  );

  if (!hasLegendItems) return [...avgLegendItem, ...typeWellLegendItems];
  // const chart = getChart(chartType as ChartTypeLabels);
  if (chartFocus?.active) {
    if (!apiResponse?.series?.length) {
      return [...typeWellLegendItems];
    }
    switch (chartType) {
      case EvaChart.Pie: {
        const itemColors = apiResponse.series[0].style.itemColors;
        const filteredLegendItems = apiResponse.series[0].y.map((title, index) => {
          return createAndFormatLegendItem(title, true, itemColors[index]);
        });
        return [...filteredLegendItems, ...typeWellLegendItems];
      }
      case EvaChart.Mosaic: {
        const filteredLegendItems = apiResponse.series
          .filter(
            (series) =>
              series.groupTitle != "Average" &&
              !series.isForecast &&
              !series.isSecondaryYAxis
          )
          .map((series) => {
            const title = series.groupTitle ? series.groupTitle : series.label;
            return {
              title: removeCount(title),
              hexColor: series.style.hexColor
            };
          })
          .filter(
            (value, index, array) =>
              array.map((a) => a.title).indexOf(value.title) === index
          )
          .map((series) => {
            return createAndFormatLegendItem(series.title, true, series.hexColor);
          });
        return [...filteredLegendItems, ...typeWellLegendItems];
      }
      case EvaChart.BoxPlot: {
        const filteredLegendItems = getFilteredItems(apiResponse.series)
          .filter(
            (value, index, array) =>
              array.map((a) => a.label).indexOf(value.label) === index
          )
          .map((series) => {
            return createAndFormatLegendItem(series.label, true, series.style.hexColor);
          });
        return [...filteredLegendItems, ...typeWellLegendItems];
      }
      case EvaChart.TotalRateDate: {
        // getCustomLegendItems needs to be called for the total rate date chart because
        // that function adds the well count series to the legend
        break;
      }
      default: {
        const uniqueLegendItemSeries = getFilteredItems(apiResponse.series).reduce(
          (accumulator, currentSeries) => {
            const isLegendItemDuplicate = accumulator.some(
              (item) =>
                (item.label && item.label === currentSeries.label) ||
                (item.groupTitle && item.groupTitle === currentSeries.groupTitle)
            );

            if (!isLegendItemDuplicate) {
              accumulator.push(currentSeries);
            }

            return accumulator;
          },
          []
        );

        const formattedLegendItems = uniqueLegendItemSeries.map((series) => {
          const title = series.groupTitle || series.label;
          return createAndFormatLegendItem(title, true, series.style.hexColor);
        });

        return [
          ...p10LegendItem,
          ...avgLegendItem,
          ...p50LegendItem,
          ...p90LegendItem,
          ...formattedLegendItems,
          ...typeWellLegendItems
          // ...slopeLegendItem
        ];
      }
    }
  }

  //custom legendItems allows the chart to override the legend that is returned from the txnId
  const customLegendItems = [];
  // TODO chart: handle for charts with custom legend items (Total rate date, Well Contribution)
  // chart?.getCustomLegendItems(response?.series, settings, entityKind) ?? [];

  // legend items in filter
  const filteredLegendItems = (
    customLegendItems.length > 0 ? customLegendItems : legendItems
  )
    .filter(
      (item) =>
        item.inFilter &&
        ((placeholderBinsEnabled ? showGroupsNotInFilter : false) ||
          item.count > 0 ||
          chartType === EvaChart.WellContribution) // Well Contribution chart will always have an undefined count for the legend items
    )
    .map((item) => {
      const title = item.groupTitle ? item.groupTitle : item.title;

      return createAndFormatLegendItem(title, item.inFilter, item.color);
    });

  return [
    ...p10LegendItem,
    ...avgLegendItem,
    ...p50LegendItem,
    ...p90LegendItem,
    // ...slopeLegendItem,
    ...filteredLegendItems,
    ...typeWellLegendItems
  ];
};

export default useChartLegendItems;
