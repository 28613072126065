import { BinSize, mBinSize } from "./binSize";
import { EntityKind } from "./entityKind";
import { QuantileType } from "./quantileType";

export type DataType = "Text" | "Number" | "Integer" | "Date";

export enum DataEnum {
  Text = "Text",
  Number = "Number",
  Integer = "Integer",
  Date = "Date",
  None = "None",
  Reset = "Reset"
}

export type BinType = "BinSize" | "Quantile" | "Dynamic";

export type MaxBinsSortOrder = "WellCount" | "Production";

export interface QuantileState {
  quantileType: QuantileType;
  numQuantiles: number;
}

export interface IGroupBy {
  bin: mBinSize;
  canBin: boolean;
  categoryId: number;
  dataType: DataType;
  display: string;
  entityKind: EntityKind;
  groupByField: string;
  property: string;
  title: string;
  tooltip: string;
  pdenSource: string;

  defaultUnit?: string;
  forecastFolder?: string;
  fullContextTitleWithNormalization?: string;
  group?: string;
  isForecastToggleOn?: boolean;
  partialContextTitle?: string;
  product?: string;
  subgroup?: string;
}

export interface GroupBy extends IGroupBy {
  bin: BinSize;
}

export interface IGroupByMap {
  property: string;
  title: string;
}

export interface ICategoryFieldModel {
  defaultUnit?: string;
  id: string;
  normalizeByDefault?: number;
  property: string;
  title: string;
  tooltip?: string;
  pythonScript?: string;
  subgroup: string;
  group: string;
}

export interface IGroupByListColumn {
  canBin: boolean;
  canNormalize: boolean;
  categoryId?: number;
  dataType: DataType;
  defaultUnit?: string;
  forecastFolder?: string;
  group: string;
  id: string;
  isForecastToggleOn?: boolean;
  isDisabled?: boolean;
  normalizeByDefault?: number;
  product: string;
  pythonScript?: string;
  property: string;
  title: string;
  source?: string;
  subgroup: string;
  subgroupOrder?: number;
  tooltip?: string;
  withinSubgroupOrder?: number;
  fieldType?: string;
  metadata?: {
    [key: string]: string;
  };
}

export interface IGroupByListItem {
  columns: IGroupByListColumn[];
  hexColor: string;
  id?: string;
  name: string;
  section?: string;
  property?: string;
  sortOrder: number;
  metadataFilters?: IGroupByMetadataFilter[];
}

export interface IGroupByMetadataFilter {
  key: string;
  name: string;
  options: string[];
}

export interface IBin {
  LessThan?: number;
  BinSize: number;
  GreaterThan?: number;
  MinSize?: number;
  MaxBins?: number;
  MaxBinsSortOrder?: MaxBinsSortOrder;
}

export interface IGroupByState {
  categoryList: IGroupByListItem[];
  facilityFields: IGroupByListItem[];
  cumPlusForecast: boolean;
  globalGroupBy: IGroupBy;
  globalFacilityFocus: IGroupBy;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hoverDataPoint: any[];
  hoverLegendItem: "";
  hoverLegendGroup: "";
  hoverLegendId: "";
  presetList: IGroupBy[];
  recentWellFocus: IGroupBy[];
  recentFacilityFocus: IGroupBy[];
  selectedGroups: string[];
  selectedFacilityGroups: string[];
  hasCheckedBins: boolean;
  hasCheckedBinsFacility: boolean;
  placeholderBinsEnabled: boolean;
}
