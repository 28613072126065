import { useEffect, useRef } from "react";

import { EvaChart } from "constants/charts.enums";
import { BoundingBox, EvaChartFeatures } from "types";
import { LockState } from "types/charts/locks";

import { EntityKind } from "models/entityKind";

import { useChartEntities } from "../useChartEntities";
import { extractActiveAndProperties } from "./utils";

interface ChartSettings {
  chartType: EvaChart;
  product: string;
  features: Partial<EvaChartFeatures>;
  locks: Record<BoundingBox, LockState>;
}

export const useChartSessions = (id: string, entityKind: EntityKind) => {
  const { chartType, product, features, locks } = useChartEntities(id);

  const prevChartSettingsRef = useRef<ChartSettings | null>(null);

  useEffect(() => {
    const newChartSettings: ChartSettings = { chartType, product, features, locks };

    if (
      JSON.stringify(newChartSettings) !== JSON.stringify(prevChartSettingsRef.current)
    ) {
      const extracted = extractActiveAndProperties(newChartSettings.features);
      sessionStorage.setItem(
        `${entityKind === EntityKind.Well ? "chart" : "midstream-chart"}::${id}`,
        JSON.stringify({ chartType, product, features: extracted, locks })
      );
      prevChartSettingsRef.current = newChartSettings;
    }
  }, [chartType, product, features, id, locks]);
};
