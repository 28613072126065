import { TreeProps } from "antd";

import {
  useProjectContext,
  useProjectDispatch
} from "components/project/projects/context";

import { OnExpandParamsT } from "../../components/layer-managers/project-layer-tree/MemoizedTree";
import { setLayerTreeExpandedKeysToSession } from "../../session/layer-tree-expanded-keys/setLayerTreeExpandedKeysToSession";
import { getKeysExcludingDescendantFolders } from "./util/getKeysExcludingDescendantFolders";

export function useUpdateLayerTreeExpandedKeys() {
  const { selectedProjectId, selectedViewId } = useProjectContext();
  const projectDispatch = useProjectDispatch();

  const updateLayerTreeExpandedKeys: TreeProps["onExpand"] = (
    expandedKeys: OnExpandParamsT["expandedKeys"],
    info: OnExpandParamsT["info"]
  ) => {
    const { node, expanded: isExpanded } = info;

    const nextExpandedKeys = getKeysExcludingDescendantFolders(
      expandedKeys as string[],
      node,
      isExpanded
    );

    projectDispatch({
      payload: {
        layerTreeExpandedKeys: nextExpandedKeys
      }
    });

    // We're only handling cache right now when a view is not selected,
    // cache has not been implemented for views/type wells/forecasts/filters yet.
    if (!selectedViewId) {
      setLayerTreeExpandedKeysToSession({
        expandedKeys: nextExpandedKeys,
        projectId: selectedProjectId
      });
    }
  };

  return updateLayerTreeExpandedKeys;
}
