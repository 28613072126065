import { useEffect } from "react";

import { EvaChart } from "constants/charts.enums";
import { useChartsContext } from "contexts/ChartContext";
import { translateLegendsToParams } from "entities/charts";
import { ChartActionType, SeriesType } from "types/echarts";

import { useChartDependencies } from "../useChartDependencies";
import { useChartEntities } from "../useChartEntities";
import {
  isCustomChart,
  resetHighlights,
  resetHighlightsByChartType,
  updatePieSeries,
  updateTargetDataEmphasis,
  updateTargetSeriesEmphasis
} from "./utils";

export function useChartHighlights(chartInstanceRef: React.RefObject<echarts.ECharts>) {
  const { id, entityKind } = useChartsContext();
  const { chartType, forecast, apiResponse, sum } = useChartEntities(id);
  const spaghetti = sum?.active;
  const isForecastActive = forecast?.active;
  const {
    hoverLegendItem,
    hoverLegendGroup,
    hoverLegendId,
    attentionWells,
    selectedGroups
  } = useChartDependencies(entityKind);

  useEffect(() => {
    if (!chartInstanceRef.current || isCustomChart(chartType ?? EvaChart.None)) return;

    if (hoverLegendItem || selectedGroups.length > 0) {
      const chartOptions = chartInstanceRef.current.getOption();
      const seriesArray = Array.isArray(chartOptions?.series) ? chartOptions.series : [];
      if (seriesArray.length === 0) return;
      const selectionIndex = translateLegendsToParams({
        type: chartType ?? EvaChart.None,
        states: {
          hoverLegendItem,
          hoverLegendGroup,
          hoverLegendId,
          attentionWells,
          selectedGroups,
          ...apiResponse
        },
        forecast: isForecastActive,
        series: seriesArray
      });
      if (!selectionIndex) return;

      const { targetIndex, seriesIndex, seriesType } = selectionIndex;
      if (!hoverLegendItem) {
        resetHighlightsByChartType(chartInstanceRef.current, chartOptions.series);
        seriesArray.forEach((_, seriesIndex) => {
          chartInstanceRef.current.dispatchAction({
            type: ChartActionType.HideTip,
            seriesIndex
          });
        });
      }

      if (seriesType === SeriesType.Line && spaghetti && targetIndex) {
        updateTargetSeriesEmphasis(chartInstanceRef.current, targetIndex);
      }

      if (seriesType === SeriesType.Pie) {
        updatePieSeries(
          seriesArray,
          hoverLegendItem,
          hoverLegendGroup,
          selectedGroups,
          chartInstanceRef
        );
      }

      const highlightPoint =
        seriesType === SeriesType.Scatter && hoverLegendGroup?.length > 0;

      chartInstanceRef.current.dispatchAction({
        type: ChartActionType.Highlight,
        seriesIndex: seriesIndex,
        dataIndex: highlightPoint ? targetIndex : undefined
      });

      if (seriesType === SeriesType.Scatter) {
        updateTargetDataEmphasis(chartInstanceRef.current, seriesIndex, targetIndex);
        // Used for popping up tooltip when hovering well on map, tooltip can be cut off by the chart boudns so needs
        // further tooltip position modification to show properly, documented in EVA 4404
        //   chartInstanceRef.current.dispatchAction({
        //     type: ChartActionType.ShowTip,
        //     seriesIndex: seriesIndex,
        //     dataIndex: targetIndex
        //   });
      }
    } else {
      resetHighlights(chartInstanceRef.current);
    }
  }, [
    hoverLegendItem,
    hoverLegendGroup,
    hoverLegendId,
    attentionWells,
    selectedGroups,
    chartType,
    apiResponse,
    forecast,
    spaghetti,
    chartInstanceRef.current
  ]);
}
