import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import { useGlobalProductionSettings } from "hooks";
import { useChartEntities } from "hooks/charts";

import { useChartsContext } from "../../../contexts";
import { GlobalProductionSettings } from "../../../hooks/useGlobalProductionSettings";
import { INormalizeBySetting } from "../../../models";
import { updateChartByPath } from "../../../store/features";

export interface GlobalSettings {
  usingGlobalFocus: boolean;
  usingGlobalTimestep: boolean;
  usingGlobalProduction: boolean;
  usingGlobalNormalizeBy: boolean;
  usingAllGlobalSettings: boolean;
}

const useChartVsGlobalSettings = (): GlobalSettings => {
  const dispatch = useDispatch();
  const { id, entityKind } = useChartsContext();
  const globalSettings = useGlobalProductionSettings(entityKind);
  const {
    normalizeBy: normalizeByState,
    chartFocus: chartFocusState,
    timeStep: timeStepState,
    source: sourceState,
    chartLegend: legendState
  } = useChartEntities(id);
  const normalizeBySettings = normalizeByState?.properties;
  const isChartFocusActive = chartFocusState?.active;
  const isLegendActive = legendState?.active;

  useEffect(() => {
    openLegendConditionally(!isLegendActive && isChartFocusActive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChartFocusActive]);

  const isUsingGlobalNormalizeBy = (
    settings: INormalizeBySetting,
    globalSettings: GlobalProductionSettings
  ): boolean => {
    // If both are disabled, they're equivalent
    if (!settings?.useNormalizeBy && !globalSettings?.normalizeBy) {
      return true;
    }

    // If both are enabled, compare only the relevant properties
    if (settings?.useNormalizeBy && !!globalSettings?.normalizeBy) {
      const localNormalizeSettings = {
        field: settings.field,
        per: settings.per,
        unit: settings.unit,
        useMultilinearNormalization: settings.useMultilinearNormalization,
        threshold: settings.threshold,
        lowerScalar: settings.lowerScalar,
        higherScalar: settings.higherScalar
      };

      const globalNormalizeSettings = {
        field: globalSettings.normalizeBy.field,
        per: globalSettings.normalizeBy.per,
        unit: globalSettings.normalizeBy.unit,
        useMultilinearNormalization:
          globalSettings.normalizeBy.useMultilinearNormalization,
        threshold: globalSettings.normalizeBy.threshold,
        lowerScalar: globalSettings.normalizeBy.lowerScalar,
        higherScalar: globalSettings.normalizeBy.higherScalar
      };

      return (
        JSON.stringify(localNormalizeSettings) === JSON.stringify(globalNormalizeSettings)
      );
    }
    return false;
  };

  const openLegendConditionally = (condition: boolean) => {
    if (condition) {
      dispatch(
        updateChartByPath({
          id,
          path: `features.legend.active`,
          value: !isLegendActive
        })
      );
    }
  };

  return useMemo(() => {
    const usingGlobalFocus: boolean = !chartFocusState?.active;
    const usingGlobalTimestep: boolean =
      globalSettings?.timeStep === timeStepState?.properties.value;
    const usingGlobalProduction: boolean =
      globalSettings?.source === sourceState?.properties.value;
    const usingGlobalNormalizeBy: boolean = isUsingGlobalNormalizeBy(
      normalizeBySettings,
      globalSettings
    );

    // TODO chart - multi product chart settings
    // const usingMultiProduct: boolean =
    //   settings?.otherProducts?.length > 0 && settings.chartType === "Total Rate Date";

    return {
      usingGlobalFocus,
      usingGlobalTimestep,
      usingGlobalProduction,
      usingGlobalNormalizeBy,
      usingAllGlobalSettings:
        usingGlobalFocus &&
        usingGlobalTimestep &&
        usingGlobalProduction &&
        usingGlobalNormalizeBy
      // && !usingMultiProduct
    };
  }, [
    JSON.stringify(globalSettings?.normalizeBy),
    globalSettings?.source,
    globalSettings?.timeStep,

    JSON.stringify(normalizeBySettings),
    // TODO chart: sort out types so it doesnt complain about properties
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    normalizeBySettings?.useNormalizeBy,
    chartFocusState?.active,
    timeStepState.properties.value,
    sourceState.properties.value
    // settings?.otherProducts // TODO chart - multi product chart settings
  ]);
};
export default useChartVsGlobalSettings;
