import { EvaChartStates } from "types/factory";

export function crossPlotLocks(states: Partial<EvaChartStates>) {
  const { locks } = states;
  const lockedYMin = locks?.yMin?.value;
  const lockedYMax = locks?.yMax?.value;
  const lockedXMax = locks?.xMax?.value;
  const lockedXMin = locks?.xMin?.value;

  return {
    lockedYMin,
    lockedYMax,
    lockedXMax,
    lockedXMin
  };
}
