import { ScatterSeriesOption } from "echarts";
import { createStateWithDefaults } from "entities/utils";
import { SeriesType } from "types/echarts";

export const DEFAULT_SCATTER_SERIES_OPTIONS: Partial<ScatterSeriesOption> = {
  type: SeriesType.Scatter,
  data: [],
  id: "",
  name: "",
  z: 2,
  animation: false,
  emphasis: {
    focus: "self",
    itemStyle: {
      opacity: 1,
      borderRadius: 2
    }
  },
  blur: {
    itemStyle: {
      opacity: 0.2
    }
  },
  large: true,
  largeThreshold: 1000,
  /* A large dataset may require that we stop hovered elements from being rendered in a seperate layer,
   * to avoid visual issues of other elements rendering (when hovering).
   * It seems that we do need to repaint the canvas when hovering, so we set the threshold to Infinity
   * https://echarts.apache.org/en/option.html#hoverLayerThreshold
   */
  hoverLayerThreshold: Infinity
};

export const generateScatterSeriesOptions = createStateWithDefaults(
  DEFAULT_SCATTER_SERIES_OPTIONS
);
