import { DEFAULT_AXIS_FONT_SIZE } from "constants/chart.constants";
import { THEME_FONT_FAMILY } from "constants/style.constants";
import { EChartsOption } from "echarts";
import { createStateWithDefaults } from "entities/utils";

import { DEFAULT_TITLE_OPTIONS } from "./title";
import { DEFAULT_X_AXIS_OPTIONS } from "./xAxis";
import { DEFAULT_Y_AXIS_OPTIONS } from "./yAxis";

export const DEFAULT_CHART_OPTIONS: Partial<EChartsOption> = {
  backgroundColor: "",
  refresh: true,
  toolbox: { show: false },
  brush: {
    brushType: "polygon",
    throttleType: "debounce",
    throttleDelay: 100,
    xAxisIndex: 0,
    toolbox: ["rect", "polygon", "lineX", "lineY", "keep", "clear"]
  },
  title: DEFAULT_TITLE_OPTIONS,
  textStyle: {
    fontFamily: THEME_FONT_FAMILY,
    fontSize: DEFAULT_AXIS_FONT_SIZE,
    color: "#041C2C"
  },
  yAxis: DEFAULT_Y_AXIS_OPTIONS,
  xAxis: DEFAULT_X_AXIS_OPTIONS,
  graphic: [],
  animation: false,
  grid: {
    left: 70,
    bottom: 50,
    top: 50,
    right: 20
  },
  uwiList: [],
  uwiCoordinates: {},
  tooltip: {
    trigger: "axis",
    confine: true,
    enterable: true,
    className: "chart-tooltip-container",
    extraCssText: "border-style:none;",

    axisPointer: {
      type: "cross",
      label: {
        show: true
      }
    }
  },
  dataZoom: [
    {
      type: "slider",
      id: "xAxis-slider",
      xAxisIndex: 0,
      filterMode: "none",
      backgroundColor: "rgba(0,0,0,0)",
      fillerColor: "rgba(0,0,0,0)",
      handleSize: "14px",
      handleIcon: "path://M0 0L3 0L3 14L0 14L0 0Z",
      handleStyle: {
        opacity: 1,
        color: "#cdd2d5"
      },
      borderColor: "rgba(0,0,0,0)",
      showDataShadow: false,
      showDetail: false,
      moveHandleSize: 0,
      moveHandleStyle: {
        borderWidth: 0,
        opacity: 1
      },
      zlevel: -1,
      bottom: 32,
      brushSelect: false,
      height: "21px",
      show: true
    },
    {
      type: "slider",
      id: "yAxis-slider",
      yAxisIndex: 0,
      filterMode: "none",
      backgroundColor: "rgba(0,0,0,0)",
      fillerColor: "rgba(0,0,0,0)",
      handleSize: "14px",
      handleIcon: "path://M0 0L3 0L3 14L0 14L0 0Z",
      handleStyle: {
        opacity: 1,
        color: "#cdd2d5"
      },
      borderColor: "rgba(0,0,0,0)",
      showDataShadow: false,
      showDetail: false,
      moveHandleSize: 0,
      moveHandleStyle: {
        borderWidth: 0,
        opacity: 1
      },
      left: 50,
      zlevel: -1,
      brushSelect: false
    },
    {
      type: "inside",
      orient: "vertical",
      filterMode: "none"
    },
    {
      type: "inside",
      filterMode: "none",
      orient: "horizontal"
    }
  ],
  series: [],
  // custom options
  chartType: "",
  extends: {
    xMin: undefined,
    xMax: undefined,
    yMin: undefined,
    yMax: undefined
  }
};

export const generateChartOptions = createStateWithDefaults(DEFAULT_CHART_OPTIONS);
