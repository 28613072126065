import { CustomSeriesOption, CustomSeriesRenderItem } from "echarts";
import { createStateWithDefaults } from "entities/utils";
import { SeriesType } from "types/echarts";

export const DEFAULT_MOSAIC_SERIES_OPTIONS: Partial<CustomSeriesOption> = {
  type: SeriesType.Custom,
  data: [],
  renderItem: renderMosaicItem as CustomSeriesRenderItem,
  z: 2
};

export const generateMosaicSeriesOptions = createStateWithDefaults(
  DEFAULT_MOSAIC_SERIES_OPTIONS
);

export function renderMosaicItem(params, api) {
  const start = api.coord([api.value(1), api.value(4)]);
  const end = api.coord([api.value(2), api.value(3)]);
  const width = end[0] - start[0];
  const height = end[1] - start[1];
  const rectShape = clipRectByRect(
    {
      x: start[0],
      y: end[1] - height,
      width: width,
      height: height
    },
    {
      x: params.coordSys.x,
      y: params.coordSys.y,
      width: params.coordSys.width,
      height: params.coordSys.height
    }
  );
  return (
    rectShape && {
      type: "rect",
      shape: rectShape,
      style: api.style()
    }
  );
}

function clipRectByRect(targetRect, rect) {
  const x = Math.max(targetRect.x, rect.x);
  const x2 = Math.min(targetRect.x + targetRect.width, rect.x + rect.width);
  const y = Math.max(targetRect.y, rect.y);
  const y2 = Math.min(targetRect.y + targetRect.height, rect.y + rect.height);
  if (x2 >= x && y2 >= y) {
    return {
      x,
      y,
      width: x2 - x,
      height: y2 - y
    };
  }
}
