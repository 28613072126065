import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import LockIcon from "@material-ui/icons/Lock";
import classnames from "classnames";
import { useChartsContext } from "contexts";
import { UNDEFINED_LOCK_OPTION } from "entities/charts";
import { selectChartLocks, selectChartOptions, updateChartByPath } from "store/features";
import { RootState } from "store/rootReducer";
import { LockState } from "types/charts/locks";

import { DataEnum } from "models";

import { AxisInput } from "./AxisInput";
import "./AxisLocker.scss";
import { LockIconWrapper } from "./AxisLocker.styles";

export function XMaxLocker() {
  const { id } = useChartsContext();
  const dispatch = useDispatch();
  const optionString = useSelector((state: RootState) => selectChartOptions(state, id));
  const locks = useSelector((state: RootState) => selectChartLocks(state, id));
  const [showLock, setShowLock] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [xMax, setXMax] = useState<LockState>(undefined);
  const [showInput, setShowInput] = useState(false);

  useEffect(() => {
    if (optionString.length === 0) return;
    const options = JSON.parse(optionString);
    const lockOptions = options?.xAxis?.lock ?? UNDEFINED_LOCK_OPTION;
    setShowLock(lockOptions.show);
    setXMax(lockOptions.max);
    const override = options?.xAxis?.lock?.max?.override ?? false;
    if (override && locks.xMax.type !== DataEnum.Reset) {
      dispatch(
        updateChartByPath({
          id,
          path: `locks.xMax`,
          value: { value: options.xAxis.max, type: lockOptions.max.type }
        })
      );
    }
  }, [optionString]);

  useEffect(() => {
    if (locks.xMax) {
      setIsLocked(locks.xMax.value !== undefined);
    }
  }, [locks]);

  const handleLockClick = () => {
    if (isLocked) {
      dispatch(
        updateChartByPath({
          id,
          path: `locks.xMax`,
          value: { value: undefined, type: DataEnum.Reset }
        })
      );
    } else {
      setShowInput(!showInput);
    }
  };

  const axisInputClassnames = classnames({ xAxis: true });

  const lockClassnames = classnames("axis-lock", "flex", {
    isLocked,
    xAxis: true,
    max: true
  });

  return (
    <>
      {showInput ? (
        <AxisInput
          axisInput={xMax}
          dataRange="xMax"
          setShowInput={setShowInput}
          className={classnames(axisInputClassnames, lockClassnames)}
        />
      ) : (
        showLock && (
          <LockIconWrapper className={lockClassnames} onClick={handleLockClick}>
            <LockIcon />
          </LockIconWrapper>
        )
      )}
    </>
  );
}
