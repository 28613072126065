import {
  ALL_CHART_TYPES,
  BLACK_HEX,
  DEFAULT_LINE_THICKNESS
} from "constants/chart.constants";
import { EvaSource, TimeStep } from "constants/charts.enums";
import { createMergeWithDefaults, createStateWithDefaults } from "entities/utils";
import { TotalRateCumChartRequestType, TotalRateCumChartSettings } from "types";

import { MaxBinsSortOrderEnum } from "models/binSize";
import { EntityKind } from "models/entityKind";

import { BASE_CHART_REQUEST } from "./defaults";

export const DEFAULT_TOTAL_RATE_CUM_CHART_SETTINGS: Partial<TotalRateCumChartSettings> = {
  product: "Oil",
  otherProducts: [],
  chartType: ALL_CHART_TYPES.TotalRateCum.id,
  showForecast: false,
  showWellCount: true,
  averageSettings: {
    color: BLACK_HEX,
    thickness: DEFAULT_LINE_THICKNESS
  },
  showAverage: false,
  p10Settings: {
    color: BLACK_HEX,
    thickness: DEFAULT_LINE_THICKNESS
  },
  showP10: false,
  p50Settings: {
    color: BLACK_HEX,
    thickness: DEFAULT_LINE_THICKNESS
  },
  showP50: false,
  p90Settings: {
    color: BLACK_HEX,
    thickness: DEFAULT_LINE_THICKNESS
  },
  showP90: false,
  pdenDataSourceSetting: {
    source: EvaSource.Public,
    timeStep: TimeStep.Month
  },
  forecastMode: "forecastTrump",
  useForecastStartDate: false,
  survivorBias: true,
  rateType: 0,
  showIndividualSeries: false,
  cutoff: 50,
  timeStep: TimeStep.Month,
  shutInMonths: 3,
  source: EvaSource.Public,
  normalizeBy: {
    useNormalizeBy: false,
    field: "Well_Design.HzLength_m",
    per: 100,
    displayName: "Hz Length (m)",
    unit: "m",
    useMultilinearNormalization: false,
    threshold: 0,
    lowerScalar: 1,
    higherScalar: 1
  },
  useWeightedRatioAverage: true,
  xVariable: {
    valueProperty: "Dates.Vintage_Year",
    displayTitle: "Vintage",
    canBin: true,
    dataType: "Integer",
    mappingName: "Dates.Vintage_Year",
    bin: null
  },
  yVariable: {
    valueProperty: "Well_Design.HzLength_m",
    displayTitle: "HzLengthEstimate",
    canBin: true,
    dataType: "Number",
    mappingName: "Well_Design.HzLength_m"
  },
  lockUnits: false,
  movingAverageDays: null
};

export const DEFAULT_TOTAL_RATE_CUM_REQUEST: Partial<TotalRateCumChartRequestType> = {
  ...BASE_CHART_REQUEST,
  FilterId: "",
  requestId: "",
  ReverseColor: false,
  ColorPalette: {
    id: "-6",
    name: "Portland",
    shared: false,
    colors: ["rgb(12,51,131)"],
    preferredColors: [],
    paletteType: 0,
    colorScale: [],
    specialColors: null,
    thickness: [2],
    reverse: false
  },
  GroupBy: {
    title: "Resource Play",
    property: "Header.ResourcePlay",
    groupByField: "Header.ResourcePlay",
    pdenSource: "Public",
    canBin: false,
    dataType: "Text", // TODO: check this
    categoryId: 1,
    tooltip: "",
    display: "",
    entityKind: EntityKind.Well,
    bin: {
      BinSize: 0,
      MinSize: null,
      MaxBins: null,
      MaxBinsSortOrder: MaxBinsSortOrderEnum.WellCount,
      GreaterThan: null,
      LessThan: null,
      Quantile: {
        quantileType: "NumberOfQuantile",
        numQuantiles: 4
      },
      BinType: "BinSize",
      UseDynamicBins: false
    }
  },
  sortBy: "AlphabeticalAsc",
  typeWells: [],
  normalizeTypeWell: true,
  userForecast: [],
  userForecastSettings: {
    forecastSourceFolder: null,
    excludeMcDanielForecasts: false,
    reserveCategory: null,
    excludeNoviForecasts: true,
    specialForecastFolders: ["McDaniel Research"]
  },
  forecastMode: "forecastTrump",
  useForecastStartDate: false,
  showGroupsNotInFilter: true,
  placeholderBinsEnabled: false,
  hasCheckedBins: true,
  syncClientPden: false,
  syncClientSchema: "",
  colorLockedItems: {},
  syncClientFcst: false,
  syncForecastRescat: "2111",
  normalizeBySetting: {
    field: "Well_Design.HzLength_m",
    display: "Hz Length (m)",
    per: 100,
    unit: "m",
    useMultilinearNormalization: false,
    threshold: 0,
    lowerScalar: 1,
    higherScalar: 1,
    useNormalizeBy: false
  },
  chartSetting: DEFAULT_TOTAL_RATE_CUM_CHART_SETTINGS as TotalRateCumChartSettings,
  axisMinMax: {
    xMin: null,
    xMax: null,
    yMin: null,
    yMax: null
  },
  isDefaultLockOn: true
};

export const generateTotalRateCumRequestBody = createMergeWithDefaults(
  DEFAULT_TOTAL_RATE_CUM_REQUEST
);

export const generateTotalRateCumChartSettings = createStateWithDefaults(
  DEFAULT_TOTAL_RATE_CUM_CHART_SETTINGS
);
