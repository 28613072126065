import { useEffect, useRef } from "react";

import { EvaChart } from "../../../constants/charts.enums";
import { ChartAxisType } from "../../../models/chart";

/**
 * Custom hook to determine if field selection is allowed for a specific chart axis based on chart type.
 *
 * This hook evaluates whether field selection should be enabled for X and Y axes
 * depending on the provided chart type. It maintains this state in a ref to persist
 * across renders while still responding to chart type changes.
 *
 * @param {ChartAxisType} axisType - The axis type to check ('x' or 'y')
 * @param {EvaChart} chartType - The type of chart being rendered
 * @returns {boolean} Whether field selection is allowed for the specified axis
 */
const useAxisFieldSelection = (axisType: ChartAxisType, chartType: EvaChart): boolean => {
  const allowFieldSelection = useRef({
    x: false,
    y: false
  });

  useEffect(() => {
    const allowXAxisSelection =
      chartType === EvaChart.CrossPlot ||
      chartType === EvaChart.CrossPlotMidstream ||
      // (chartType === EvaChart.BoxPlot && showScatter) || TODO chart: check showScatter for box plot
      chartType === EvaChart.Mosaic ||
      chartType === EvaChart.Probit ||
      // (chartType === EvaChart.StackedBar && !showHistogram) || TODO chart: check showHistogram for stacked bar
      chartType === EvaChart.TrendDate;
    const allowYAxisSelection =
      chartType === EvaChart.TrendDate ||
      chartType === EvaChart.CrossPlot ||
      chartType === EvaChart.CrossPlotMidstream ||
      chartType === EvaChart.StackedBar ||
      chartType === EvaChart.BoxPlot;

    allowFieldSelection.current = {
      x: allowXAxisSelection,
      y: allowYAxisSelection
    };
  }, [chartType]);
  //showScatter, showHistogram]); // TODO chart: add these to dependencies once implemented
  return allowFieldSelection.current[axisType];
};

export default useAxisFieldSelection;
