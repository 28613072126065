import { memo } from "react";
import { useDispatch } from "react-redux";

import { EvaSource } from "constants/charts.enums";
import { useChartsContext } from "contexts/ChartContext";
import { updateChartByPath } from "store/features";
import { capitalizeFirstLetter } from "utils";

import { useChartEntities } from "hooks/charts";

import { DataSourceIcon, DataSourceSelect } from "components/shared";

import ToolbarButton from "../../base/ToolbarButton";

const DataSourceToggle = () => {
  // derived state
  const dispatch = useDispatch();
  const { id } = useChartsContext();
  const { source: sourceState } = useChartEntities(id);
  const showButton = sourceState?.show;
  const source = sourceState?.properties?.value;
  const icon = DataSourceIcon[(source as EvaSource) || EvaSource.Public];
  const label = capitalizeFirstLetter(source || EvaSource.Public);
  function handleSourceChange(value: EvaSource) {
    dispatch(
      updateChartByPath({
        id,
        path: `features.source.properties.value`,
        value: value
      })
    );
  }

  if (!showButton) {
    return null;
  }

  return (
    <DataSourceSelect
      trigger={["hover"]}
      value={source as EvaSource}
      onChange={handleSourceChange}>
      <ToolbarButton
        data-testid="datasource-trigger"
        aria-label={source}
        active={false}
        icon={icon}
        overflowLabel={label}
      />
    </DataSourceSelect>
  );
};

export default memo(DataSourceToggle);
