import { LegendStates, MouseSelectionStates, SelectedParams } from "types";
import { SeriesType } from "types/echarts";

import {
  getAttentionWellIndex,
  getSeriesIndex,
  getSeriesIndexFromSelectedGroups,
  isValidChartLegendStates
} from "./utils";

export function mosaicHoverLegends({ params }: Partial<MouseSelectionStates>) {
  if (!params?.value?.[0] || typeof params.value[0] !== "string") {
    return {
      hoverLegendItem: "",
      hoverLegendGroup: "",
      hoverLegendId: ""
    };
  }

  let seriesName = params.value[0];
  const idx = seriesName.indexOf(" Forecast");
  if (idx > 0) {
    seriesName = seriesName.substring(0, idx).trim();
  }
  return {
    hoverLegendItem: seriesName,
    hoverLegendGroup: "",
    hoverLegendId: ""
  };
}

export function mosaicSelectionParams({
  states,
  series
}: Partial<LegendStates>): SelectedParams {
  const { hoverLegendGroup, hoverLegendItem, attentionWells, selectedGroups } = states;
  isValidChartLegendStates({ states, series });

  const isHoveringOnBins =
    hoverLegendItem && !hoverLegendGroup && attentionWells?.length === 0;
  const isHoveringOnChart =
    hoverLegendItem && hoverLegendGroup && hoverLegendGroup !== hoverLegendItem;
  const isHoveringOnMap =
    hoverLegendItem && !hoverLegendGroup && attentionWells?.length > 0;

  const onlySelectedGroup =
    !hoverLegendItem &&
    !hoverLegendGroup &&
    attentionWells?.length === 0 &&
    selectedGroups?.length > 0;

  const selectedGroupsIndices = getSeriesIndexFromSelectedGroups({ states, series });

  const seriesIndex = getSeriesIndex({ states, series });

  if (isHoveringOnBins) {
    return {
      seriesIndex: [...seriesIndex, ...selectedGroupsIndices],
      seriesType: SeriesType.Line,
      targetIndex: undefined
    };
  }

  if (isHoveringOnChart) {
    return {
      seriesIndex: [...seriesIndex, ...selectedGroupsIndices],
      targetIndex: seriesIndex,
      seriesType: SeriesType.Line
    };
  }

  if (isHoveringOnMap) {
    return {
      seriesIndex: [...seriesIndex, ...selectedGroupsIndices],
      targetIndex: getAttentionWellIndex({ states, series }),
      seriesType: SeriesType.Line
    };
  }

  if (onlySelectedGroup) {
    return {
      seriesIndex: [...selectedGroupsIndices],
      targetIndex: undefined,
      seriesType: SeriesType.Line
    };
  }
}
