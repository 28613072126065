import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useChartsContext } from "contexts/ChartContext";
import { translateSelectionToLegends } from "entities/charts";
import {
  updateHoverLegendGroup,
  updateHoverLegendId,
  updateHoverLegendItem
} from "store/features";
import { ChartActionType } from "types/echarts";

import { useChartEntities } from "../useChartEntities";

export function useChartMouseInteractions(
  chartInstanceRef: React.RefObject<echarts.ECharts>
) {
  const dispatch = useDispatch();
  const { id } = useChartsContext();
  const { chartType, apiResponse, forecast, lasso: lassoStates } = useChartEntities(id);
  const isForecastActive = forecast.active;
  const isLassoActive = lassoStates.active;

  useEffect(() => {
    if (!chartInstanceRef.current) return;

    const controller = new AbortController();
    const signal = controller.signal;

    const chartInstance = chartInstanceRef.current;

    function setHoverLegends(params) {
      if (isLassoActive) return;
      const chartOptions = chartInstanceRef.current.getOption();
      const seriesArray = Array.isArray(chartOptions?.series) ? chartOptions.series : [];
      if (seriesArray.length === 0) return;
      const { hoverLegendItem, hoverLegendGroup, hoverLegendId } =
        translateSelectionToLegends({
          type: chartType,
          params,
          series: seriesArray,
          response: apiResponse,
          forecast: isForecastActive
        });
      dispatch(updateHoverLegendItem(hoverLegendItem));
      dispatch(updateHoverLegendGroup(hoverLegendGroup));
      dispatch(updateHoverLegendId(hoverLegendId));
    }

    const handleHover = (params) => {
      setHoverLegends(params);
    };

    const handleMouseOver = (params) => {
      setHoverLegends(params);
    };

    const handleLineBlur = () => {
      dispatch(updateHoverLegendItem(""));
      dispatch(updateHoverLegendGroup(""));
      dispatch(updateHoverLegendId(""));
    };
    const handleMouseOut = () => {
      const chartOptions = chartInstanceRef.current.getOption();
      const seriesArray = Array.isArray(chartOptions?.series) ? chartOptions.series : [];
      if (seriesArray.length === 0) return;
      chartInstanceRef.current.dispatchAction({
        type: ChartActionType.HideTip,
        seriesIndex: [...Array(seriesArray.length).keys()]
      });
      dispatch(updateHoverLegendItem(""));
      dispatch(updateHoverLegendGroup(""));
      dispatch(updateHoverLegendId(""));
    };

    chartInstance.on(ChartActionType.MouseOver, handleMouseOver, { signal });
    chartInstance.on(ChartActionType.Hover, handleHover, { signal });
    chartInstance.on(ChartActionType.MouseOut, handleMouseOut, { signal });
    chartInstance.on(ChartActionType.LineBlur, handleLineBlur, { signal });

    return () => {
      if (chartInstance && !chartInstance.isDisposed()) {
        controller.abort();
      }
    };
  }, [
    chartInstanceRef.current,
    chartType,
    apiResponse,
    isForecastActive,
    isLassoActive,
    dispatch
  ]);
}
