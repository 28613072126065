import { memo } from "react";
import { useDispatch } from "react-redux";

import { useChartsContext } from "contexts/ChartContext";
import { updateChartByPath } from "store/features";

import { useChartEntities } from "hooks/charts";

import { ToolbarButton } from "components/base";
import ProducingRateIcon from "components/icons/ProducingRate";

const ProducingToggle = () => {
  const dispatch = useDispatch();
  const { id } = useChartsContext();
  const { isProducingRateType: isProducingRateTypeState } = useChartEntities(id);
  const isProducingRateType = isProducingRateTypeState?.active;
  const showButton = isProducingRateTypeState?.show;

  const toggle = (active: boolean) => {
    dispatch(
      updateChartByPath({
        id,
        path: `features.isProducingRateType.active`,
        value: active
      })
    );
  };

  if (!showButton) return null;

  return (
    <ToolbarButton
      icon={<ProducingRateIcon producing={isProducingRateType} />}
      active={isProducingRateType}
      overflowLabel="Rate Type"
      tooltipText="Toggle Producing Day Rate"
      onToggle={toggle}
    />
  );
};

export default memo(ProducingToggle);
