import { memo } from "react";
import { useDispatch } from "react-redux";

import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { useChartsContext } from "contexts/ChartContext";
import { updateChartByPath } from "store/features";

import { useChartEntities } from "hooks/charts";

import { IChartScreenshotSettings } from "models/chart";

import { useScreenshotDispatch } from "components/screenshot/hooks";

import ToolbarButton from "../../../base/ToolbarButton";

const ScreenshotToggle = () => {
  const dispatch = useDispatch();
  const screenshotDispatch = useScreenshotDispatch();
  const { id } = useChartsContext();
  const { screenshot, request, apiResponse } = useChartEntities(id);
  const showButton = screenshot?.show;
  // update screenshot state when legend is toggled
  const toggle = (value) => {
    const widget = value
      ? {
          widgetId: id,
          widgetComponentType: "chart"
        }
      : null;

    const widgetState = value
      ? {
          request,
          response: apiResponse
        }
      : null;
    const next = { ...screenshot, visible: value };
    screenshotDispatch({
      payload: {
        settings: next?.properties?.preset as IChartScreenshotSettings,
        widget,
        widgetState
      }
    });
    dispatch(
      updateChartByPath({
        id,
        path: `features.screenshot.active`,
        value: value
      })
    );
  };

  if (!showButton) {
    return null;
  }

  return (
    <ToolbarButton
      key="screenshot"
      data-testid="screenshot-toggle"
      active={screenshot?.active}
      icon={<CameraAltIcon fontSize="large" />}
      overflowLabel="Screenshot"
      tooltipText="Screenshot"
      onToggle={toggle}
    />
  );
};

export default memo(ScreenshotToggle);
