import { memo, useState } from "react";

import SettingsIcon from "@material-ui/icons/Settings";
import { Popover } from "antd";
import { EvaChart } from "constants/charts.enums";
import { useChartsContext } from "contexts/ChartContext";

import { useChartEntities } from "hooks/charts";

import ToolbarButton from "../../../base/ToolbarButton";
import SettingsOptions from "./SettingsOptions";

const SettingsToggle = () => {
  const { id } = useChartsContext();

  const [visible, setVisible] = useState(false);
  const { chartType, survivorBias, useWeightedRatioAverage, lockUnits } =
    useChartEntities(id);
  const isDisabled = chartType === EvaChart.WellContribution;

  return (
    <Popover
      arrowPointAtCenter
      content={<SettingsOptions />}
      placement="bottomRight"
      trigger="click"
      open={visible}
      onOpenChange={(v) => setVisible(v)}>
      <ToolbarButton
        icon={<SettingsIcon fontSize="large" />}
        isMenuButton={true}
        overflowLabel="Chart Options"
        tooltipText="Chart Options"
        disabled={isDisabled}
        active={useWeightedRatioAverage || survivorBias || lockUnits}
      />
    </Popover>
  );
};

export default memo(SettingsToggle);
