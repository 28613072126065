import { LegendStates, MouseSelectionStates, SelectedParams } from "types";
import { SeriesType } from "types/echarts";

import {
  getAttentionWellIndex,
  getSeriesIndex,
  getSeriesIndexFromSelectedGroups,
  isValidChartLegendStates
} from "./utils";

export function pieHoverLegends({ params, series }: Partial<MouseSelectionStates>) {
  const dataIndex = params.dataIndex;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const s = series as any;
  if (!s?.[0]?.data?.[dataIndex]?.name) {
    return {
      hoverLegendItem: "",
      hoverLegendGroup: "",
      hoverLegendId: ""
    };
  }
  const seriesName = s[0].data[dataIndex]?.name;
  return {
    hoverLegendItem: seriesName,
    hoverLegendGroup: "",
    hoverLegendId: ""
  };
}

export function pieSelectionParams({
  states,
  series
}: Partial<LegendStates>): SelectedParams {
  const { hoverLegendGroup, hoverLegendItem, attentionWells } = states;
  isValidChartLegendStates({ states, series });
  const isHoveringOnBins =
    hoverLegendItem && !hoverLegendGroup && attentionWells?.length === 0;
  const isHoveringOnMap =
    hoverLegendItem && !hoverLegendGroup && attentionWells?.length > 0;
  const selectedGroupsIndices = getSeriesIndexFromSelectedGroups({ states, series });
  const seriesIndex = getSeriesIndex({ states, series });
  if (isHoveringOnBins) {
    return {
      seriesIndex: [...seriesIndex, ...selectedGroupsIndices],
      seriesType: SeriesType.Pie,
      targetIndex: undefined
    };
  }
  if (isHoveringOnMap) {
    return {
      seriesIndex: [...seriesIndex, ...selectedGroupsIndices],
      targetIndex: getAttentionWellIndex({ states, series }),
      seriesType: SeriesType.Pie
    };
  }
}
