import { memo } from "react";
import { useDispatch } from "react-redux";

import { useChartsContext } from "contexts/ChartContext";
import { updateChartByPath } from "store/features";

import { useChartEntities } from "hooks/charts";

import { SpaghettiMode } from "components/icons";

import ToolbarButton from "../../base/ToolbarButton";

const SpaghettiToggle = () => {
  const dispatch = useDispatch();
  const { id } = useChartsContext();
  const { sum: spaghettiStates } = useChartEntities(id);
  const isEnabled = spaghettiStates?.enabled;
  const isActive = spaghettiStates?.active;
  const showButton = spaghettiStates?.show;

  const toggle = (active: boolean) => {
    dispatch(
      updateChartByPath({
        id,
        path: `features.spaghetti.active`,
        value: active
      })
    );
  };
  if (!showButton) {
    return null;
  }
  return (
    <ToolbarButton
      active={isActive}
      icon={<SpaghettiMode />}
      overflowLabel="Spaghetti"
      tooltipText="Toggle Spaghetti Plot"
      onToggle={toggle}
      disabled={!isEnabled}
    />
  );
};

export default memo(SpaghettiToggle);
