// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { memo } from "react";

import { mdiCalculator } from "@mdi/js";
import { noop } from "utils";

import ToolbarButton from "../../base/ToolbarButton";

const ProbitDataSourceToggle = () => {
  const toggle = noop;

  // TODO chart: add probit chart
  return null;

  return (
    <ToolbarButton
      icon={<Icon path={mdiCalculator} size={1.3} />}
      active={false}
      overflowLabel="Use line of best fit for stats"
      tooltipText="Use line of best fit for stats"
      onToggle={toggle()}
    />
  );
};

export default memo(ProbitDataSourceToggle);
