import { IS_DEV_ENV } from "constants/app.constants";
import { PARAM_FIELD } from "constants/chart.constants";
import { EntityType } from "constants/entities.enum";
import { createEntityState } from "entities/charts/factory";
import { createMergeWithDefaults } from "entities/utils";
import { EvaChartFeatures } from "types";

import { IGroupBy } from "models";

const MIDSTREAM_CROSS_PLOT_FEATURES: Partial<EvaChartFeatures> = {
  screenshot: createEntityState(EntityType.ScreenshotFeature, {
    show: true,
    enabled: true
  }),
  fullScreen: createEntityState(EntityType.ChartsFeature, {
    show: true,
    enabled: true
  }),
  datatable: createEntityState(EntityType.ChartDataTableFeature, {
    show: true,
    enabled: true
  }),
  spaghetti: createEntityState(EntityType.ChartsFeature, {
    show: true,
    enabled: false
  }),
  debug: createEntityState(EntityType.ChartsFeature, {
    show: IS_DEV_ENV,
    enabled: IS_DEV_ENV
  }),
  cutoff: createEntityState(EntityType.CutOffChartFeature, {
    show: true,
    enabled: true
  }),
  shutInMonths: createEntityState(EntityType.ShutInMonthsChartFeature, {
    show: true,
    enabled: true
  }),
  movingAverageDays: createEntityState(EntityType.MovingAverageDaysChartFeature, {
    show: true,
    enabled: true
  }),
  survivorBias: createEntityState(EntityType.ChartsFeature, {
    show: true,
    enabled: true,
    active: true
  }),
  useWeightedRatioAverage: createEntityState(EntityType.ChartsFeature, {
    show: true,
    enabled: true,
    active: true
  }),
  lockUnits: createEntityState(EntityType.ChartsFeature, {
    show: true,
    enabled: true
  }),
  lasso: createEntityState(EntityType.LassoFeature, {
    show: true,
    enabled: true
  }),
  chartFocus: createEntityState(EntityType.ChartFocusMidStreamFeature, {
    show: true,
    enabled: true
  }),
  legend: createEntityState(EntityType.ChartsFeature, {
    show: true,
    enabled: true
  }),
  pointSize: createEntityState(EntityType.PointSizeFeature, {
    show: true,
    enabled: true
  }),
  pointOpacity: createEntityState(EntityType.PointOpacityFeature, {
    show: true,
    enabled: true
  }),
  chartTypeParam: createEntityState(EntityType.ChartTypeParamFeature, {
    properties: {
      x: PARAM_FIELD.FacilityStartDateYear as Partial<IGroupBy>,
      y: PARAM_FIELD.CurrentCalRate as Partial<IGroupBy>
    }
  })
};

export const crossPlotMidstreamFeatures = createMergeWithDefaults(
  MIDSTREAM_CROSS_PLOT_FEATURES
);
