import { useEffect, useState } from "react";

import {
  ALL_PRODUCT_TYPES,
  FORECAST_PRODUCT_LIST,
  PERFORMANCE_PRODUCT_LIST
} from "constants/chart.constants";
import {
  MY_FOCUS_FIELDS,
  ORG_FOCUS_FIELDS,
  ORG_GEO_FIELDS
} from "constants/settings.constants";
import { getProductType } from "utils";

import useBetaFeatures from "hooks/useBetaFeatures";

import { EntityKind } from "../../../models/entityKind";
import { useGroupByState, useGroupByUpdater } from "../group-by.context";
import { geoVolumeCategoryTitle } from "./useCategories";

const useSelectedCategory = () => {
  const { selectedCategory, selectedProduct, entityKind } = useGroupByState();
  const updateGroupByState = useGroupByUpdater();
  const { hasFeature } = useBetaFeatures();

  const [value, setValue] = useState({
    hasPerformaceFields: false,
    hasForecastFields: false,
    hasProductFields: false,
    isUserDefinedColumn: false,
    isOrgDefinedColumn: false,
    isOrgGeoDefinedColumn: false,
    productList: [],
    isShapefileCategory: false,
    isGeoVolumeCategory: false
  });

  useEffect(() => {
    if (!selectedCategory?.name) return;

    const { name } = selectedCategory;

    const hasPerformaceFields = name === "Performance";
    const hasForecastFields = name === "Forecast";
    const hasProductFields = hasPerformaceFields || hasForecastFields;
    const isGeoVolumeCategory = name === geoVolumeCategoryTitle;

    const isUserDefinedColumn = name.indexOf(MY_FOCUS_FIELDS) >= 0;
    const isOrgDefinedColumn = name.indexOf(ORG_FOCUS_FIELDS) >= 0;
    const isShapefileCategory = name.indexOf("Shapefile") === 0;
    const isOrgGeoDefinedColumn = name.indexOf(ORG_GEO_FIELDS) >= 0;

    const productList = (
      hasPerformaceFields
        ? PERFORMANCE_PRODUCT_LIST
        : hasForecastFields
        ? FORECAST_PRODUCT_LIST
        : []
    )
      .filter((item) => !item.available || item.available(hasFeature))
      .map((item) => item.label);

    setValue({
      hasPerformaceFields,
      hasForecastFields,
      hasProductFields,
      isUserDefinedColumn,
      isOrgDefinedColumn,
      isOrgGeoDefinedColumn,
      productList,
      isShapefileCategory,
      isGeoVolumeCategory: isGeoVolumeCategory
    });
  }, [selectedCategory?.name]);

  /**
   * Side-effect of category change:
   *
   * If selected-category has product list, and selected-product is not in the
   * product list, then set selected-product to the first product in the list.
   */
  useEffect(() => {
    const { hasProductFields, productList } = value;

    if (!hasProductFields || productList.length <= 1) return;

    const { label } = getProductType(selectedProduct);
    const productInList = selectedProduct && productList.includes(label);
    productInList ||
      updateGroupByState({
        type: "selected_product",
        payload:
          entityKind == EntityKind.Well
            ? productList.includes(ALL_PRODUCT_TYPES.Oil.label)
              ? ALL_PRODUCT_TYPES.Oil.label
              : productList[0]
            : productList.includes(ALL_PRODUCT_TYPES.GasInlet.label)
            ? ALL_PRODUCT_TYPES.GasInlet.label
            : productList[1]
      });
  }, [value.hasPerformaceFields, value.hasForecastFields, value.productList]);

  return value;
};

export default useSelectedCategory;
