export enum ChartEntity {
  Chart = "Chart",
  ChartsFeature = "ChartsFeature",
  CutOffChartFeature = "CutOffChartFeature",
  ShutInMonthsChartFeature = "ShutInMonthsChartFeature",
  MovingAverageDaysChartFeature = "MovingAverageDaysChartFeature",
  ChartDataTableFeature = "ChartDataTableFeature",
  SourceFeature = "SourceFeature",
  TimeStepFeature = "TimeStepFeature",
  LassoFeature = "LassoFeature",
  NormalizationFeature = "NormalizationFeature",
  P10Feature = "P10Feature",
  P50Feature = "P50Feature",
  P90Feature = "P90Feature",
  AverageFeature = "AverageFeature",
  ScreenshotFeature = "Screenshot",
  ChartFocusFeature = "ChartFocusFeature",
  PointOpacityFeature = "PointOpacityFeature",
  PointSizeFeature = "PointSizeFeature",
  ChartTypeParamFeature = "ChartTypeParamFeature",
  ChartFocusMidStreamFeature = "ChartFocusMidStreamFeature",
  PressureChartSettingsFeature = "PressureChartSettingsFeature"
}

export enum MapEntity {
  Map = "Map"
}

export enum ThreeDViewEntity {
  ThreeDView = "3dView"
}

export const EntityType = {
  ...ChartEntity,
  ...MapEntity,
  ...ThreeDViewEntity
};
