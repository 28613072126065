import { memo, useState } from "react";

import { Popover } from "antd";

import { useChartEntities } from "hooks/charts";

import { GroupBy as GroupByIcon } from "components/icons";

import { useChartsContext } from "../../../../contexts/ChartContext";
import ToolbarButton from "../../../base/ToolbarButton";
import ChartFocusSettings from "./ChartFocusSettings";

const ChartFocusToggle = () => {
  const { id } = useChartsContext();
  const { chartFocus } = useChartEntities(id);
  const isActive = chartFocus?.active;
  const showButton = chartFocus?.show;
  const [visible, setVisible] = useState(false);

  if (!showButton) {
    return null;
  }

  return (
    <Popover
      placement="bottomRight"
      arrowPointAtCenter
      content={<ChartFocusSettings />}
      trigger="click"
      open={visible}
      onOpenChange={(v) => setVisible(v)}>
      <ToolbarButton
        data-testid={isActive ? "chartFocusToggleActive" : "chartFocusToggleInactive"}
        icon={<GroupByIcon size={18} />}
        isMenuButton={true}
        overflowLabel="Chart Focus"
        tooltipText="Chart Focus"
        active={isActive}
      />
    </Popover>
  );
};

export default memo(ChartFocusToggle);
