import { memo } from "react";
import { useDispatch } from "react-redux";

import { useChartsContext } from "contexts";
import { updateChartByPath } from "store/features";

import { useChartEntities } from "hooks/charts";

import { LegendFilled } from "components/icons";

import ToolbarButton from "../../../base/ToolbarButton";

const LegendToggle = () => {
  const dispatch = useDispatch();
  const { id } = useChartsContext();
  const { chartLegend } = useChartEntities(id);
  const isActive = chartLegend?.active;

  const toggle = (active: boolean) => {
    dispatch(
      updateChartByPath({
        id,
        path: `features.legend.active`,
        value: active
      })
    );
  };

  return (
    <ToolbarButton
      active={isActive}
      data-testid={isActive ? "chartLegendToggleActive" : "chartLegendToggleInactive"}
      icon={<LegendFilled />}
      overflowLabel="Legend"
      tooltipText="Legend"
      onToggle={toggle}
    />
  );
};

export default memo(LegendToggle);
