import classnames from "classnames";

import { YMaxLocker, YMinLocker } from "../AxisLocker";
import { Wrapper } from "./AxisContainer.styles";

export function YAxisContainer() {
  const wrapperClassnames = classnames({
    yAxis: true
  });

  return (
    <Wrapper className={wrapperClassnames}>
      <YMaxLocker />
      <YMinLocker />
    </Wrapper>
  );
}
