import { EvaSource, TimeStep } from "constants/charts.enums";
import { createMergeWithDefaults } from "entities/utils";
import { CrossPlotChartRequestType } from "types";

import { MaxBinsSortOrderEnum } from "models/binSize";
import { EntityKind } from "models/entityKind";

import { PdenSourceEnum } from "../../../../models/pdenDataSourceSetting";
import { BASE_CHART_REQUEST } from "./defaults";

export const DEFAULT_CROSS_PLOT_REQUEST: Partial<CrossPlotChartRequestType> = {
  ...BASE_CHART_REQUEST,
  FilterId: "",
  requestId: "",
  ReverseColor: false,
  ColorPalette: {
    id: "-6",
    name: "Portland",
    shared: false,
    colors: ["rgb(12,51,131)"],
    preferredColors: [],
    paletteType: 0,
    colorScale: [],
    specialColors: null,
    thickness: [2],
    reverse: false
  },
  GroupBy: {
    title: "Resource Play",
    property: "Header.ResourcePlay",
    groupByField: "Header.ResourcePlay",
    pdenSource: "Public",
    canBin: false,
    dataType: "Text", // TODO chart: check this
    categoryId: 1,
    tooltip: "",
    display: "",
    entityKind: EntityKind.Well,
    bin: {
      BinSize: 0,
      MinSize: null,
      MaxBins: null,
      MaxBinsSortOrder: MaxBinsSortOrderEnum.WellCount,
      GreaterThan: null,
      LessThan: null,
      Quantile: {
        quantileType: "NumberOfQuantile",
        numQuantiles: 4
      },
      BinType: "BinSize",
      UseDynamicBins: false
    }
  },
  sortBy: "AlphabeticalAsc",
  typeWells: [],
  normalizeTypeWell: true,
  userForecast: [],
  userForecastSettings: {
    forecastSourceFolder: null,
    excludeMcDanielForecasts: false,
    reserveCategory: null,
    excludeNoviForecasts: true,
    specialForecastFolders: ["McDaniel Research"]
  },
  forecastMode: "forecastTrump",
  useForecastStartDate: false,
  showGroupsNotInFilter: true,
  placeholderBinsEnabled: false,
  hasCheckedBins: true,
  syncClientPden: false,
  syncClientSchema: "",
  colorLockedItems: {},
  syncClientFcst: false,
  syncForecastRescat: "2111",
  normalizeBySetting: {
    field: "Well_Design.HzLength_m",
    display: "Hz Length (m)",
    per: 100,
    unit: "m",
    useMultilinearNormalization: false,
    threshold: 0,
    lowerScalar: 1,
    higherScalar: 1,
    useNormalizeBy: false
  },
  chartSetting: {
    product: "Oil",
    chartType: 0,
    showForecast: false,
    pdenDataSourceSetting: {
      source: EvaSource.Public,
      timeStep: TimeStep.Month
    },
    forecastMode: "forecastTrump",
    useForecastStartDate: false,
    survivorBias: false,
    rateType: 0,
    cutoff: 0,
    timeStep: TimeStep.Month,
    shutInMonths: 0,
    source: EvaSource.Public,
    normalizeBy: {
      useNormalizeBy: false,
      field: "Well_Design.HzLength_m",
      per: 100,
      displayName: "Hz Length (m)",
      unit: "m",
      useMultilinearNormalization: false,
      threshold: 0,
      lowerScalar: 1,
      higherScalar: 1
    },
    useWeightedRatioAverage: false,
    xVariable: {
      valueProperty: "Dates.Vintage_YearMonth",
      displayTitle: "Vintage (Year-Month)",
      canBin: false,
      dataType: "Text",
      mappingName: "Dates.Vintage_YearMonth",
      bin: null
    },
    yVariable: {
      valueProperty: "Performance.Oil.TwelveMCalTimeCum",
      displayTitle: "12M CalTime Cum (Mbbl)",
      canBin: true,
      dataType: "Number",
      mappingName: "Performance.Oil.TwelveMCalTimeCum"
    },
    lockUnits: false
  },
  axisMinMax: {
    xMin: null,
    xMax: null,
    yMin: null,
    yMax: null
  },
  isDefaultLockOn: true,
  chartFieldInformation: {
    xField: {
      type: "x",
      title: "Vintage (Year-Month)",
      property: "Dates.Vintage_YearMonth",
      pdenSource: PdenSourceEnum.Public,
      folderName: "",
      isForecast: false,
      product: "",
      defaultUnit: "Year-Month"
    },
    yField: {
      type: "y",
      product: "Oil",
      defaultUnit: "Mbbl",
      title: "12M CalTime Cum + Forecast (Mbbl)",
      property: "Performance.Oil.TwelveMCalTimeCum.fcst",
      pdenSource: PdenSourceEnum.Public,
      folderName: "",
      isForecast: true
    },
    groupByField: {
      type: "groupBy",
      title: "Resource Play",
      property: "Header.ResourcePlay",
      defaultUnit: "",
      product: "",
      isForecast: false,
      folderName: ""
    }
  }
};

export const generateCrossPlotRequestBody = createMergeWithDefaults(
  DEFAULT_CROSS_PLOT_REQUEST
);
