import { HoverLegends } from "types";

import { DataEnum } from "models";
import { ChartSeries } from "models/model";

export function getLineSeriesMinMax(series: ChartSeries[]) {
  const seriesData = series || [];
  const allX = seriesData.flatMap((location) => location.x);
  const allY = seriesData.flatMap((location) => location.y);

  const xMinValue = allX.reduce((acc, cur) => (cur < acc ? cur : acc), Infinity);
  const xMaxValue = allX.reduce((acc, cur) => (cur > acc ? cur : acc), -Infinity);
  const yMinValue = allY.reduce((acc, cur) => (cur < acc ? cur : acc), Infinity);
  const yMaxValue = allY.reduce((acc, cur) => (cur > acc ? cur : acc), -Infinity);

  const xMin = { value: xMinValue, type: DataEnum.Number };
  const xMax = { value: xMaxValue, type: DataEnum.Number };
  const yMin = { value: yMinValue, type: DataEnum.Number };
  const yMax = { value: yMaxValue, type: DataEnum.Number };

  return { xMin, xMax, yMin, yMax };
}

export function computeSelectionStates({
  hoverLegendItem,
  hoverLegendGroup,
  attentionWells,
  label,
  groupTitle,
  selectedGroups = []
}: Partial<HoverLegends>): { isSelectedWell: boolean; isHovered: boolean } {
  const hasAttentionWell = attentionWells?.length > 0;
  const selectedWell = hasAttentionWell ? attentionWells[0] : null;

  const isSelectedWell = selectedWell ? label.includes(selectedWell) : false;

  const isGroupHovered =
    groupTitle === hoverLegendGroup ||
    groupTitle === hoverLegendItem ||
    selectedGroups.includes(groupTitle);

  const isHovered =
    selectedWell && hoverLegendItem === selectedWell ? isSelectedWell : isGroupHovered;
  return { isSelectedWell, isHovered };
}
