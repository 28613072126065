import useResizeObserver from "@react-hook/resize-observer";
import { MutableRefObject, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useChartsContext } from "contexts";
import { updateChartByPath } from "store/features";

const toggleIconSize = 36;
const leftOffset = 260;

/**
 * Calculates the overflow index for toolbar icons,
 * based on available space and saves it to context.
 */
const useOverflowIndex = (ref: MutableRefObject<HTMLDivElement>) => {
  const dispatch = useDispatch();
  const { id } = useChartsContext();

  const [overflowIndex, setOverflowIndex] = useState<number>(0);

  useEffect(() => {
    dispatch(
      updateChartByPath({
        id,
        path: `overflowIndex`,
        value: overflowIndex
      })
    );
  }, [overflowIndex]);

  useResizeObserver(ref, (client) => {
    const { width } = client.contentRect;
    const availableWidth = width - leftOffset;
    const visibleCount = Math.floor(availableWidth / toggleIconSize);

    const index = Math.max(0, visibleCount);
    setOverflowIndex(index);
  });
};

export default useOverflowIndex;
