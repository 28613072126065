import { EvaChart } from "constants/charts.enums";
import { EntityType } from "constants/entities.enum";

import { generateChartEntity } from "./chart";
import {
  generateAverageFeatureEntity,
  generateChartFocusEntity,
  generateChartTypeParamFeature,
  generateChartsFeatureEntity,
  generateCutOffFeatureEntity,
  generateDataTableFeatureEntity,
  generateLassoFeatureEntity,
  generateMidstreamChartFocusEntity,
  generateMovingAverageDaysFeatureEntity,
  generateNormalizationFeatureEntity,
  generateP10FeatureEntity,
  generateP50FeatureEntity,
  generateP90FeatureEntity,
  generatePointOpacityFeatureEntity,
  generatePointSizeFeatureEntity,
  generatePressureChartSettingsFeature,
  generateScreenshotFeatureEntity,
  generateShutInMonthsFeatureEntity,
  generateSourceFeatureEntity,
  generateTimeStepFeatureEntity
} from "./eva/features/feature";
import { generateCAGRRequestBody } from "./eva/requests/cagr";
import { generateCrossPlotRequestBody } from "./eva/requests/crossPlot";
import { generateCrossPlotMidstreamRequestBody } from "./eva/requests/crossPlotMidstream";
import { generateCumTimeRequestBody } from "./eva/requests/cumTime";
import { generateDeclineRateRequestBody } from "./eva/requests/declineRate";
import { generateMosaicRequestBody } from "./eva/requests/mosaic";
import { generatePieRequestBody } from "./eva/requests/pie";
import { generateRateCumRequestBody } from "./eva/requests/rateCum";
import { generateRateDateRequestBody } from "./eva/requests/rateDate";
import { generateRateDateMidstreamRequestBody } from "./eva/requests/rateDateMidstream";
import { generateRateTimeRequestBody } from "./eva/requests/rateTime";
import { generateTotalRateCumRequestBody } from "./eva/requests/totalRateCum";
import {
  ChartOption,
  generateLineSeriesOptions,
  generateMosaicSeriesOptions,
  generatePieSeriesOptions,
  generateScatterSeriesOptions,
  generateTitleOptions
} from "./options";
import { generateChartOptions } from "./options/chart";
import { generateXAxisOptions } from "./options/xAxis";
import { generateYAxisOptions } from "./options/yAxis";

const GENERATORS = {
  [EntityType.Chart]: generateChartEntity,
  [EntityType.ChartsFeature]: generateChartsFeatureEntity,
  [EntityType.ChartDataTableFeature]: generateDataTableFeatureEntity,
  [EntityType.SourceFeature]: generateSourceFeatureEntity,
  [EntityType.TimeStepFeature]: generateTimeStepFeatureEntity,
  [EntityType.CutOffChartFeature]: generateCutOffFeatureEntity,
  [EntityType.ScreenshotFeature]: generateScreenshotFeatureEntity,
  [EntityType.ShutInMonthsChartFeature]: generateShutInMonthsFeatureEntity,
  [EntityType.MovingAverageDaysChartFeature]: generateMovingAverageDaysFeatureEntity,
  [EntityType.LassoFeature]: generateLassoFeatureEntity,
  [EntityType.P10Feature]: generateP10FeatureEntity,
  [EntityType.P50Feature]: generateP50FeatureEntity,
  [EntityType.P90Feature]: generateP90FeatureEntity,
  [EntityType.AverageFeature]: generateAverageFeatureEntity,
  [EntityType.NormalizationFeature]: generateNormalizationFeatureEntity,
  [EntityType.ChartFocusFeature]: generateChartFocusEntity,
  [EntityType.PointOpacityFeature]: generatePointOpacityFeatureEntity,
  [EntityType.PointSizeFeature]: generatePointSizeFeatureEntity,
  [EntityType.ChartTypeParamFeature]: generateChartTypeParamFeature,
  [EntityType.ChartFocusMidStreamFeature]: generateMidstreamChartFocusEntity,
  [EntityType.PressureChartSettingsFeature]: generatePressureChartSettingsFeature,
  [EvaChart.RateCum]: generateRateCumRequestBody,
  [EvaChart.CrossPlot]: generateCrossPlotRequestBody,
  [EvaChart.CumTime]: generateCumTimeRequestBody,
  [EvaChart.RateTime]: generateRateTimeRequestBody,
  [EvaChart.Pie]: generatePieRequestBody,
  [EvaChart.RateDate]: generateRateDateRequestBody,
  [EvaChart.TotalRateCum]: generateTotalRateCumRequestBody,
  [EvaChart.DeclineRate]: generateDeclineRateRequestBody,
  [EvaChart.CAGR]: generateCAGRRequestBody,
  [EvaChart.Mosaic]: generateMosaicRequestBody,

  // midstream charts
  [EvaChart.RateDateMidstream]: generateRateDateMidstreamRequestBody,
  [EvaChart.CrossPlotMidstream]: generateCrossPlotMidstreamRequestBody,

  [ChartOption.Chart]: generateChartOptions,

  [ChartOption.XAxis]: generateXAxisOptions,
  [ChartOption.YAxis]: generateYAxisOptions,
  [ChartOption.Title]: generateTitleOptions,

  [ChartOption.LineSeries]: generateLineSeriesOptions,
  [ChartOption.ScatterSeries]: generateScatterSeriesOptions,
  [ChartOption.Pie]: generatePieSeriesOptions,
  [ChartOption.MosaicSeries]: generateMosaicSeriesOptions
};

export type Generators = keyof typeof GENERATORS;

export function createEntityState<K extends Generators>(
  type: K,
  state: Partial<ReturnType<(typeof GENERATORS)[K]>>
): ReturnType<(typeof GENERATORS)[K]> {
  const generator = GENERATORS[type];
  if (!generator) {
    throw new Error(`Unknown entity type: ${type} to create state`);
  }
  return generator(state) as ReturnType<(typeof GENERATORS)[K]>;
}
