import { TooltipOption } from "echarts/types/dist/shared";
import { EvaChartStates } from "types/factory";

import { getSeriesTooltipDot } from "components/chart/utils";
import { getSeriesNameWithFormattedUwi } from "components/chart/utils/getSeriesNameWithFormattedUWI";

export function translateCrossPlotTooltipOptions(states: EvaChartStates): TooltipOption {
  const { series, uwis, layout, hoverLegendItem } = states;

  const xAxisValues = layout.xAxis.data;
  const xAxisIsDate = layout.xAxis.axisIsDate;
  const colorList = {};
  for (const s of series) {
    colorList[s.label] = s.style.hexColor;
  }
  return {
    hideDelay: 0,
    transitionDuration: 0, // prevent tooltip from flickering left to right on render
    formatter: function (items) {
      const filteredItems = Array.isArray(items)
        ? items.filter(
            (item) => item.seriesName.startsWith(hoverLegendItem) && item.value !== ""
          )
        : [items];

      const series = filteredItems.length > 0 ? filteredItems[0] : null;
      const uwiIndexPresent = items.value?.length === 3;

      if (series) {
        const seriesXValue = series.value[0];
        const seriesYValue = series.value[1];
        const uwi = uwis[series.value[2]];
        const seriesColor = colorList[series.seriesName];
        if (xAxisIsDate && uwiIndexPresent) {
          return (
            `<span style="color:#666";>${getSeriesNameWithFormattedUwi(uwi)}</span>` +
            "<br/>" +
            `<span style="color:#666";>${getSeriesTooltipDot(seriesColor)}${
              series.seriesName
            }</span>` +
            "<br/>" +
            `<p style="font-family: var(--fontMono);color:#666">${
              xAxisValues[seriesXValue]
            }&emsp;<span style="font-weight:700;color:#666;float:right;font-family: var(--fontMono);";>${seriesYValue.toFixed(
              2
            )}</span></p>`
          );
        } else if (!xAxisIsDate && items.value.length === 3) {
          if (typeof seriesXValue === "number" && typeof seriesYValue === "number") {
            return (
              `<span style="color:#666";>${getSeriesNameWithFormattedUwi(uwi)}</span>` +
              "<br/>" +
              `<span style="color:#666";>${getSeriesTooltipDot(seriesColor)}${
                series.seriesName
              }</span>` +
              "<br/>" +
              `<p style="font-family: var(--fontMono);color:#666">${seriesXValue.toFixed(
                2
              )}&emsp;<span style="font-weight:700;color:#666;float:right;font-family: var(--fontMono);";>${seriesYValue.toFixed(
                2
              )}</span></p>`
            );
          }
        }
      }
    }
  };
}
