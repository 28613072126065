import { EvaChart } from "constants/charts.enums";
import { LassoSelectionStates } from "types/lasso";

import { getCagrList } from "./cagr";
import { getCrossPlotList } from "./crossPlot";
import { getCumTimeList } from "./cumTime";
import { getDeclineRateList } from "./declineRate";
import { getRateCumList } from "./rateCum";
import { getRateDateList } from "./rateDate";
import { getRateTimeList } from "./rateTime";

export const LASSO_MAP = {
  [EvaChart.RateCum]: getRateCumList,
  [EvaChart.CrossPlot]: getCrossPlotList,
  [EvaChart.CumTime]: getCumTimeList,
  [EvaChart.RateTime]: getRateTimeList,
  [EvaChart.RateDate]: getRateDateList,
  [EvaChart.DeclineRate]: getDeclineRateList,
  [EvaChart.CAGR]: getCagrList,
  [EvaChart.Mosaic]: () => [],

  // midstream charts
  [EvaChart.RateDateMidstream]: getRateDateList,
  [EvaChart.CrossPlotMidstream]: getCrossPlotList
};

export function getBrushSelectList({
  params,
  chartType,
  series,
  uwiList = []
}: LassoSelectionStates): string[] {
  const list = LASSO_MAP[chartType];
  if (chartType === EvaChart.TotalRateCum || chartType === EvaChart.Pie) return;

  if (!list) {
    throw new Error(`Unknown chart type: ${chartType} to get brush select list`);
  }
  return list({ params, series, uwiList });
}
