import useResizeObserver from "@react-hook/resize-observer";
import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";

import { Feature, MultiPolygon, Polygon } from "@turf/helpers";
import * as turf from "@turf/turf";
import { Alert } from "antd";
import axios, { AxiosResponse } from "axios";
import colormap from "colormap";
import _debounce from "lodash/debounce";
import { ISelectedWell, setAttentionWells, setXdaInterceptData } from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components";
import Two from "two.js";
import ZUI from "two.js/extras/jsm/zui.js";
import { getP10P90Percentile } from "utils";
import { calculateTextDimensions } from "utils/dom";
import { getMaxDecimalPlaces } from "utils/getMaxDecimalPlaces";

import { getDynamicBinSettings } from "api/getDynamicBinSettings";
import { MapEntity } from "api/map";

import { LegendItemModel, MutationParameters } from "models";
import Uwi from "models/uwi";
import { ScreenSize, XdaData, XdaLayoutOptions } from "models/xdaData";

import { BusyOverlay, ErrorOverlay } from "components/activity/shared";
import { ParentDimensionsT } from "components/chart/legend/Legend";
import { useDashboardContext, useDashboardDispatch } from "components/dashboard/hooks";
import { IconSpinner } from "components/icons";
import { useIpdbDispatch } from "components/map/contexts/IpdbContext";
import { IpdbLegend } from "components/map/legend/IpdbLegend";
import { useScreenshotContext } from "components/screenshot/hooks";
import { Screenshot } from "components/vis";

import { lightenColor } from "../../utils/color/lightenColor";
import { getIpdbBin } from "../../utils/getIpdbBin";
import {
  clearMeasurementData,
  drawAxis,
  drawCentroid,
  drawIpdb3dModel,
  drawShape,
  drawZoneLine,
  handleMeasurementTool
} from "../pad-study/drawHelper";
import XdaToolbar from "./XdaToolbar";
import { updateXDASettings, useVisState } from "./context";
import { IpdbBin, IpdbColors, IpdbNumOfColorsForPalettes } from "./context/types";
import { useMapEntitiesQuery, useXdaConstraint } from "./hooks";
import { useShowXdaInfoRef } from "./hooks/useShowXdaInfoRef";
import checkDataForFieldIsAllZeroes from "./util/checkDataForFieldIsAllZeroes";
import convertHexToRGBA from "./util/convertHexToRgba";

const XdaEndpoint = process.env.REACT_APP_XDA_SERVICE;
const measurementData = {
  clickEvent: null,
  distanceText: null,
  dots: [],
  dotCounter: 0,
  line: null,
  textBackground: null
};

const params = {
  fullscreen: false,
  autostart: true
};

const NullColor = "gray";

function XdaViewer({ onFullscreenToggle }) {
  // context state and dispatch functions
  const [{ xda }, visDispatch] = useVisState();
  const {
    upHeight,
    downHeight,
    leftWidth,
    ipdbFontSize,
    ipdbOpacity,
    showIpdbLegend,
    rightWidth,
    showCompletion,
    scaleByOption,
    scaleByValue,
    completionLength,
    showGrid,
    showMeasurement,
    showXdaValueInfo,
    showTooltip,
    showIpdb,
    topsModelSource,
    dataFields,
    showData,
    ipdbSource,
    hangWellsToTop,
    bin: xdaBin,
    reverseColor,
    showAllTops,
    showOverlap,
    widthScaled,
    showRelativeDepth,
    showTVDSSDepth,
    selectedPlays,
    resetZuiInstance
  } = xda.settings;

  const { lockMap } = useDashboardContext();
  const { widget, settings } = useScreenshotContext();
  const dashboardDispatch = useDashboardDispatch();
  const dispatch = useDispatch();
  const ipdbDispatch = useIpdbDispatch();
  const highlightWells = useRef<string>("");
  // state
  const [wellLabel, setWellLabel] = useState("");
  const [labelTop, setLabelTop] = useState(100);
  const [labelLeft, setLabelLeft] = useState(100);
  const [errorMsg, setErrorMsg] = useState("");
  const [binUpdateErrorMessage, setBinUpdateErrorMessage] = useState("");
  const [parentDimensions, setParentDimensions] = useState<ParentDimensionsT>(undefined);
  const attentionWells = useSelector((state: RootState) => state.map.attentionWells);
  const geoRectanglesRef = useRef<unknown[]>([]);
  const highlightedGeoRectanglesRef = useRef<unknown[]>([]);
  // state from store
  const selectedWells: { [name: string]: ISelectedWell } = useSelector(
    (state: RootState) => state.map.selectedWells
  );
  const currentPolygon = useSelector((state: RootState) => state.filter.polygonFilter) as
    | Polygon
    | MultiPolygon
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | Feature<Polygon | MultiPolygon, { [name: string]: any }>;

  const xdaLineRequest = useSelector((state: RootState) => state.map.xdaLineRequest);
  const showXdaValueInfoRef = useShowXdaInfoRef();
  const padScenario = useSelector((state: RootState) => state.app.padScenario);
  // refs
  const labelRef = useRef(null);
  const sceneRef = useRef(null);
  const shapeRef = useRef([]);
  const screenSize = useRef<ScreenSize>(null);
  const two = useRef(new Two(params));
  const zuiRef = useRef(null);
  const dataRef = useRef<XdaData>(null);
  const pathsRef = useRef([]);
  const mouseoverRef = useRef(null);
  const measurementDataRef = useRef(measurementData);
  // custom hooks
  const { playConstraint } = useXdaConstraint();
  const { mapEntities } = useMapEntitiesQuery();
  const [valueInfo, setValueInfo] = useState("");

  const screenshotBounds = {
    width: settings?.width || 1152,
    height: settings?.height || 681
  };

  const screenshotOverlayVisible = useMemo(() => {
    return widget?.widgetId === "xda";
  }, [widget]);

  const activeIpdbLegend =
    screenshotOverlayVisible && settings?.legendVisible !== undefined
      ? settings.legendVisible
      : showIpdbLegend;

  const activeIpdbFontSize =
    screenshotOverlayVisible && settings?.legendFontSize
      ? settings.legendFontSize
      : ipdbFontSize;

  const activeBackgroundOpacity =
    screenshotOverlayVisible && settings?.legendOpacity
      ? settings.legendOpacity
      : ipdbOpacity;

  const activeShowLegendBorder =
    screenshotOverlayVisible && settings?.legendBorderVisible !== undefined
      ? settings.legendBorderVisible
      : true;

  //Text to simulate the expected size length of tops and grid
  const textTops = "AAAAAAAAAAA";
  const textGrid = "0000";
  const xdaLayoutOptions: XdaLayoutOptions = {
    grid: {
      top: 50,
      left:
        screenshotOverlayVisible && settings?.topsLabelsFontSize
          ? Number(
              calculateTextDimensions(textTops, settings.topsLabelsFontSize, "sans-serif")
                .width
            ) + 24 //calculateTextDimensions is 66 when font size is default (9)
          : 90,
      right:
        screenshotOverlayVisible && settings?.gridLabelsFontSize
          ? Number(
              2 *
                calculateTextDimensions(
                  textGrid,
                  settings.gridLabelsFontSize,
                  "sans-serif"
                ).width
            ) + 93 //calculateTextDimensions is 22 when text size is default (9)
          : 115,
      bottom:
        screenshotOverlayVisible && settings?.gridLabelsFontSize
          ? Number(
              calculateTextDimensions(textGrid, settings.gridLabelsFontSize, "sans-serif")
                .height
            ) + 34 //calculateTextDimensions is 16 when text size is default (9)
          : 50
    },
    axisGap: 20,
    scale: 1
  };

  useEffect(() => {
    if (binUpdateErrorMessage) {
      ipdbDispatch({
        payload: {
          legendTitle: "",
          legendItems: []
        },
        type: "update"
      });
    }
  }, [binUpdateErrorMessage]);

  const { data, isError, isLoading, mutate } = useMutation(
    ({ wells, line, dataFields }: MutationParameters) => {
      if (!line || !topsModelSource.length) return;
      return axios.post(`${XdaEndpoint}/line`, {
        line,
        wells,
        upHeight,
        downHeight,
        leftWidth,
        rightWidth,
        showCompletion,
        scaleByOption,
        scaleByValue,
        completionLength,
        showIpdb,
        topsModelSource,
        showAllTops,
        showOverlap,
        data_fields: dataFields.map((field) => field.property),
        data_source: ipdbSource,
        hangWellsToTop: hangWellsToTop,
        geoModelField: xda.settings.ipdbField,
        padScenario,
        selectedPlays
      });
    },
    {
      onSuccess: (response: AxiosResponse<XdaData>) => {
        if (response.data === null) {
          dataRef.current.available_plays = [];
        }

        const data = response.data;

        if (data.error_message) {
          throw data.error_message;
        }
        dispatch(
          setXdaInterceptData({
            line: data.line,
            points: data.intercept_points
          })
        );
        dataRef.current = data;
        renderScene(data);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        if (err.response?.data) {
          setErrorMsg(err.response.data);
        } else if (typeof err === "string") {
          setErrorMsg(err);
        } else {
          setErrorMsg("network error occurred");
        }

        renderScene({
          available_plays: [],
          centroids: [],
          polygons_not_in_list: [],
          lsds: [],
          otherLsds: [],
          ids: [],
          error_message: "",
          polygons: [],
          scale_factor: 0,
          rect: undefined,
          line: undefined,
          intercept_points: [],
          well_data: []
        });
      }
    }
  );

  const onShapeEntered = useCallback(
    (evt, path, id, selectedWells, wellData: string[]) => {
      if (id.unique_id in selectedWells) {
        const well = selectedWells[id.unique_id];
        path.fill = well.color;
        if (!labelRef.current) {
          return;
        }
        setLabelLeft(evt.offsetX);
        setLabelTop(evt.offsetY);
        dispatch(setAttentionWells([id.unique_id]));
        const uwi = new Uwi().toFormatted(id.unique_id);
        let label = uwi + "<br/>" + well.group;
        if (wellData?.length === 4) {
          for (let i = 0; i < 4; i++) {
            const value = wellData[i];
            if (value.length > 0 && dataFields[i].title) {
              label += "<br/>" + dataFields[i].title + ": " + value;
            }
          }
        }
        setWellLabel(label);
        if (showTooltip) {
          ReactTooltip.show(labelRef.current);
        }
      }
    },
    [dispatch, showTooltip, dataFields]
  );

  const onShapeLeave = useCallback(
    (_, path) => {
      path.fill = "rgba(0,0,0,0)";
      ReactTooltip.hide(labelRef.current);
      dispatch(setAttentionWells([]));
    },
    [dispatch]
  );

  const scaleData = (data: XdaData, scale: number): XdaData => {
    const scaledData = Object.assign({}, data);
    scaledData.rect = {
      width: data.rect.width,
      height: data.rect.height,
      miny: data.rect.miny,
      maxy: data.rect.maxy,
      widthScaled: data.rect.width * scale
    };
    scaledData.centroids = data.centroids.map((centroid) => {
      return { x: centroid.x * scale, y: centroid.y };
    });
    scaledData.intercept_points = data.intercept_points.map((point) => {
      return { x: point.x * scale, y: point.y };
    });
    scaledData.polygons = data.polygons.map((polygon) => {
      return polygon.map((point) => {
        return { x: point.x * scale, y: point.y };
      });
    });
    scaledData.polygons_not_in_list = data.polygons_not_in_list.map((polygon) => {
      return {
        unique_id: polygon.unique_id,
        points: polygon.points.map((point) => {
          return { x: point.x * scale, y: point.y };
        }),
        centroid: { x: polygon.centroid.x * scale, y: polygon.centroid.y }
      };
    });
    scaledData.lsds = data.lsds.map((lsd) => {
      return {
        lsd: lsd.lsd,
        zones: lsd.zones.map((zone) => {
          return {
            zone: zone.zone,
            line: {
              start: { x: zone.line.start.x * scale, y: zone.line.start.y },
              end: { x: zone.line.end.x * scale, y: zone.line.end.y }
            }
          };
        })
      };
    });

    scaledData.ipdb3d_boxes = data.ipdb3d_boxes?.map((box) => {
      return {
        bot: box.bot,
        top: box.top,
        left: { x: box.left.x * scale, y: box.left.y },
        right: { x: box.right.x * scale, y: box.right.y },
        val: box.val
      };
    });

    return scaledData;
  };

  const renderScene = useCallback(
    async (data: XdaData) => {
      const selectedWellsAndSticks = { ...selectedWells };
      if (padScenario?.sticks?.length > 0) {
        for (const stick of padScenario.sticks) {
          selectedWellsAndSticks[stick.id] = {
            Uwi: stick.id,
            color: "black",
            group: "Sticks"
          };
        }
      }

      if (!data || !mapEntities?.data) {
        return;
      }

      const ipdbBin = getIpdbBin(
        xdaBin?.lessThan,
        xdaBin?.binSize,
        xdaBin?.greaterThan,
        false
      );

      if (showIpdb && data.ipdb3d_boxes?.length > 0) {
        // No data: Sometimes the ipdbField is not present in the data, so we return null values.
        let defaultBinValues = null;
        const flatArray = data.ipdb3d_boxes.map((box) => box.val);
        const p10p90 = getP10P90Percentile(flatArray);
        if (p10p90?.length) {
          defaultBinValues = await getDynamicBinSettings({
            p10: p10p90[0],
            p90: p10p90[1]
          });
        }

        // Overwrite the calculated binSize if it is set by user
        if (xdaBin?.binSize) {
          ipdbBin.binSize =
            typeof xdaBin?.binSize === "string"
              ? parseFloat(xdaBin?.binSize)
              : xdaBin.binSize;
        }

        if (defaultBinValues) {
          const defaultIpdbBin = defaultBinValues;
          if (
            (!xdaBin?.isLocked || !xdaBin?.binSize) &&
            (ipdbBin.lessThan !== defaultIpdbBin.lessThan ||
              ipdbBin.binSize !== defaultIpdbBin.binSize ||
              ipdbBin.greaterThan !== defaultIpdbBin.greaterThan)
          ) {
            // since we are updating the bin, we should return and not render the scene
            // this will allow the useEffect to re-render the scene with the new bin
            defaultIpdbBin.isLocked = false;
            updateXDASettings(visDispatch, {
              ...xda.settings,
              bin: defaultIpdbBin
            });
            return;
          }
        } else {
          setBinUpdateErrorMessage("Selected field has no data.");
          updateXDASettings(visDispatch, { ...xda.settings, bin: null });
          return;
        }
      }

      // Clear curent scene
      const context = two.current;
      context.clear();
      removeShapeEventBinding();

      clearMeasurementData(measurementDataRef, context, true);

      if (data?.available_plays?.length) {
        // this is the case where no wells are crossed by the line
        const line = turf.lineString(xdaLineRequest.line);
        if (
          !turf.booleanWithin(line, currentPolygon) &&
          !turf.booleanCrosses(line, currentPolygon)
        ) {
          // if the line is not within the map, we should not render the scene
          return;
        }
      } else if (mapEntities?.status === 200 && !mapEntities.data.length) {
        // this is the case where we have crossed wells but no data is returned
        // If map request is successful but no data is returned, stop drawing the new scene
        // also need to check if this is a no cross section scenario, where we will need to drawing the new scene no matter what
        return;
      }
      data = scaleData(data, widthScaled);
      xdaLayoutOptions.scale = widthScaled;
      let selectedEntities: { [uwi: string]: ISelectedWell } = Object.assign(
        {},
        selectedWellsAndSticks
      );
      if (mapEntities?.status === 200 && mapEntities.data.length) {
        const temp: { [uwi: string]: ISelectedWell } = {};
        const entitiesDict: { [name: string]: MapEntity } = {};
        for (const ent of mapEntities.data) {
          entitiesDict[ent.uwi] = ent;
        }
        if (padScenario?.sticks?.length > 0) {
          for (const stick of padScenario.sticks) {
            entitiesDict[stick.id] = {
              color: "black",
              group: "Sticks",
              value: "n/a",
              hasSurvey: true,
              uwi: stick.id
            };
          }
        }
        for (const selected of Object.keys(selectedEntities)) {
          if (entitiesDict[selected]) {
            temp[selected] = {
              color: entitiesDict[selected].color,
              Uwi: selectedEntities[selected].Uwi,
              group: entitiesDict[selected].group
            };
          }
        }
        selectedEntities = temp;
      }
      handleMeasurementTool(
        measurementDataRef,
        showMeasurement,
        context,
        data,
        screenSize.current,
        xdaLayoutOptions,
        zuiRef.current
      );

      drawAxis(
        context,
        data,
        xdaLayoutOptions,
        screenSize.current,
        xda.settings,
        settings,
        screenshotOverlayVisible
      );

      shapeRef.current = [];
      if (showIpdb && data.ipdb3d_boxes?.length > 0) {
        try {
          const dataAllZeroes = checkDataForFieldIsAllZeroes(data.ipdb3d_boxes);

          let numOfColors = dataAllZeroes
            ? 1
            : Math.ceil((ipdbBin.greaterThan - ipdbBin.lessThan) / ipdbBin.binSize);
          const decimalPlaces = getMaxDecimalPlaces([
            xdaBin?.lessThan,
            xdaBin?.greaterThan,
            xdaBin?.binSize
          ]);
          const stops: [number, string][] = [];
          const hasMaxValue: boolean = ipdbBin?.greaterThan != null;
          const hasMinValue: boolean = !!ipdbBin?.lessThan != null;
          if (hasMinValue) {
            numOfColors += 1;
          }
          if (hasMaxValue) {
            numOfColors += 1;
          }
          let colorMapNumOfColors = numOfColors;
          // Since the number of colors must match the number of bins in the colormap, this is a workaround
          // to allow dynamic bins to function properly. This code can be removed once the colour palette refactor
          // EVA-3858 is complete which should allow colour interpolation between a number of colours
          // that is less than the number of bins
          if (
            xda.settings.ipdbColor === IpdbColors[0] &&
            numOfColors < IpdbNumOfColorsForPalettes[0]
          ) {
            colorMapNumOfColors = IpdbNumOfColorsForPalettes[0];
          } else if (
            xda.settings.ipdbColor === IpdbColors[1] &&
            numOfColors < IpdbNumOfColorsForPalettes[1]
          ) {
            colorMapNumOfColors = IpdbNumOfColorsForPalettes[1];
          } else if (
            xda.settings.ipdbColor === IpdbColors[2] &&
            numOfColors < IpdbNumOfColorsForPalettes[2]
          ) {
            colorMapNumOfColors = IpdbNumOfColorsForPalettes[2];
          } else if (
            xda.settings.ipdbColor === IpdbColors[3] &&
            numOfColors < IpdbNumOfColorsForPalettes[3]
          ) {
            colorMapNumOfColors = IpdbNumOfColorsForPalettes[3];
          }
          const colors = colormap({
            colormap: xda.settings.ipdbColor,
            nshades: colorMapNumOfColors,
            format: "hex",
            alpha: 1
          });

          setBinUpdateErrorMessage("");

          if (reverseColor) {
            colors.reverse(); // Reverses in place.
          }

          if (dataAllZeroes) {
            // match color to 3D model if only one color
            stops.push([0, colors[colors.length - 1]]);
          } else {
            for (let i = 0; i < numOfColors; i++) {
              stops.push([ipdbBin.lessThan + ipdbBin.binSize * i, colors[i]]);
            }
          }

          const legendItems = stops.map((stop, i) => {
            // This is to ensure the binValue doesn't exceed the max value if the bin size is not a perfect divisor of the max value.
            const binValue =
              stop[0] > ipdbBin.greaterThan
                ? ipdbBin.greaterThan.toFixed(decimalPlaces)
                : stop[0].toFixed(decimalPlaces);
            const li = new LegendItemModel(binValue);
            const previousBinValue =
              i > 0 ? stops[i - 1][0].toFixed(decimalPlaces) : binValue;
            li.text = "";
            if (hasMinValue && i == 0) {
              li.text = "< " + binValue;
            } else if (hasMaxValue && i == numOfColors - 1) {
              li.text = "≥ " + binValue;
            } else {
              li.text = `${previousBinValue} - ${binValue}`;
            }
            li.title = li.text;
            li.value = binValue;
            li.color = stop[1];
            return li;
          });

          //Dispatch to allow for display of default bin settings in options
          if (!xdaBin) {
            updateXDASettings(visDispatch, {
              ...xda.settings,
              ["bin"]: new IpdbBin(ipdbBin.lessThan, ipdbBin.binSize, ipdbBin.greaterThan)
            });
          }
          ipdbDispatch({
            payload: {
              legendTitle: `${xda.settings.ipdbField} ${xda.settings.ipdbUnit}`,
              showIpdb: true,
              legendItems: dataAllZeroes ? [] : legendItems
            },
            type: "update"
          });
          geoRectanglesRef.current = drawIpdb3dModel(
            context,
            data,
            xdaLayoutOptions,
            screenSize.current,
            xda.settings,
            (ipdb) => {
              const value = ipdb.val;
              if (value == null) {
                return { value, color: NullColor };
              }
              if (hasMinValue && value < ipdbBin.lessThan) {
                return { value, color: colors[0] };
              }
              if (hasMaxValue && value >= ipdbBin.greaterThan) {
                return { value, color: colors[numOfColors - 1] };
              }
              const index = findFirstGreaterThan(stops, value);
              if (index >= stops.length) {
                return { value, color: "#fff" };
              }
              if (index < 0) {
                //last item
                return { value, color: colors[colors.length - 1] };
              }
              return { value, color: colors[index] };
            }
          );
        } catch (err) {
          const errorMessage = err?.message ?? "An error occurred.";
          // Change the error message to be more user-friendly.
          const match = errorMessage.match(/requires nshades to be at least size (\d+)/);
          if (match) {
            const size = match[1];
            setBinUpdateErrorMessage(
              `Selected colour palette requires at least ${size} bins. Please update your bin settings`
            );
          } else {
            setBinUpdateErrorMessage(errorMessage);
          }
        }
      }
      drawZoneLine(
        context,
        data,
        xdaLayoutOptions,
        screenSize.current,
        xda.settings,
        settings,
        screenshotOverlayVisible
      );
      const hasWellsAndSticks =
        selectedWellsAndSticks && Object.keys(selectedWellsAndSticks).length !== 0;
      const hasPolygonsNotInList = data.polygons_not_in_list?.length;
      if (hasWellsAndSticks || hasPolygonsNotInList) {
        const onlyDrawPolygonNotInListShapes = !!(
          !hasWellsAndSticks && hasPolygonsNotInList
        );
        drawShape(
          context,
          data,
          xdaLayoutOptions,
          screenSize.current,
          xda.settings,
          selectedEntities,
          onShapeEntered,
          onShapeLeave,
          highlightWells.current,
          pathsRef,
          showMeasurement,
          showOverlap,
          onlyDrawPolygonNotInListShapes
        );
        if (!onlyDrawPolygonNotInListShapes) {
          drawCentroid(
            context,
            data,
            xdaLayoutOptions,
            screenSize.current,
            xda.settings,
            settings,
            screenshotOverlayVisible,
            showData
          );
        }
      }
    },
    [
      mapEntities?.data,
      mapEntities?.status,
      selectedWells,
      showGrid,
      showIpdb,
      showMeasurement,
      onShapeEntered,
      showOverlap,
      onShapeLeave,
      xda.settings.ipdbColor,
      xda.settings.ipdbField,
      ipdbDispatch,
      showData,
      dataFields,
      ipdbSource,
      hangWellsToTop,
      xdaBin,
      reverseColor,
      screenshotOverlayVisible,
      settings?.topsLabelsFontSize,
      settings?.dataLabelsFontSize,
      settings?.gridLabelsFontSize,
      widthScaled,
      showRelativeDepth,
      showTVDSSDepth,
      xdaLineRequest,
      currentPolygon,
      xdaBin?.isLocked
    ]
  );
  useEffect(() => {
    if (zuiRef.current) {
      zuiRef.current.reset();
      zuiRef.current.translateSurface(0, 0);
    }
  }, [widthScaled, resetZuiInstance]);

  //BST to search for first index in a that is greater than b
  function findFirstGreaterThan(a: [number, string][], b: number) {
    let left = 0;
    let right = a.length - 1;

    while (left <= right) {
      const mid = Math.floor((left + right) / 2);

      if (a[mid][0] > b) {
        if (mid === 0 || a[mid - 1][0] <= b) {
          return mid;
        } else {
          right = mid - 1;
        }
      } else {
        left = mid + 1;
      }
    }

    return -1; // Return -1 if no element in 'a' is greater than 'b'
  }

  function removeShapeEventBinding() {
    for (const shape of shapeRef.current) {
      shape.unbind("mouseenter");
      shape.unbind("mouseleave");
    }
  }

  useEffect(() => {
    function addZUI(two): ZUI {
      const domElement = two.renderer.domElement;
      const zui = new ZUI(two.scene, domElement);

      const mouse = new Two.Vector();

      zui.addLimits(0.1, 4);
      domElement.addEventListener("mousedown", mousedown, false);

      domElement.addEventListener("mousewheel", mousewheel, false);
      domElement.addEventListener("wheel", mousewheel, false);

      function mousedown(e: MouseEvent) {
        mouse.x = e.clientX;
        mouse.y = e.clientY;

        window.addEventListener("mousemove", mousemove, false);
        window.addEventListener("mouseup", mouseup, false);
      }

      function mouseup() {
        window.removeEventListener("mousemove", mousemove, false);
        window.removeEventListener("mouseup", mouseup, false);
      }

      function mousemove(e) {
        const dx = e.clientX - mouse.x;
        const dy = e.clientY - mouse.y;

        zui.translateSurface(dx, dy);
        mouse.set(e.clientX, e.clientY);
      }

      function mousewheel(e: WheelEvent) {
        const dy = -e.deltaY / 1000;

        zui.zoomBy(dy, e.clientX, e.clientY);
      }

      return zui;
    }

    function setup() {
      if (!sceneRef.current) {
        return;
      }
      const twoContext = two.current;
      zuiRef.current = addZUI(twoContext);
      twoContext.appendTo(sceneRef.current);

      const container = sceneRef.current;

      const height = container.clientHeight;
      const width = container.clientWidth;
      screenSize.current = {
        width,
        height
      };
      setParentDimensions({
        width: width,
        height: height
      });

      // Function to check if a point is inside a rectangle
      function isPointInsideRectangle(x, y, rect) {
        const left = rect.translation.x - rect.width / 2;
        const right = rect.translation.x + rect.width / 2;
        const top = rect.translation.y + rect.height / 2;
        const bottom = rect.translation.y - rect.height / 2;
        return x >= left && x <= right && y >= top && y <= bottom;
      }

      function getRectangleUnderMouse(x, y) {
        const rectangles = geoRectanglesRef.current;
        if (!rectangles) {
          return null;
        }
        for (let i = rectangles.length - 1; i >= 0; i--) {
          const rect = rectangles[i];

          if (isPointInsideRectangle(x, y, rect)) {
            return rect;
          }
        }

        return null;
      }

      const mouseMoveListener = two.current.renderer.domElement.addEventListener(
        "mousemove",
        function (event) {
          if (!showXdaValueInfoRef.current) {
            return;
          }
          const rectUnderMouse = getRectangleUnderMouse(
            event.offsetX,
            event.offsetY
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ) as any;

          if (rectUnderMouse) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            for (const rect of highlightedGeoRectanglesRef.current as any[]) {
              if (rect) {
                rect.fill = rect.originalFill;
              }
            }
            setValueInfo(rectUnderMouse.value?.toFixed(3) ?? "");
            rectUnderMouse.originalFill = rectUnderMouse.fill;
            if (rectUnderMouse.fill) {
              rectUnderMouse.fill = lightenColor(rectUnderMouse.fill, 0.6);
              highlightedGeoRectanglesRef.current = [];
              highlightedGeoRectanglesRef.current.push(rectUnderMouse);
            }
          }
        }
      );

      const mouseOutListener = two.current.renderer.domElement.addEventListener(
        "mouseout",
        function () {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          for (const rect of highlightedGeoRectanglesRef.current as any[]) {
            if (rect) {
              rect.fill = rect.originalFill;
            }
          }
        }
      );

      return () => {
        two.current.renderer?.domElement.removeEventListener(
          "mousemove",
          mouseMoveListener
        );
        two.current.renderer?.domElement?.removeEventListener(
          "mouseout",
          mouseOutListener
        );
        removeShapeEventBinding();
        two.current.clear();
      };
    }

    return setup();
  }, []);

  const resizeDebounce = _debounce((client) => {
    const width = client.contentRect.width;
    const height = client.contentRect.height;
    screenSize.current = {
      width,
      height
    };
    setParentDimensions({
      //adding 30 to account for the 30px of padding-right in sceneWrapper
      width: width + 30,
      height: height
    });
    two.current.width = width;
    two.current.height = height;
    renderScene(data?.data);
  }, 300);

  useResizeObserver(sceneRef, (client) => resizeDebounce(client));

  const requestDebounce = _debounce(async () => {
    if (!xdaLineRequest.line.length) return;
    const line = {
      start: {
        x: xdaLineRequest.line[0][0],
        y: xdaLineRequest.line[0][1]
      },
      end: {
        x: xdaLineRequest.line[1][0],
        y: xdaLineRequest.line[1][1]
      }
    };
    const wells = Object.keys(selectedWells) || [];
    mutate({ line, wells, dataFields });
  }, 500);

  useEffect(() => {
    highlightWells.current = attentionWells.length > 0 ? attentionWells[0] : "";
    for (const path of pathsRef.current) {
      if (path.id === attentionWells[0]) {
        path.fill = path.stroke;
      } else if (showOverlap) {
        path.fill = convertHexToRGBA(path.stroke, 0.3);
      } else {
        path.fill = "rgba(0,0,0,0)";
      }
    }
    //renderScene(dataRef.current);
  }, [attentionWells, renderScene, showOverlap]);

  useEffect(() => {
    requestDebounce();
    return () => requestDebounce.cancel();
  }, [
    xdaLineRequest,
    upHeight,
    downHeight,
    leftWidth,
    rightWidth,
    showCompletion,
    scaleByOption,
    topsModelSource,
    selectedPlays,
    scaleByValue,
    completionLength,
    playConstraint,
    mapEntities?.data,
    hangWellsToTop,
    renderScene,
    showAllTops,
    showOverlap,
    xdaBin?.isLocked
  ]);

  // lock map before going fullscreen
  const handleFullscreenToggle = (value) => {
    dashboardDispatch({ payload: { lockMap: value } });
  };

  useEffect(() => onFullscreenToggle(lockMap), [lockMap]);

  const handleScreenshotToggle = (v) => {
    handleFullscreenToggle(v);
  };

  useEffect(() => {
    handleScreenshotToggle(screenshotOverlayVisible);
  }, [screenshotOverlayVisible]);

  return (
    <Wrapper
      ref={mouseoverRef}
      className="xda-viewer"
      isScreenshot={screenshotOverlayVisible}>
      <Screenshot key="screenshot" containerId="xda-screenshot-overlay" />

      <XDAContainer
        className="xda-viewer-container"
        isScreenshot={screenshotOverlayVisible}
        screenshotWidth={screenshotBounds.width}
        screenshotHeight={screenshotBounds.height}>
        <ScreenshotContainer id={"xda-screenshot-overlay"} />
        <SceneWrapper
          ref={sceneRef}
          onContextMenu={(e) => e.preventDefault()}
          showMeasurement={showMeasurement}
        />

        {isLoading && (
          <BusyOverlay onContextMenu={(e) => e.preventDefault()}>
            <IconSpinner />
          </BusyOverlay>
        )}
        {isError && (
          <ErrorOverlay>
            <Alert type="error" message={`Unable to generate XDA, ${errorMsg}.`} />
          </ErrorOverlay>
        )}
        {!topsModelSource.length && !isError && (
          <ErrorOverlay>
            <Alert type="error" message={"No tops model source selected."} />
          </ErrorOverlay>
        )}
        {showXdaValueInfo && (
          <ValueInformationContainer>{valueInfo}</ValueInformationContainer>
        )}
        {!Object.keys(selectedWells).length &&
          !padScenario?.sticks?.length &&
          !isError &&
          !dataRef?.current?.available_plays?.length && (
            <ErrorOverlay>
              <Alert message="No lines drawn. Please use line tool on the map toolbar to draw a cross section." />
            </ErrorOverlay>
          )}

        {xda.settings.showIpdb && activeIpdbLegend && (
          <IpdbLegend
            height={250}
            width={180}
            parentDimensions={parentDimensions}
            ipdbFontSize={activeIpdbFontSize}
            backgroundOpacity={activeBackgroundOpacity}
            showLegendBorder={activeShowLegendBorder}
            location="xda"
          />
        )}
      </XDAContainer>

      {!screenshotOverlayVisible && (
        <XdaToolbar
          mouseoverRef={mouseoverRef}
          binUpdateErrorMessage={binUpdateErrorMessage}
          onFullscreenToggle={onFullscreenToggle}
          dataRef={dataRef}
          isLoading={isLoading}
        />
      )}

      <Label
        ref={labelRef}
        top={labelTop}
        left={labelLeft}
        data-tip={wellLabel}
        data-for="xda-tooltip"
      />
      <ReactTooltip id="xda-tooltip" html={true} />
    </Wrapper>
  );
}

export default XdaViewer;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: inherit;

  ${(props) =>
    props.isScreenshot &&
    `
    display: grid;
    background-color: rgba(46, 72, 88, 0.24);
  `}
`;

const XDAContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: inherit;

  ${(props) =>
    props.isScreenshot &&
    `
    justify-self: center;
    width: ${props.screenshotWidth}px;
    height: ${props.screenshotHeight}px;
    background-color: white;
    top: 60px;
  `}
`;

const ValueInformationContainer = styled.div`
  display: flex;
  height: 30px;
  position: absolute;
  bottom: 2px;
  width: 100%;
  justify-content: center;
`;

const SceneWrapper = styled.div`
  width: 100%;
  height: 100%;
  cursor: ${(p) => (p.showMeasurement ? "crosshair" : "default")};

  text {
    user-select: none;
  }
`;

const ScreenshotContainer = styled.div`
  position: absolute;
  display: grid;
  justify-content: center;
  pointer-events: none;
`;

const Label = styled.span`
  position: absolute;
  left: ${(p) => p.left}px;
  top: ${(p) => p.top}px;
`;
