import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import {
  POLYGON_BINS_MY_FIELDS_LAYERS,
  POLYGON_BINS_ORG_FIELDS_LAYERS
} from "constants/mapLayers.constants";

import { useColumns } from "hooks";

import { IShapefile, IShapefileNode } from "models/projects";

import { groupFields } from "components/groupBy/helpers";
import { useProjectContext } from "components/project/projects/context";
import { useProjectShapefilesQuery } from "components/project/shapefiles/queries";

import useBetaFeatures from "../../../../../hooks/useBetaFeatures";
import { EntityKind } from "../../../../../models/entityKind";
import { RootState } from "../../../../../store/rootReducer";
import { ProjectTreeNodeT, projectTreeRootMap } from "./useProjectLayerTree.types";

export interface UseProjectLayerTreeReturnT {
  error: string;
  isLoading: boolean;
  tree: ProjectTreeNodeT[];
}

export function useProjectLayerTree(): UseProjectLayerTreeReturnT {
  // store values
  const activeEntityKinds = useSelector(
    (state: RootState) => state.app.activeEntityKinds
  );

  // context
  const { selectedProjectId } = useProjectContext();

  // hooks
  const projectShapefiles = useProjectShapefilesQuery({
    projectId: selectedProjectId
  });

  const { hasFeature } = useBetaFeatures();

  const columns = useColumns();
  const groupedColumns = groupFields(columns);

  const groupedBins = useMemo(() => {
    if (Object.keys(groupedColumns).length === 0) {
      return [];
    }
    return Object.keys(groupedColumns).reduce((acc, key) => {
      const list = groupedColumns[key];
      const geomFields = list
        .filter((l) => l.fieldType === "geom")
        .sort((a, b) => a.withinSubgroupOrder - b.withinSubgroupOrder)
        .map((field) => ({
          ...field,
          source: field.property.startsWith("My_Focus_Fields") ? "user" : "org"
        }));
      if (geomFields.length > 0) {
        acc.push({ key, geomFields });
      }
      return acc;
    }, []);
  }, [groupedColumns]);

  // state
  const [tree, setTree] = useState<ProjectTreeNodeT[]>([]);

  const parseShapefileChildren = (
    children: IShapefileNode[],
    source: "project" | "org" | "mcdan"
  ): ProjectTreeNodeT[] => {
    const subTree: ProjectTreeNodeT[] = [];
    if (children) {
      children.forEach((node: IShapefileNode | IShapefile, i) => {
        const type = node.type === "node" ? "folder" : "layer";
        const treeNode: ProjectTreeNodeT = {
          type: type,
          originalIndex: i,
          key:
            node.type === "node"
              ? node.shapefileNodeId
              : node.projectShapefileId
              ? node.projectShapefileId
              : node.shapefileId,
          title: node.title,
          checked: false,
          children: [],
          source: source,
          fileType: "shapefile",
          className: node.children ? "shapefile-folder" : "shapefile-leaf"
        };

        if (treeNode.type === "folder") {
          if (source !== "org" && node.shapefileNodeId === "orgLevelShapefiles") return;

          treeNode.folder = {
            source: source
          };
          treeNode.shapefileNodeId = node.shapefileNodeId;
        } else if (treeNode.type === "layer") {
          const shapefile = node as IShapefile;
          const type =
            source === "org"
              ? "orgShapefile"
              : source === "mcdan"
              ? "mcdanShapefile"
              : "projectShapefile";
          if (shapefile) {
            const sfnode = node as IShapefile;
            treeNode.layer = {
              name: node.title,
              type: type,
              selected: true,
              zoom: [0, 22],
              style: {
                color: shapefile.color,
                strokeColor: shapefile.strokeColor,
                thickness: shapefile.thickness,
                opacity: shapefile.opacity
              }
            };
            treeNode.projectId = node.projectId;
            treeNode.shapefileId = node.shapefileId;
            treeNode.projectShapefileId = node.projectShapefileId;
            treeNode.shapefileNodeId = node.shapefileNodeId;
            treeNode.checked = treeNode.layer.selected;
            treeNode.name = node.name;
            treeNode.properties = sfnode.properties;
            treeNode.fileType = sfnode.fileType;
            treeNode.rasterAttributes = sfnode.rasterAttributes;
          }
        }

        if (node.children) {
          treeNode.children = parseShapefileChildren(node.children, source);
        }
        subTree.push(treeNode);
      });
    }

    return subTree;
  };

  const parsePolygonBins = (
    source: string,
    geomFields = groupedBins
  ): ProjectTreeNodeT[] => {
    const subTree: ProjectTreeNodeT[] = [];
    if (geomFields) {
      geomFields.forEach((geomField) => {
        const geomFieldNode: ProjectTreeNodeT = {
          type: "layer",
          key: geomField?.id,
          title: geomField?.title,
          checked: false,
          layer: {
            name: geomField?.id,
            type: "polygonBin",
            selected: false,
            zoom: [0, 22],
            style: {
              color: "#0000FF",
              strokeColor: "#transparent",
              thickness: 1,
              opacity: 1
            }
          }
        };
        subTree.push(geomFieldNode);
      });
      return subTree;
    }
  };

  const parsePolygonBinFolders = (
    source: string,
    bins = groupedBins
  ): ProjectTreeNodeT[] => {
    const subTree: ProjectTreeNodeT[] = [];
    if (bins) {
      bins.forEach((bin) => {
        const binNode: ProjectTreeNodeT = {
          type: "folder",
          key: bin?.key ?? bin?.id,
          title: bin?.key ?? bin?.title,
          checked: false,
          layer: {
            name: bin?.key ?? bin?.id,
            type: "polygonBin",
            selected: false,
            zoom: [0, 22],
            style: {
              color: "#0000FF",
              strokeColor: "#transparent",
              thickness: 1,
              opacity: 1
            }
          }
        };

        if (bin?.geomFields) {
          binNode.children = parsePolygonBins(source, bin.geomFields);
        }
        if (bin?.geomFields.some((field) => field.source === source)) {
          subTree.push(binNode);
        }
      });
      return subTree;
    }
  };

  const isFacilityEnabled = hasFeature("Facility") || hasFeature("Facility Volumes");

  useEffect(() => {
    const tree: ProjectTreeNodeT[] = [];
    Object.keys(projectTreeRootMap).forEach((nodeKey) => {
      if (
        (!isFacilityEnabled || !activeEntityKinds.includes(EntityKind.Facility)) &&
        nodeKey === "Facility Layers"
      ) {
        return;
      }
      let source: "map" | "project" | "org" | "mcdan" = "map";
      switch (nodeKey) {
        case "Project Shapefiles":
          source = "project";
          break;
        case "Organization Shapefiles":
          source = "org";
          break;
        case "McDaniel Shapefiles":
          source = "mcdan";
          break;
      }

      const polygonBinsFolder = projectTreeRootMap[nodeKey].includes(
        POLYGON_BINS_MY_FIELDS_LAYERS,
        POLYGON_BINS_ORG_FIELDS_LAYERS
      );

      tree.push({
        type: "rootFolder",
        key: nodeKey,
        title: `${nodeKey}`,
        checked: false,
        source: source,
        children: projectTreeRootMap[nodeKey].map((mapKey): ProjectTreeNodeT => {
          return {
            type: polygonBinsFolder ? "folder" : "layer",
            key: mapKey,
            title: mapKey,
            checked: polygonBinsFolder ? false : true,
            layer: {
              name: mapKey,
              type: "map",
              selected: true,
              zoom: [0, 22]
            }
          };
        })
      });
    });

    if (projectShapefiles.data?.Project) {
      tree.filter((x) => x.key === "Project Shapefiles")[0].children =
        parseShapefileChildren(projectShapefiles.data.Project.children, "project");

      tree.filter((x) => x.key === "Organization Shapefiles")[0].children =
        parseShapefileChildren(projectShapefiles.data.Organization.children, "org");

      tree.filter((x) => x.key === "McDaniel Shapefiles")[0].children =
        parseShapefileChildren(projectShapefiles.data.McDaniel.children, "mcdan");
    }

    if (groupedColumns) {
      tree
        .filter((x) => x.key === "Polygon Bins")[0]
        .children.filter((y) => y.key === "My Fields")[0].children =
        parsePolygonBinFolders("user");

      tree
        .filter((x) => x.key === "Polygon Bins")[0]
        .children.filter((y) => y.key === "Organization Fields")[0].children =
        parsePolygonBinFolders("org");
    }

    setTree(tree);
  }, [projectShapefiles.data, activeEntityKinds, columns]);

  return {
    tree,
    isLoading: projectShapefiles.isLoading,
    error: null
  };
}
