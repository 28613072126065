import { createStateWithDefaults } from "entities/utils";
import { ChartEntity } from "types";

import { DataEnum } from "models";

export const UNDEFINED_CHART_FEATURES = {
  screenshot: undefined,
  fullScreen: undefined,
  datatable: undefined,
  spaghetti: undefined,
  forecast: undefined,
  source: undefined,
  debug: undefined,
  timeStep: undefined,
  isProducingRateType: undefined,
  cutoff: undefined,
  shutInMonths: undefined,
  movingAverageDays: undefined,
  survivorBias: undefined,
  useWeightedRatioAverage: undefined,
  lockUnits: undefined,
  lasso: undefined,
  typewells: undefined,
  p10: undefined,
  p50: undefined,
  p90: undefined,
  average: undefined,
  normalization: undefined,
  chartFocus: undefined,
  legend: undefined,
  dataTable: undefined,
  pointOpacity: undefined,
  pointSize: undefined,
  lassoBrushEnd: undefined,
  lassoBrushPosition: undefined,
  lassoBrushList: undefined,
  annotations: undefined,
  chartTypeParam: undefined
};

export const UNDEFINED_CHART_ENTITIES = {
  chartTypeParam: undefined,
  apiResponse: undefined,
  request: undefined,
  options: undefined,
  locks: undefined,
  features: undefined
};

export const UNDEFINED_LOCKS = {
  xMin: {
    value: undefined,
    type: DataEnum.None
  },
  xMax: {
    value: undefined,
    type: DataEnum.None
  },
  yMin: {
    value: undefined,
    type: DataEnum.None
  },
  yMax: {
    value: undefined,
    type: DataEnum.None
  }
};

export const UNDEFINED_CHART_ENTITY: Partial<ChartEntity> = {
  id: "",
  chartType: undefined,
  locks: UNDEFINED_LOCKS,
  screenshot: {
    size: { width: undefined, height: undefined }
  },
  request: undefined,
  response: undefined,
  product: undefined,
  features: UNDEFINED_CHART_FEATURES,
  options: "",
  bounds: undefined,
  overflowIndex: undefined
};

export const generateChartEntity = createStateWithDefaults(UNDEFINED_CHART_ENTITY);
