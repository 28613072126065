import { toast } from "react-toastify";

import { EvaChart } from "constants/charts.enums";

import { IChartResult } from "models/model";

import isDeclineChart from "../utils/isDeclineChart";

/**
 * Displays a warning toast notification when a chart has synchronization issues.
 * For decline charts, if there's a warning message in the API response's sync status,
 * it will show that message as a toast warning. Otherwise, it dismisses any existing
 * toast warnings for the given ID.
 *
 * @param {EvaChart} chartType - The type of chart being rendered
 * @param {IChartResult} apiResponse - The API response containing chart data and sync status
 * @param {string} id - Unique identifier for the chart, used for toast containerId and in toastId
 * @returns {void}
 */
const handleSyncWarningToast = (
  apiResponse: IChartResult,
  chartType: EvaChart,
  id: string
): void => {
  const syncIdWarn = `sync-${id}`;
  if (isDeclineChart(chartType) && apiResponse?.syncStatus?.warningMessage?.length > 0) {
    toast.dismiss(syncIdWarn);
    //show toast message if there's a syncing warning
    toast.warn(apiResponse.syncStatus?.warningMessage, {
      containerId: id,
      toastId: syncIdWarn
    });
  } else {
    toast.dismiss(syncIdWarn);
  }
};

export default handleSyncWarningToast;
