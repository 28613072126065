import { EvaChart } from "constants/charts.enums";
import { createEntityState } from "entities/charts/factory";
import { ChartOption } from "entities/charts/options";
import _zip from "lodash/zip";
import { EvaChartStates } from "types/factory";

import { updateOptionsWithTypeWellsSeries } from "./updateOptionsWithTypeWellSeries";

export function translatePieResponseToOptions(states: Partial<EvaChartStates>) {
  const { title, series, typeWellSeries = [] } = states;

  let options = createEntityState(ChartOption.Chart, {
    chartType: EvaChart.Pie,
    title: {
      ...createEntityState(ChartOption.Title, {
        text: title.toUpperCase()
      })
    },
    yAxis: {
      ...createEntityState(ChartOption.YAxis, {
        name: "",
        nameGap: 43,
        nameTruncate: { maxWidth: 520.2, ellipsis: "..." },
        axisLine: {
          lineStyle: { color: "rgb(155,155,155)", width: 0, type: "solid" }
        }
      })
    },
    xAxis: {
      ...createEntityState(ChartOption.XAxis, {
        name: "",
        nameGap: 27,
        axisLine: {
          lineStyle: { color: "rgb(155,155,155)", width: 0, type: "solid" }
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        useY: false // useY is managed at hd chart
      })
    },
    grid: { top: 46, bottom: 46, left: 62, right: 20 },
    series: series.map((location, index) => {
      const { label, style, x, y, groupTitle } = location;
      const data = _zip(x, y).map(([value, name]) => ({ value, name }));
      const id =
        groupTitle !== label
          ? `${groupTitle}, ${label}, ${index}`
          : `${groupTitle}, ${index}`;
      const pieSeries = createEntityState(ChartOption.Pie, {
        id,
        name: label,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)"
          }
        },
        data: data,
        color: style.itemColors,
        radius: "60%",
        center: ["50%", "50%"]
      });
      return pieSeries;
    }),
    dataZoom: []
  });

  if (typeWellSeries.length > 0) {
    options = updateOptionsWithTypeWellsSeries(options, typeWellSeries);
  }
  return options;
}
