import {
  DEFAULT_AXIS_LABEL_FONT_SIZE,
  DEFAULT_FONT_WEIGHT
} from "constants/chart.constants";
import { EvaChart } from "constants/charts.enums";
import { createEntityState } from "entities/charts/factory";
import { ChartOption } from "entities/charts/options";
import { EvaChartStates } from "types/factory";

import { checkForOverlappingAndOutOfBoundsLabels } from "components/chart/utils";

const MOSAIC_LABEL_SPACING = 0.1;

export function translateMosaicResponseToOptions(states: Partial<EvaChartStates>) {
  const { title, layout, series, width } = states;

  const labels = layout.xAxis.labels;

  const colorList = createColorMap(series);
  const data = formatSeriesData(series, colorList);
  const max = Math.max(...series.map((s) => s.x[1]));
  const labelsToUse = processAxisLabels(labels, max, width);

  let options = createEntityState(ChartOption.Chart, {
    chartType: EvaChart.Mosaic,
    grid: { top: 92, bottom: 46, left: 59, right: 20 },
    toolbox: {
      show: false
    },
    aria: null,
    title: {
      ...createEntityState(ChartOption.Title, {
        text: title.toUpperCase(),
        itemGap: 0,
        subtext: null,
        textStyle: { fontSize: 14, fontWeight: "bold", color: "#041C2C" },
        padding: [14, 0, 10, 16]
      })
    },
    yAxis: {
      ...createEntityState(ChartOption.YAxis, {
        min: 0,
        max: 100,
        name: "Well Count (%)",
        nameLocation: "middle",
        nameGap: 40,
        nameTextStyle: null,
        nameTruncate: { maxWidth: 520.2, ellipsis: "..." },
        axisLine: {
          onZero: false,
          lineStyle: { color: "rgb(155,155,155)", width: 1 }
        },
        splitLine: {
          lineStyle: {
            color: "#D9D9D9"
          }
        }
      })
    },
    xAxis: [
      {
        ...createEntityState(ChartOption.XAxis, {
          type: "value",
          name: layout.xAxis.title,
          min: 0,
          max: max,
          nameLocation: "middle",
          id: "xAxis",
          nameGap: 30,
          axisLine: {
            onZero: false,
            lineStyle: { color: "rgb(155,155,155)", width: 1 }
          },
          splitLine: {
            lineStyle: {
              color: "#D9D9D9"
            }
          },
          axisLabel: {
            color: "#5B6770",
            fontWeight: DEFAULT_FONT_WEIGHT,
            fontSize: DEFAULT_AXIS_LABEL_FONT_SIZE,
            customValues: labelsToUse.map((l) => l.value),
            formatter: (val) => {
              const found = labelsToUse.findIndex((x) => x.value == val);
              if (found >= 0) {
                return labelsToUse[found].label;
              }
              return "";
            }
          },
          axisTick: {
            customValues: labelsToUse.map((l) => l.value)
          },
          position: "bottom"
        })
      },

      {
        nameGap: 30,
        id: "xAxis2",
        type: "value",
        min: 0,
        max: max,
        name: "Well Count",
        nameLocation: "middle",
        axisTick: {
          show: false
        },
        position: "top",
        axisLabel: {
          color: "#5B6770",
          fontWeight: DEFAULT_FONT_WEIGHT,
          fontSize: DEFAULT_AXIS_LABEL_FONT_SIZE
        }
      }
    ],
    series: {
      ...createEntityState(ChartOption.MosaicSeries, {
        data: data,
        label: {}
      })
    }
  });

  if (series.length === 0) {
    options = {};
  }
  return options;
}

function createColorMap(series) {
  const colorList = {};
  for (const s of series) {
    colorList[s.label] = s.style.hexColor;
  }
  return colorList;
}

function formatSeriesData(series, colorList) {
  const formattedData = series.map((s) => [s.label, s.x[0], s.x[1], s.y[0], s.y[1]]);
  return formattedData.map((item) => ({
    value: item,
    itemStyle: {
      color: colorList[item[0]],
      borderColor: "#000"
    }
  }));
}

function processAxisLabels(labels, max, chartWidth) {
  const labelsToUse = [];
  let lastX = 0;
  let lastLabel = "";
  const dataToChartRatio = max / chartWidth;

  for (let i = 0; i < labels.length; i++) {
    const l = labels[i];
    const value = parseFloat(l.value);

    if (i === 0) {
      labelsToUse.push(l);
      lastX = value;
      lastLabel = l.label;
      continue;
    }

    const labelOverlapsOrIsOutOfBounds = checkForOverlappingAndOutOfBoundsLabels(
      dataToChartRatio,
      chartWidth,
      lastLabel,
      lastX,
      l.label,
      value
    );

    const x = value - lastX;
    if (x / max > MOSAIC_LABEL_SPACING && !labelOverlapsOrIsOutOfBounds) {
      labelsToUse.push(l);
      lastX = value;
      lastLabel = l.label;
    }
  }

  return labelsToUse;
}
