import { memo } from "react";
import { useDispatch } from "react-redux";

import BugReportIcon from "@material-ui/icons/BugReport";
import { IS_DEV_ENV } from "constants/app.constants";
import { useChartsContext } from "contexts/ChartContext";
import { updateChartByPath } from "store/features";

import { useChartEntities } from "hooks/charts";

import ToolbarButton from "../../base/ToolbarButton";

const DevDebugToggle = () => {
  const dispatch = useDispatch();
  const { id } = useChartsContext();
  const { debugState } = useChartEntities(id);
  const isActive = debugState.active;
  const toggle = (active: boolean) => {
    dispatch(
      updateChartByPath({
        id,
        path: `features.debug.active`,
        value: active
      })
    );
  };
  if (!IS_DEV_ENV) {
    return null;
  }

  return (
    <ToolbarButton
      active={isActive}
      icon={<BugReportIcon fontSize="large" />}
      overflowLabel="Debug Chart Options"
      tooltipText="Debug Chart Options"
      onToggle={toggle}
    />
  );
};

export default memo(DevDebugToggle);
