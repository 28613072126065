import { memo } from "react";

function GasOutlet() {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_429_11126)">
        <path
          transform="translate(-4,8)"
          d="M9 4.00018H19V18.0002C19 19.1048 18.1046 20.0002 17 20.0002H9"
          stroke="currentColor"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          transform="translate(-4,8)"
          d="M12 15.0002L15 12.0002M15 12.0002L12 9.00018M15 12.0002H5"
          stroke="currentColor"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        fill="currentColor"
        transform="translate(21,10)"
        d="M13.8165 11.8429C13.5771 7.9784 6.80651 0 6.80651 0C5.27508 6.87459 -0.00611427 7.65789 5.31389e-06 12.1833C0.00383005 15.237 1.38839 17.0101 3.11564 17.9518C2.5687 16.1228 2.79053 14.0001 4.43288 12.4656C7.46513 9.63222 7.79865 7.72674 7.79865 7.72674C10.9357 13.2963 10.1662 16.796 8.98432 18.5607C11.8621 17.7659 14.036 15.3808 13.8165 11.8429"
      />
    </svg>
  );
}

export default memo(GasOutlet);
