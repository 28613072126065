import { RasterAttributes } from "models/model";

import * as mapLayers from "./../../../../../constants/mapLayers.constants";

export type MapType = "map";
export type ProjectShapefileType = "projectShapefile";
export type OrgShapefileType = "orgShapefile";
export type McdanShapefileType = "mcdanShapefile";
export type PolygonBinType = "polygonBin";

export const projectTreeRootMap = {
  "Well Layers": [
    mapLayers.WELL_LABEL_LAYER,
    mapLayers.WELL_LAYER,
    mapLayers.SELECTED_WELL_LAYER,
    mapLayers.TYPE_LOG_LAYER,
    // HIGHLIGHTED_WELL_LAYER,
    mapLayers.XDA_INTERCEPT_LAYER,
    mapLayers.XDA_LABEL,
    mapLayers.XDA_WELL_INTERCEPT_POINT
  ],
  "Facility Layers": [mapLayers.FACILITY_LAYER, mapLayers.FACILITY_LABEL_LAYER],
  "Completion Layers": [mapLayers.COMPLETION_FRAC_LAYER, mapLayers.COMPLETION_PERF_LAYER],
  "Grid Layers": [
    mapLayers.LSD_LABEL_LAYER,
    mapLayers.LSD_LAYER,
    "Section",
    "Township",
    "Section Label",
    mapLayers.NTS_BLOCK_LABEL_LAYER
    // "Township Label"
  ],
  "Base Layers": ["Base Well"],
  [mapLayers.POLYGON_BINS_LAYERS]: [
    mapLayers.POLYGON_BINS_MY_FIELDS_LAYERS,
    mapLayers.POLYGON_BINS_ORG_FIELDS_LAYERS
  ],
  ["Shapefile Label Layers"]: [
    mapLayers.SHAPEFILE_LABELS_LAYER,
    mapLayers.SHAPEFILE_NAME_LABELS_LAYER
  ],
  "Project Shapefiles": [],
  "Organization Shapefiles": [],
  "McDaniel Shapefiles": []
};

export interface ProjectTreeNodeT {
  type: "rootFolder" | "layer" | "folder";
  key: string;
  title: string;
  checked: boolean;
  folder?: ProjectShapefileFolderT;
  layer?: ProjectLayerT;
  children?: ProjectTreeNodeT[];
  source?: string;
  className?: string;
  shapefileId?: string;
  shapefileNodeId?: string;
  projectShapefileId?: string;
  projectId?: string;
  name?: string;
  properties?: string[];
  fieldSource?: string;
  originalIndex?: number;
  fileType?: "GeoTiff" | "Shapefile" | "shapefile";
  rasterAttributes?: RasterAttributes;
}

export interface ProjectShapefileFolderT {
  source: "map" | "project" | "org" | "mcdan";
}

export interface ProjectLayerT {
  name: string;
  type: LayerType;
  selected: boolean;
  style?: ProjectLayerStyleT;
  zoom: [number, number];
  shapefileId?: string;
}

export type LayerType =
  | MapType
  | ProjectShapefileType
  | OrgShapefileType
  | McdanShapefileType
  | PolygonBinType;

export interface ProjectLayerStyleT {
  color: string;
  strokeColor: string;
  thickness: string | number;
  opacity: number;
}
