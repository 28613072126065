import { EvaChart } from "../../../constants/charts.enums";

const isDeclineChart = (chartType: EvaChart) => {
  return (
    chartType == EvaChart.RateCum ||
    chartType == EvaChart.TotalRateCum ||
    chartType == EvaChart.CumTime ||
    chartType == EvaChart.RateTime ||
    chartType == EvaChart.RateDate ||
    chartType == EvaChart.TrendDate ||
    chartType == EvaChart.TotalRateDate
  );
};

export default isDeclineChart;
