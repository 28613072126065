import { LegendStates, MouseSelectionStates, SelectedParams } from "types";
import { SeriesType } from "types/echarts";

import {
  getAttentionWellIndex,
  getGroupName,
  getLegendId,
  getSeriesIndex,
  getSeriesIndexFromSelectedGroups,
  getSeriesName,
  isValidChartLegendStates
} from "./utils";

export function rateDateHoverLegends({
  params,
  series,
  forecast
}: Partial<MouseSelectionStates>) {
  const seriesName = getSeriesName({ params, series, forecast });
  const groupName = getGroupName({ params, series });
  const legendId = getLegendId({ params, series });

  return {
    hoverLegendItem: seriesName,
    hoverLegendGroup: groupName,
    hoverLegendId: legendId
  };
}

export function rateDateSelectionParams({
  states,
  series
}: Partial<LegendStates>): SelectedParams {
  const { hoverLegendGroup, hoverLegendItem, attentionWells, selectedGroups } = states;
  isValidChartLegendStates({ states, series });

  const isHoveringOnBins =
    hoverLegendItem && !hoverLegendGroup && attentionWells?.length === 0;
  const isHoveringOnChart =
    hoverLegendItem && hoverLegendGroup && hoverLegendGroup !== hoverLegendItem;
  const isHoveringOnMap =
    hoverLegendItem && !hoverLegendGroup && attentionWells?.length > 0;

  const onlySelectedGroup =
    !hoverLegendItem &&
    !hoverLegendGroup &&
    attentionWells?.length === 0 &&
    selectedGroups?.length > 0;

  const selectedGroupsIndices = getSeriesIndexFromSelectedGroups({ states, series });

  const seriesIndex = getSeriesIndex({ states, series });

  if (isHoveringOnBins) {
    return {
      seriesIndex: [...seriesIndex, ...selectedGroupsIndices],
      seriesType: SeriesType.Line,
      targetIndex: undefined
    };
  }

  if (isHoveringOnChart) {
    return {
      seriesIndex: [...seriesIndex, ...selectedGroupsIndices],
      targetIndex: seriesIndex,
      seriesType: SeriesType.Line
    };
  }

  if (isHoveringOnMap) {
    return {
      seriesIndex: [...seriesIndex, ...selectedGroupsIndices],
      targetIndex: getAttentionWellIndex({ states, series }),
      seriesType: SeriesType.Line
    };
  }

  if (onlySelectedGroup) {
    return {
      seriesIndex: [...selectedGroupsIndices],
      targetIndex: undefined,
      seriesType: SeriesType.Line
    };
  }
}
