import { memo } from "react";
import { useDispatch } from "react-redux";

import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import { useChartsContext } from "contexts/ChartContext";
import { updateChartByPath } from "store/features";

import { useChartEntities } from "hooks/charts";

import ToolbarButton from "../../base/ToolbarButton";

const FullscreenToggle = () => {
  const dispatch = useDispatch();
  const { id } = useChartsContext();
  const { fullScreen } = useChartEntities(id);
  const isActive = fullScreen.active;

  const toggle = (active: boolean) => {
    dispatch(
      updateChartByPath({
        id,
        path: `features.fullScreen.active`,
        value: active
      })
    );
  };

  return (
    // "Key" will help react to re-render the button and correct the state of tooltip
    // Without re-render, tooltip will remember the state of mouse-over -> tooltip not closed even tho the window was already expanded/collapsed
    <ToolbarButton
      key={"FullscreenToggle" + isActive}
      data-testid="fullscreen-toggle"
      icon={<FullscreenIcon fontSize="large" />}
      activeIcon={<FullscreenExitIcon fontSize="large" />}
      active={isActive}
      overflowLabel="Fullscreen"
      tooltipText="Toggle Fullscreen"
      onToggle={toggle}
    />
  );
};

export default memo(FullscreenToggle);
