import { BarChart, BarSeriesOption, LineChart, LineSeriesOption } from "echarts/charts";
import {
  DatasetComponent,
  DatasetComponentOption,
  GridComponent,
  GridComponentOption,
  TitleComponent,
  TitleComponentOption,
  TooltipComponent,
  TransformComponent
} from "echarts/components";
import * as echarts from "echarts/core";
import { LabelLayout, UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";

export type ECOption = echarts.ComposeOption<
  | BarSeriesOption
  | LineSeriesOption
  | TitleComponentOption
  | GridComponentOption
  | DatasetComponentOption
>;

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  BarChart,
  LineChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer
]);

export enum SeriesType {
  Line = "line",
  Scatter = "scatter",
  Pie = "pie",
  Custom = "custom",
  None = "none"
}

export enum ChartActionType {
  Click = "click",
  Hover = "hover",
  Highlight = "highlight",
  HideTip = "hideTip",
  ShowTip = "showTip",
  Downplay = "downplay",
  MouseOver = "mouseover",
  MouseOut = "mouseout",
  BrushSelected = "brushSelected",
  BrushEnd = "brushEnd",
  LineBlur = "lineBlur"
}

export enum ZRenderActionType {
  Click = "click",
  MouseUp = "mouseup",
  MouseDown = "mousedown",
  ContextMenu = "contextmenu"
}

export enum AxisType {
  Value = "value",
  Category = "category",
  Time = "time",
  Log = "log"
}

export { echarts };
